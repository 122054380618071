import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Dispatch } from 'redux';
import { toastr } from 'react-redux-toastr';
import { deliveryFormName } from '../../../Shipment/Shipment.View/components/Shipment.component';
import { ErrorWrapper } from '../../../../../../viewModels/base';
import CommonUtilities from '../../../../../../helpers/CommonUtilities';
import { DeliveryApi } from '../../../../../../api/Delivery.api';
import { change } from 'redux-form';

const actionCreator = actionCreatorFactory();
const type = 'DELIVERY/IMPORT-EXCEL-ROWS';
export const asyncActions = actionCreator.async<{}, {}, ErrorWrapper>(type);

export default function submit(files: any[], onSuccess: Function) {
  return async (dispatch: Dispatch<AnyAction>, getState: Function) => {
    async function mainAction() {
      dispatch(asyncActions.started({}));
      dispatch(change(deliveryFormName, 'shipmentDetailsRows', []));
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append(`files[${i}]`, files[i]);
      }
      const result = await DeliveryApi.ImportExcelRows(formData);

      dispatch(asyncActions.done({ params: {}, result: {} }));
      if (result !== null && result.shipmentDetailsRows !== null) {
        dispatch(change(deliveryFormName, 'shipmentDetailsRows', result.shipmentDetailsRows));
      }
      if (onSuccess) {
        onSuccess();
      }
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
      toastr.error('Error', 'Error has occurred');
      CommonUtilities.scrollToTop();
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
