import { connect } from 'react-redux';
import App, { AppComponentDispatchProps, AppComponentStateProps } from './App.component';
import { AppReduxState, REDUCER_NAME__APP } from './App.reducer';
import { networkChanged } from './actions';

const mapStateToProps = (state: any): AppComponentStateProps => {
  const currentState: AppReduxState = state[REDUCER_NAME__APP];
  return {
    loading: currentState.loading,
    error: currentState.error,
    isLoggedIn: !!currentState.user,
    settings: currentState.settings,
    online: currentState.online,
  };
};

const mapDispatchToProps = {
  networkChanged,
};
export default connect<AppComponentStateProps, AppComponentDispatchProps, any>(
  mapStateToProps,
  mapDispatchToProps,
)(App);
