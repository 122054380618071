import ApiUtilities from '../helpers/ApiUtils';
import { BACKEND_HOST_MAIN } from '../constants/api.constants';
import { PaginatedListResult } from '../viewModels/base';
import {
  AddressesResponseViewModel,
  AddressRequestViewModel,
  AddressCreateOrUpdateRequestViewModel,
} from '../viewModels/addressBook';
import AddressesRequestViewModel from '../viewModels/addressBook/AddressesRequestViewModel1';
import { ShipmentContact } from '../viewModels/delivery/ShipmentResponseViewModel';

function getRoot(): string {
  return `${BACKEND_HOST_MAIN()}api/addressbook`;
}

export class AddressApi {
  public static getList(params: AddressesRequestViewModel): Promise<PaginatedListResult<AddressesResponseViewModel>> {
    return ApiUtilities.handleGet<PaginatedListResult<AddressesResponseViewModel>>(getRoot(), { ...params });
  }

  public static getContactList(
    params: AddressesRequestViewModel,
  ): Promise<PaginatedListResult<AddressesResponseViewModel>> {
    return ApiUtilities.handleGet<PaginatedListResult<AddressesResponseViewModel>>(getRoot(), params);
  }

  public static getSingle(id: number): Promise<AddressRequestViewModel | null> {
    return ApiUtilities.handleGet<AddressRequestViewModel>(`${getRoot()}/${id}`);
  }

  public static getSingleContact(id: number): Promise<ShipmentContact | null> {
    return ApiUtilities.handleGet<ShipmentContact>(`${getRoot()}/${id}/shipment-contact`);
  }

  public static getSingleDebtorContact(id: number): Promise<ShipmentContact | null> {
    return ApiUtilities.handleGet<ShipmentContact>(`${getRoot()}/${id}/debtor-contact`);
  }

  public static add(formValues: AddressCreateOrUpdateRequestViewModel): Promise<number> {
    return ApiUtilities.handlePost<number>(getRoot(), formValues);
  }

  public static update(id: number, formValues: AddressCreateOrUpdateRequestViewModel): Promise<boolean> {
    return ApiUtilities.handlePut<boolean>(`${getRoot()}/${id}`, formValues);
  }

  public static remove(id: number): Promise<boolean> {
    return ApiUtilities.handleDelete(`${getRoot()}/${id}`);
  }
}
