import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Dispatch } from 'redux';
import { profileFormName } from '../Profile.component';

import { ErrorWrapper } from '../../../../../viewModels/base';
import { toastr } from 'react-redux-toastr';

import CommonUtilities from '../../../../../helpers/CommonUtilities';
import AccountApi from '../../../../../api/Account.api';
import { initialize } from 'redux-form';

const actionCreator = actionCreatorFactory();
const type = 'PROFILE/CHANGE_PASSWORD/SUBMIT';
export const asyncActions = actionCreator.async<{}, {}, ErrorWrapper>(type);

export default function submit(onSuccess: Function) {
  return async (dispatch: Dispatch<AnyAction>, getState: Function) => {
    async function mainAction() {
      const formValues = getState().form[profileFormName].values;
      if (!formValues) {
        throw new ErrorWrapper('Nothing was filled');
      }
      dispatch(asyncActions.started({}));
      await AccountApi.changePassword(formValues);
      dispatch(asyncActions.done({ params: {}, result: {} }));
      toastr.success('Success', 'Password has been changed');
      dispatch(initialize(profileFormName, {}));
      if (onSuccess) {
        onSuccess();
      }
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
      toastr.error('Error', 'Password has not been changed');
      CommonUtilities.scrollToTop();
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
