import { combineReducers, Reducer } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { connectRouter } from 'connected-react-router';
import { i18nReducer } from 'react-redux-i18n';
import { default as authReducer, REDUCER_NAME__APP } from '../app/App.reducer';
import settings from './settings';
import sidebarReducer from './SidebarReducer';

const conf = (history) => ({
  router: connectRouter(history),
  form: formReducer,
  i18n: i18nReducer,
  toastr: toastrReducer,
  [REDUCER_NAME__APP]: authReducer,
  settings: settings,
  sidebar: sidebarReducer,
});

export default function createReducer(history: any, asyncReducers: Reducer<any>) {
  return combineReducers({
    ...conf(history),
    ...asyncReducers,
  });
}
