import { animateScroll } from 'react-scroll';
import { ErrorWrapper } from '../viewModels/base';
import fileDownload from 'js-file-download';

export default class CommonUtilities {
  public static ReactScrollDefaultOptions = { duration: 500, smooth: 'easeInOutQuint' };

  /**
   *Checks if object is null or undefined
   * @return boolean
   */
  public static NotNullAndNotUndefined(object: any): boolean {
    if (object === null || object === undefined) {
      return false;
    }
    return true;
  }

  public static capitalizeFirstLetter(me: string) {
    if (!me) {
      return me;
    }
    return me.charAt(0).toUpperCase() + me.slice(1);
  }

  public static logDevMessage(...params: any[]) {
    if (process.env.NODE_ENV !== 'production') {
      console.log(...params);
    }
  }

  public static scrollToTop() {
    animateScroll.scrollTo(0, CommonUtilities.ReactScrollDefaultOptions);
  }

  public static async tryCatchWrapper(
    mainAction: () => Promise<void>,
    catchAction: (exception: ErrorWrapper) => Promise<void>,
  ): Promise<void> {
    try {
      await mainAction();
    } catch (exception) {
      if (exception instanceof ErrorWrapper) {
        await catchAction(exception);
      } else {
        console.error('Unknown error: ', exception);
      }
    }
  }

  public static typeSafeName<T>(name: keyof T) {
    return name;
  }

  public static guid(): string {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  public static downloadFile(file: Blob, inline = false, fileName: string = CommonUtilities.guid()): void {
    if (inline) {
      const url = window.URL.createObjectURL(file);
      window.open(url);
    } else {
      fileDownload(file, fileName, file.type);
    }
  }
}
