import * as React from 'react';
import * as moment from 'moment';
import { Moment } from 'moment';
import { LOCAL_STORAGE_CURRENT_LANGUAGE, LOCAL_STORAGE_CURRENT_LANGUAGE_DEBUG } from '../constants/base.constants';
import { UncontrolledTooltip } from 'reactstrap';
import TranslationApi from '../api/Translations.api';
import countries, { CountryOption } from '../seed/CountryOptions';
import { SelectComponentValue } from '../viewModels/common/SelectComponentValue';

const Translate = require('react-redux-i18n').Translate;
const I18n = require('react-redux-i18n').I18n;

interface ExtNavigator extends Navigator {
  browserLanguage: string;
  userLanguage: string;
}

export type ValidLanguage = 'English' | 'Finnish' | 'Estonian' | 'Russian' | 'Dutch' | 'Polish';
export type ValidFlag = 'flag-icon-us' | 'flag-icon-fi';

const strToLanguage: { [str: string]: ValidLanguage } = {
  en: 'English',
  fi: 'Finnish',
  ee: 'Estonian',
  ru: 'Russian',
  nl: 'Dutch',
  pl: 'Polish',
};

export default class LocaleUtilities {
  public static DateFormat = 'DD.MM.YYYY';
  public static DateTimeFormat = 'DD.MM.YYYY HH:mm';
  public static i = 1;

  public static translateSelectCompnentLabels(values: Array<SelectComponentValue>): Array<SelectComponentValue> {
    values.forEach(function (elem) {
      elem.label = I18n.t(elem.label);
    });
    return values;
  }

  public static trySetDate(param: Date, useDefaultFormat = true): Moment | undefined {
    if (!param) {
      return undefined;
    }

    if (useDefaultFormat) {
      return moment(param, LocaleUtilities.DateFormat);
    } else {
      return moment(param);
    }
  }

  public static trySetDateToString(param: Date, useDefaultFormat = true): string {
    if (!param) {
      return '';
    }

    if (useDefaultFormat) {
      return moment(param).format(LocaleUtilities.DateFormat);
    } else {
      return '';
    }
  }

  public static getCountryLabel(value?: string): string {
    let country = '';
    countries.forEach(function (element: CountryOption) {
      if (value === element.value) {
        country = element.label;
      }
    });
    return country;
  }

  public static getFlagByLanguage(language: ValidLanguage): ValidFlag {
    if (language === 'English') {
      return 'flag-icon-us';
    }
    if (language === 'Finnish') {
      return 'flag-icon-fi';
    }
    return 'flag-icon-us';
  }

  public static setCurrentLanguage(language: ValidLanguage): void {
    localStorage[LOCAL_STORAGE_CURRENT_LANGUAGE] = language;
  }

  public static getDefaultLanguage(): ValidLanguage {
    const extNavigator = window.navigator as ExtNavigator;

    const language =
      (
        (extNavigator.languages && extNavigator.languages[0]) ||
        extNavigator.userLanguage ||
        extNavigator.language ||
        extNavigator.browserLanguage ||
        'en'
      ).match(/[A-Za-z]{2}/) || [];
    return strToLanguage[language[0]] || 'English';
  }

  public static getCurrentLanguage(): ValidLanguage {
    let currentLang = localStorage[LOCAL_STORAGE_CURRENT_LANGUAGE];
    if (currentLang == '[object Object]') {
      currentLang = null;
    }
    return currentLang || LocaleUtilities.getDefaultLanguage();
  }

  public static getCurrentAppLanguage(languages: any[]): ValidLanguage {
    const currentLang = LocaleUtilities.getCurrentLanguage();
    return languages.find((element) => currentLang === element.name) || languages[0];
  }

  public static i18n(key: string, group?: string, onlyText = false): any {
    const debug = LocaleUtilities.getDebug();
    /* VAJALIK NÄITEKS PLACEHOLDERI-i jaoks */
    if (onlyText && !debug) {
      return I18n.t(key);
    }
    key = key.replace('.', '');
    if (!debug) {
      return <Translate value={key} />;
    }

    if (key && key.indexOf('.') > -1) {
      console.error(`${key} cannot contain \'.\' symbol. Replace it, for example, with \'-\'.`);
    }

    LocaleUtilities.i += 1;
    const targetKey = 'translation_' + LocaleUtilities.i;

    const value = I18n.t(key);

    const currentLang = LocaleUtilities.getCurrentLanguage() + ':';

    if (value && value.substring(0, 2) === '[[') {
      const rest = value.slice(2, -2);
      TranslationApi.upsert(key, { english: rest, finnish: rest, estonian: rest }, group);
    }

    /* VAJALIK NÄITEKS PLACEHOLDERI-i jaoks */
    if (onlyText) {
      return value;
    }
    return (
      <span
        className="debug"
        id={targetKey}
        onClick={(e) => {
          // Brainstorm
          const newValue = prompt(currentLang, value);
          let english: any | null;
          let finnish: any | null;
          let estonian: any | null;
          if (LocaleUtilities.getCurrentLanguage() === 'English') {
            english = newValue;
          } else if (LocaleUtilities.getCurrentLanguage() === 'Finnish') {
            finnish = newValue;
          } else {
            estonian = newValue;
          }
          if (english !== undefined || finnish !== undefined || estonian !== undefined) {
            TranslationApi.upsert(key, { english, finnish, estonian }, group).then(() => {
              location.reload();
            });
          }
        }}
      >
        <Translate value={key} />
        <UncontrolledTooltip placement="right" target={targetKey}>
          {key}
        </UncontrolledTooltip>
      </span>
    );
  }

  public static toggleDebug(): void {
    const currentDebug = LocaleUtilities.getDebug();
    localStorage[LOCAL_STORAGE_CURRENT_LANGUAGE_DEBUG] = !currentDebug;
  }

  public static getDebug(): boolean {
    return localStorage[LOCAL_STORAGE_CURRENT_LANGUAGE_DEBUG] === 'true' || false;
  }
}
