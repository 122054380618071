import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Dispatch } from 'redux';
import { initialize } from 'redux-form';
import { SortOrder } from 'speys-react-bootstrap-table';

import { VehicleApi } from '../../../../../../api/Vehicle.api';
import VehiclesResponseViewModel from '../../../../../../viewModels/vehicle/VehiclesResponseViewModel';
import { ErrorWrapper, TableData } from '../../../../../../viewModels/base';
import { vehiclesFormName } from '../Vehicles.component';
import VehiclesRequestViewModel from '../../../../../../viewModels/vehicle/VehiclesRequestViewModel';
import RouterUtilities from '../../../../../../helpers/RouterUtilities';
import CommonUtilities from '../../../../../../helpers/CommonUtilities';

const actionCreator = actionCreatorFactory();
export const asyncActions = actionCreator.async<{}, { tableData: TableData<VehiclesResponseViewModel> }, ErrorWrapper>(
  'VEHICLES/FETCH',
);

export declare type fetchAsyncType = (
  params: VehiclesRequestViewModel | null,
  reinitializeForm: boolean,
  changeQueryParams: boolean,
) => (dispatch: Dispatch<AnyAction>, getState: Function) => Promise<void>;

//
// Main fetch action
//

const fetchAsync: fetchAsyncType = (
  params: VehiclesRequestViewModel | null,
  reinitializeForm: boolean,
  changeQueryParams: boolean,
) => {
  return async (dispatch: Dispatch<AnyAction>, getState: Function) => {
    const partnersForm: VehiclesRequestViewModel = params || getState().form[vehiclesFormName].values;

    async function mainAction() {
      dispatch(asyncActions.started({}));

      if (reinitializeForm) {
        dispatch(initialize(vehiclesFormName, partnersForm));
      }

      if (changeQueryParams) {
        RouterUtilities.changeQueryParamsInUrl(partnersForm);
      }

      const paginatedList = await VehicleApi.getList(partnersForm);

      dispatch(
        asyncActions.done({
          params: {},
          result: {
            tableData: TableData.createTableDataFrom(paginatedList, partnersForm.page, partnersForm.sizePerPage),
          },
        }),
      );
    }

    async function catchAction(exception: any) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
};

export default fetchAsync;

//
// Sorting and pagination
//

export function onSortChange(sortName: string, sortOrder: SortOrder) {
  return async (dispatch: Dispatch<any>, getState: Function) => {
    const deliveriesForm: VehiclesRequestViewModel = getState().form[vehiclesFormName].values;
    deliveriesForm.sortName = sortName;
    deliveriesForm.sortOrder = sortOrder;
    dispatch(fetchAsync(deliveriesForm, false, true));
  };
}

export function onPageChange(page: number, sizePerPage: number) {
  return async (dispatch: Dispatch<any>, getState: Function) => {
    const deliveriesForm: VehiclesRequestViewModel = getState().form[vehiclesFormName].values;
    deliveriesForm.page = page;
    deliveriesForm.sizePerPage = sizePerPage;
    dispatch(fetchAsync(deliveriesForm, false, true));
  };
}

export function onSizePerPageList(sizePerPage: number) {
  return async (dispatch: Dispatch<any>, getState: Function) => {
    const deliveriesForm: VehiclesRequestViewModel = getState().form[vehiclesFormName].values;
    deliveriesForm.sizePerPage = sizePerPage;
    dispatch(fetchAsync(deliveriesForm, false, true));
  };
}
