/**
 * Created by zekar on 3/3/2017.
 */

import axios from 'axios';
import * as qs from 'qs';
import { BACKEND_HOST_AUTH, endpoints } from '../constants/api.constants';
import ApiUtils from '../helpers/ApiUtils';
import { LocalStorageUserModel } from '../helpers/AuthUtils';
import LoginUserRequest1ViewModel from '../viewModels/account/loginUserRequest1ViewModel';
import { ErrorWrapper } from '../viewModels/base';
import AccountChangePasswordRequestViewModel from '../viewModels/account/AccountChangePasswordRequestViewModel';

/////////////////////////////
/// USER SERVICE
/////////////////////////////

/* tslint:disable:variable-name */
export class TokenInfo {
  access_token: string;
  expires_in: number;
  resource: string;
  token_type: string;
  refresh_token: string;
}
/* tslint:enable:variable-name */

function getRoot(): string {
  return `${BACKEND_HOST_AUTH()}api/account`;
}

export default class AccountApi {
  public static async loginUser(viewModel: LoginUserRequest1ViewModel): Promise<TokenInfo> {
    // let isSuccess = true;

    const url = BACKEND_HOST_AUTH() + endpoints.auth.login;
    try {
      return await ApiUtils.handleApi<TokenInfo>(axios.post<TokenInfo>(url, qs.stringify(viewModel)), true, false);
    } catch (exception) {
      throw new ErrorWrapper('Login failed', exception);
    }
  }

  public static getCurrentUser(accessToken: string): Promise<LocalStorageUserModel> {
    return ApiUtils.handleGet<LocalStorageUserModel>(
      `${BACKEND_HOST_AUTH()}api/partner-portal/account/get-current`,
      {},
      accessToken,
    );
  }

  public static changePassword(formValues: AccountChangePasswordRequestViewModel) {
    return ApiUtils.handlePost<boolean>(`${getRoot()}/change-password`, formValues);
  }

  public static forgotPassword(formValues: any) {
    return ApiUtils.handlePost<boolean>(`${getRoot()}/forgot-password`, formValues);
  }

  public static resetPassword(formValues: any) {
    return ApiUtils.handlePost<boolean>(`${getRoot()}/reset-password`, formValues);
  }

  public static toggleActivation(userId: number, activated: boolean) {
    return ApiUtils.handlePut<boolean>(`${getRoot()}/toggle-activation/${userId}/${activated}`, null);
  }
}
