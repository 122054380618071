import * as React from 'react';
import DatePicker from 'react-datetime';
import { Field, GenericFieldHTMLAttributes, BaseFieldProps, WrappedFieldProps } from 'redux-form';
import * as moment from 'moment';
import $ from 'jquery';

class XXXField extends Field<
  GenericFieldHTMLAttributes &
    BaseFieldProps & {
      placeHolder: string;
      showTimeSelect?: boolean;
      defaultAddHours?: number;
      customTimeSelectFunction?: Function;
    }
> {}

class SpeysDatePickerInnerComponent extends React.Component<
  WrappedFieldProps &
    GenericFieldHTMLAttributes &
    BaseFieldProps & {
      placeHolder: string;
      showTimeSelect?: boolean;
      defaultAddHours?: number;
      customTimeSelectFunction?: Function;
    }
> {
  constructor(props: any) {
    super(props);
    this.handleTimeIconClick = this.handleTimeIconClick.bind(this);
  }

  UNSAFE_componentWillMount(): void {
    $(document).ready(function () {
      $('.rdt.readonly').find('input').attr('readonly', 'true');
    });
  }

  handleTimeIconClick(): void {
    if (this.props.customTimeSelectFunction !== undefined) {
      this.props.customTimeSelectFunction();
    }
  }
  myInp?: any;
  render(): JSX.Element {
    const { input, className, customTimeSelectFunction } = this.props;
    const { value, onChange } = input;
    const momentDate = !value ? undefined : moment.isMoment(value) ? value : moment.utc(value);
    return (
      <div className="input-group">
        <DatePicker
          utc={true}
          ref={(ip) => (this.myInp = ip)}
          dateFormat={'DD.MM.YYYY'}
          timeFormat={this.props.showTimeSelect !== null && this.props.showTimeSelect ? 'HH:mm' : false}
          className={className}
          value={momentDate}
          onChange={(date: string): void => {
            const momentDate2 = moment(date);
            if (moment(date).isValid()) {
              if (momentDate2.hour() === 0 && momentDate2.minute() === 0 && this.props.defaultAddHours !== undefined) {
                momentDate2.hours(this.props.defaultAddHours);
              }
              onChange(momentDate2);
            } else {
              onChange(null);
            }
          }}
        />
        <div
          className="input-group-append pointer"
          onClick={(): void => {
            if (this.myInp) {
              this.myInp.openCalendar();
            }
          }}
        >
          <span className="input-group-text">
            <i className="icon-calendar"></i>
          </span>
        </div>
        {customTimeSelectFunction !== undefined ? (
          <div className="input-group-append">
            <span
              className="input-group-text  bg-info text-white pointer"
              onClick={(): void => this.handleTimeIconClick()}
            >
              <i className="icon-clock"></i>
            </span>
          </div>
        ) : null}
      </div>
    );
  }
}

class SpeysDatePickerComponentProps {
  name: string;
  placeHolder: string;
  className: string;
  showTimeSelect?: boolean;
  defaultAddHours?: number;
  customTimeSelectFunction?: Function;
}

export class SpeysCloudDatepicker2FieldComponent extends React.Component<SpeysDatePickerComponentProps> {
  render(): JSX.Element {
    const { name, placeHolder, className, showTimeSelect, defaultAddHours, customTimeSelectFunction } = this.props;
    return (
      <XXXField
        name={name}
        className={className}
        component={SpeysDatePickerInnerComponent}
        placeHolder={placeHolder}
        showTimeSelect={showTimeSelect}
        defaultAddHours={defaultAddHours}
        customTimeSelectFunction={customTimeSelectFunction}
      />
    );
  }
}
