import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './app';
import { unregister } from './registerServiceWorker';
import { Provider } from 'react-redux';
import createStore from './store';
import { start } from './app/actions';

import './index.scss';
import './assets/theme.css';
import './assets/custom-inputs.css';
import './assets/ladda.css';
import './assets/table.css';
import './assets/loading.css';
import { IS_PRODUCTION } from './constants/base.constants';

declare global {
  interface Window {
    dataLayer: any;
  }
}

export const store = createStore(window['__INITIAL_STATE__']);
store.dispatch(start());

if (IS_PRODUCTION) {
  window.dataLayer = window.dataLayer || [];
  const gtag = function (js, config) {
    window.dataLayer.push(arguments); // eslint-disable-line prefer-rest-params
  };

  gtag('js', new Date());
  gtag('config', process.env.REACT_APP_GA_MEASUREMENT_ID);
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root') as HTMLElement,
);
unregister();
