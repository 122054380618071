import actionCreatorFactory from 'typescript-fsa';
import { Dispatch } from 'redux';
import { initialize } from 'redux-form';
import { companyFormName } from '../../components/Company.component';
import { ErrorWrapper, NotFoundErrorWrapper } from '../../../../../../../viewModels/base';
import { CompanyApi } from '../../../../../../../api/Company.api';
import CommonUtilities from '../../../../../../../helpers/CommonUtilities';
import CompanyResponseViewModel from '../../../../../../../viewModels/company/CompanyResponseViewModel';

const actionCreator = actionCreatorFactory();
const type = 'COMPANY/EDIT/START';
export const asyncActions = actionCreator.async<
  {},
  { addressId: number; company: CompanyResponseViewModel },
  ErrorWrapper
>(type);

export default function start(addressId: number) {
  return async (dispatch: Dispatch<any>) => {
    async function mainAction() {
      dispatch(asyncActions.started({}));
      const address = await CompanyApi.getSingle(addressId);
      if (!address) {
        throw new NotFoundErrorWrapper(`Item with Id ${addressId} not found`);
      }
      dispatch(initialize(companyFormName, address));
      dispatch(asyncActions.done({ params: {}, result: { addressId: addressId, company: address } }));
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
