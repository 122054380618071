import * as React from 'react';
import { AllDeliveryComponentProps } from './Shipment.component';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import FileUploadComponent from './Shipment.FileUpload.component';
/*OTHER*/
import { routePaths } from '../../../../../../constants/api.constants';
import ReusableComponentUtilities from '../../../../../../helpers/ReusableComponentUtilities';
import { UploadFileTypes } from '../../../../../../constants/base.constants';
// import {StickyContainer} from "react-sticky";

export declare type ShipmentDocumentsTabComponentProps = AllDeliveryComponentProps & {
  shipment: any;
  loadingLeg: boolean;
  getFiles: Function;
  uploadFile: Function;
  downloadFile: Function;
  deleteFile: Function;
};

export default class ShipmentDocumentsTabComponent extends React.Component<
  ShipmentDocumentsTabComponentProps,
  {
    alert?: any;
  }
> {
  constructor(props: any) {
    super(props);
    this.handleSaveAndConfirm = this.handleSaveAndConfirm.bind(this);
    this.handleDebtorChange = this.handleDebtorChange.bind(this);
    this.state = {
      alert: null,
    };
  }

  async componentDidMount(): Promise<void> {
    this.props.getFiles(this.props.deliveryId);
  }

  goToList = (): void => {
    this.props.history.push(routePaths.shipments.list);
  };

  handleSave = (): void => {
    this.props.submitItem(this.goRefreshPage);
  };

  goRefreshPage = (): void => {
    if (this.props.deliveryId === undefined || this.props.deliveryId === 0) {
      this.props.history.push(routePaths.shipments.list);
    } else {
      this.props.startItem(this.props.deliveryId);
    }
  };

  handleChangeStatus = (id: number, statusId: number): void => {
    // do nothing
  };

  handleDebtorChange(id: number) {
    this.props.updateDebtorInfo(id, 'sender');
  }

  handleSaveAndConfirm = () => {
    const shipment = this.props.shipment;

    if (shipment === undefined) {
      return;
    }
  };

  render() {
    const errorWrapper = this.props.errorWrapper;
    const errorRowComponent = ReusableComponentUtilities.getErrorRowComponent(errorWrapper);

    return (
      <Row>
        <Col md={12}>
          <div className={'mt-15'}>{errorRowComponent}</div>
          <div className={'card'}>
            <div className={'card-body'}>
              <FileUploadComponent
                isFetching={this.props.loading}
                externalId={this.props.deliveryId ? this.props.deliveryId : 0}
                files={this.props.files}
                deletingId={0}
                getFiles={this.props.getFiles}
                uploadFile={this.props.uploadFile}
                downloadFile={this.props.downloadFile}
                deleteFile={this.props.deleteFile}
                fileType={UploadFileTypes.shipment}
                expandableComponent={false}
                hideDeleteButton={true}
                hideUploadBox={false}
              />
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}
