import { BACKEND_HOST_MAIN, BACKEND_HOST_TRACKING } from '../constants/api.constants';
import { PaginatedListResult } from '../viewModels/base';
import TrackingsRequestViewModel from '../viewModels/trackings/TrackingsRequestViewModel';
import TrackingsResponseViewModel from '../viewModels/trackings/TrackingsResponseViewModel';
import ApiUtilities from '../helpers/ApiUtils';
import TrackingResponseViewModel from '../viewModels/trackings/TrackingResponseViewModel';
import TrackingCreateOrUpdateRequestViewModel from '../viewModels/trackings/TrackingCreateOrUpdateRequestViewModel';

function getRoot() {
  return `${BACKEND_HOST_MAIN}api/trackings`;
}

function getTrackingRoot() {
  return `${BACKEND_HOST_TRACKING}api`;
}

export default class TrackingApi {
  public static getList(params: TrackingsRequestViewModel): Promise<PaginatedListResult<TrackingsResponseViewModel>> {
    return ApiUtilities.handleGet<PaginatedListResult<TrackingsResponseViewModel>>(getRoot(), { ...params });
  }

  public static getSingle(id: number): Promise<TrackingResponseViewModel | null> {
    return ApiUtilities.handleGet<TrackingResponseViewModel>(`${getRoot()}/${id}`);
  }

  public static add(formValues: TrackingCreateOrUpdateRequestViewModel) {
    return ApiUtilities.handlePost<number>(getRoot(), formValues);
  }

  public static update(id: number, formValues: TrackingCreateOrUpdateRequestViewModel) {
    return ApiUtilities.handlePut<boolean>(`${getRoot()}/${id}`, formValues);
  }

  public static remove(id: number) {
    return ApiUtilities.handleDelete(`${getRoot()}/${id}`);
  }

  public static getSticknTrackDevices(): Promise<PaginatedListResult<TrackingsResponseViewModel>> {
    return ApiUtilities.handleGet<PaginatedListResult<TrackingsResponseViewModel>>(`${getTrackingRoot()}/stickntrack`);
  }
}
