import * as React from 'react';
import { Button, Col, Row, Table } from 'reactstrap';
import imageDocIcon from '../assets/doc_icon.png';
import Dropzone from 'react-dropzone';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import ShipmentExpandableBlockComponent from './Shipment.Expandable.Block.component';
import LocaleUtilities from '../../../../../../helpers/LocaleUtilities';
import SpeysCloudLoadingComponent from '../../../../../../components/SpeysCloud.Loading.component';
import SweetAlert from '../../../../../../components/SweetAlert/SweetAlert.component';
import { UploadFileTypes } from '../../../../../../constants/base.constants';

const Translate = require('react-redux-i18n').Translate;

interface FileUploadComponentState {
  acceptedFiles: any[];
  rejectedFiles: any[];
  alert?: any;
}

interface FileUploadComponentProps {
  isFetching: boolean;
  externalId: number;
  files: any[] | undefined;
  deletingId: number;
  fileType: UploadFileTypes;
  getFiles: Function;
  uploadFile: Function;
  deleteFile: Function;
  downloadFile: Function;
  expandableComponent: boolean;
  hideUploadBox: boolean;
  hideDeleteButton: boolean;
}

class ShipmentFileUploadComponent extends React.Component<FileUploadComponentProps, FileUploadComponentState> {
  constructor(props: any) {
    super(props);
    this.state = { acceptedFiles: [], rejectedFiles: [] };
    this.clearFiles = this.clearFiles.bind(this);
    this.onImageUpload = this.onImageUpload.bind(this);
    this.uploadSubmit = this.uploadSubmit.bind(this);
    this.deleteSubmit = this.deleteSubmit.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
  }

  clearFiles() {
    this.setState({ acceptedFiles: [], rejectedFiles: [] });
  }

  onImageUpload(acceptedFiles: any, rejectedFiles: any) {
    this.setState({ acceptedFiles });
  }

  uploadSubmit(e: any, externalId: number, fileType: UploadFileTypes) {
    e.preventDefault();
    this.props.uploadFile(
      externalId,
      this.state.acceptedFiles,
      () => {
        this.clearFiles();
        this.props.getFiles(externalId);
      },
      fileType,
    );
  }

  deleteSubmit(e: any, file: any, externalId: number) {
    e.preventDefault();
    this.setState({
      alert: (
        <SweetAlert
          title={LocaleUtilities.i18n('Are you sure', 'confirm.modal')}
          type="danger"
          onConfirm={() => this.props.deleteFile(externalId, file.id, () => this.props.getFiles(externalId))}
          onCancel={() => this.setState({ alert: undefined })}
        >
          <p>{LocaleUtilities.i18n('Are you sure you want to delete this file?', 'shipment.files')}</p>
        </SweetAlert>
      ),
    });
  }

  handleRefresh(e: any) {
    e.preventDefault();
    this.props.getFiles(this.props.externalId);
  }

  renderUploadComponent() {
    return (
      <Segment className="form-group-sm-margin no-border m-0">
        <Dimmer active={this.props.isFetching}>
          <Loader />
        </Dimmer>
        <Row className="margin-top">
          <Col md={12}>
            {this.props.isFetching ? <div className="overlay-wrapper">Loading...</div> : null}
            <Dropzone
              onDrop={(acceptedFiles, rejectedFiles) => this.onImageUpload(acceptedFiles, rejectedFiles)}
              multiple={true}
              maxSize={4 * 1024 * 1024}
              className="filepicker dropzone dz-clickable"
            >
              <div className="dz-default dz-message">
                <span className=" ti-cloud-up fa-4x" />
                <p>
                  <Translate value="assignment.documents.drop_files_here_to_upload" />
                </p>
                <p className="dropify-error hidden">Ooops, something wrong appended.</p>
              </div>
            </Dropzone>
          </Col>
          <Col md={12} className="mt-10">
            {this.state.acceptedFiles.length > 0 ? (
              <div>
                <span className="text-bold">
                  <Translate value="assignment.documents.ready_to_upload" />
                  &nbsp;
                  {this.state.acceptedFiles.length}{' '}
                  {this.state.acceptedFiles.length === 1 ? (
                    <Translate value="assignment.documents.file" />
                  ) : (
                    <Translate value="assignment.documents.files" />
                  )}
                </span>
                <ul className={'ml-25'}>
                  {this.state.acceptedFiles.map((file, i) => (
                    <li key={i}>{file.name}</li>
                  ))}
                </ul>
                <Button
                  disabled={this.props.isFetching}
                  className="btn btn-primary btn-white-text"
                  onClick={(e) => this.uploadSubmit(e, this.props.externalId, this.props.fileType)}
                >
                  <Translate value="base.button.start_upload" />
                </Button>
              </div>
            ) : null}
          </Col>
        </Row>
      </Segment>
    );
  }

  renderTableComponent() {
    return (
      <div className="table-responsive">
        <Table hover={true} bordered={false}>
          <thead>
            <tr>
              <th>{LocaleUtilities.i18n('shipment-documents-Uploaded files', 'shipment.files')}</th>
              <th className={'text-right'}>
                <div className="font-bold">
                  <i className="icon-refresh pointer" title="Refresh" onClick={this.handleRefresh} />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {!this.props.files || !this.props.files.length ? (
              <tr>
                <td colSpan={2} className="note">
                  {LocaleUtilities.i18n('shipment-documents-There are no uploaded files', 'shipment.files')}
                </td>
              </tr>
            ) : null}
            {this.props.files &&
              this.props.files.map((file, i) => (
                <tr key={i}>
                  <td>
                    <img src={imageDocIcon} width="14" alt="" />
                    &nbsp;{file.name}
                  </td>
                  <td className="text-right">
                    <div className="btn-group">
                      <span
                        className="btn btn-info btn-sm"
                        onClick={(e) => {
                          this.props.downloadFile(this.props.externalId, file.id, true);
                        }}
                      >
                        {LocaleUtilities.i18n('base-button-Open', 'base.button', true)}
                      </span>
                      <span
                        className="btn btn-success dark btn-sm"
                        onClick={(e) => this.props.downloadFile(this.props.externalId, file.id)}
                      >
                        {LocaleUtilities.i18n('base-button-Download', 'base.button', true)}
                      </span>
                      {!this.props.hideDeleteButton && (
                        <Button
                          disabled={this.props.deletingId === file.id && this.props.deletingId > -1}
                          className="btn btn-danger dark btn-sm btn-white-text"
                          onClick={(e) => this.deleteSubmit(e, file, this.props.externalId)}
                        >
                          {LocaleUtilities.i18n('base-button-Delete', 'base.button', true)}
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    );
  }

  render() {
    return (
      <div className={'mt-10'}>
        {this.state.alert}
        {this.props.expandableComponent === true ? (
          <span>
            {this.props.hideUploadBox === false && (
              <ShipmentExpandableBlockComponent
                key={1}
                panelKey={1}
                title={'Upload files'}
                icon={'ti-upload'}
                expanded={true}
              >
                {this.renderUploadComponent()}
              </ShipmentExpandableBlockComponent>
            )}
            <ShipmentExpandableBlockComponent
              key={0}
              panelKey={0}
              title={LocaleUtilities.i18n('shipment-block-Files', 'shipment.details', true)}
              icon={'icon-docs'}
              expanded={true}
            >
              {this.renderTableComponent()}
            </ShipmentExpandableBlockComponent>
          </span>
        ) : (
          <span>
            <SpeysCloudLoadingComponent
              isLoading={this.props.isFetching}
              error={undefined}
              showOverlayType={'white-overlay'}
            />
            {this.props.hideUploadBox === false && this.renderUploadComponent()}
            {this.renderTableComponent()}
          </span>
        )}
      </div>
    );
  }
}

export default ShipmentFileUploadComponent;
