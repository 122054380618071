import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Dispatch } from 'redux';
import { addressFormName } from '../../components/Address.component';
import { ErrorWrapper } from '../../../../../../../viewModels/base';

import { AddressState, REDUCER_KEY__ADDRESS } from '../../Address.reducer';
import { AddressApi } from '../../../../../../../api/Address.api';
import { toastr } from 'react-redux-toastr';

import CommonUtilities from '../../../../../../../helpers/CommonUtilities';

const actionCreator = actionCreatorFactory();
const type = 'ADDRESS/EDIT/SUBMIT';
export const asyncActions = actionCreator.async<{}, {}, ErrorWrapper>(type);

export default function submit(onSuccess: Function) {
  return async (dispatch: Dispatch<AnyAction>, getState: Function) => {
    async function mainAction() {
      dispatch(asyncActions.started({ confirmLoading: true }));
      const state = getState();
      const currentState: AddressState = state[REDUCER_KEY__ADDRESS];
      const formValues = state.form[addressFormName].values || {};
      if (!formValues) {
        throw new ErrorWrapper('Nothing was filled');
      }
      if (!currentState.addressId) {
        throw new ErrorWrapper('Id is missing');
      }
      console.log(currentState.addressId, formValues);
      await AddressApi.update(currentState.addressId, formValues);
      dispatch(asyncActions.done({ params: { confirmLoading: false }, result: {} }));
      toastr.success('Success', 'Item was successfully updated');
      if (onSuccess) {
        onSuccess();
      }
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
      toastr.error('Error', 'Item was not updated');
      CommonUtilities.scrollToTop();
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
