export enum ShipmentType {
  NotSelected = 0,
  Sender = 5,
  Receiver = 10,
}
export enum DeliveryStatus {
  NotSelected = 0,
  Draft = 1,
  Canceled = 5,
  MissingData = 10,
  Received = 15,
  Confirmed = 20,
  Processing = 25,
  Collected = 27,
  PickedUp = 30,
  InTransit = 40,
  Delivering = 45,
  Delivered = 50,
  LastDelivered = 60,
  WaitingReadinessRequest = 70,
}

export enum ShippingOrderStatus {
  NotSelected = 0,
  New = 1,
  Canceled = 2,
  InProgress = 5,
  PickedUp = 10,
  Delivered = 15,
  Invoiced = 20,
}

export enum RequestStatus {
  Open = 0,
  Closed = 1,
  Accepted = 2,
  Rejected = 3,
}

export enum ShipmentLeg {
  NotSelected = 0,
  ShippingOrder = 5,
  Collection = 10,
  TerminalHandling = 15,
  HarbourHandling = 20,
  SeaFreight = 25,
  Other = 30,
}

export enum ParcelType {
  NotSelected = 0,
  Eur = 1,
  Fin = 2,
  Pallet = 3,
  L465 = 4,
  Trolley = 5,
  Package = 7,
  Box = 8,
  Canister = 9,
  FullTruckLoad = 10,
  LongPallet = 11,
  HalfPallet = 12,
  Ldm = 13,
}
