import { BACKEND_HOST_TRANSLATIONS } from '../constants/api.constants';
import { PaginatedListResult } from '../viewModels/base';
import ApiUtilities from '../helpers/ApiUtils';
import TranslationsRequestViewModel from '../app/routes/Admin/Translations/viewModels/TranslationsRequestViewModel';
import TranslationsResponseViewModel from '../app/routes/Admin/Translations/viewModels/TranslationsResponseViewModel';

function getRoot() {
  return `${BACKEND_HOST_TRANSLATIONS()}api/translation`;
}

export default class TranslationApi {
  public static async getAll(): Promise<any> {
    return await ApiUtilities.handleGet<any>(`${getRoot()}/app/PartnerPortal`);
  }

  public static async getList(
    options: TranslationsRequestViewModel,
  ): Promise<PaginatedListResult<TranslationsResponseViewModel>> {
    return await ApiUtilities.handleGet<PaginatedListResult<TranslationsResponseViewModel>>(
      `${getRoot()}/app/PartnerPortal/grouped-by-key`,
      options,
    );
  }

  /**
   * Updates or inserts an item
   */
  public static async upsert(
    key: string,
    translations: { english: string | null; finnish: string | null; estonian: string | null },
    group?: string,
  ) {
    if (group !== null) {
      // var pieces = key.toLowerCase().split('-');
      // group = pieces[0];
    }
    const URL = `${getRoot()}/app/PartnerPortal/key/${key}/group/${group}`;
    return await ApiUtilities.handlePut<boolean>(URL, translations);
  }
}
