import * as React from 'react';

export default class SpeysCloudLoaderComponent extends React.Component<any> {
  render() {
    return (
      <div className="preloader" style={{ display: 'block' }}>
        <div className="loader">
          <div className="loader__figure" />
          <p className="loader__label">Speys Partner Portal</p>
        </div>
      </div>
    );
  }
}
