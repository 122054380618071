import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { asyncActions as fetchAsyncActions } from '../../Shipment/Shipment.Tracking/actions/ShipmentTracking.fetch.action';
import TrackingsResponseViewModel from '../../../../../viewModels/trackings/TrackingsResponseViewModel';
import { ErrorWrapper, TableData } from '../../../../../viewModels/base';

export const REDUCER_KEY__DELIVERY_TRACKING = 'deliverytracking';

export interface DeliveryTrackingReducerState {
  type?: string;
  loading: boolean;
  tableData: TableData<TrackingsResponseViewModel>;
  error?: ErrorWrapper;
  deliveryId: number;
}

const defaultState: DeliveryTrackingReducerState = {
  loading: false,
  tableData: TableData.getDefault<TrackingsResponseViewModel>(),
  deliveryId: 0,
};
export default (state: DeliveryTrackingReducerState = defaultState, action: Action): DeliveryTrackingReducerState => {
  // Fetch
  if (isType(action, fetchAsyncActions.started)) {
    return { ...state, type: action.type, loading: true };
  }
  if (isType(action, fetchAsyncActions.done)) {
    return { ...state, type: action.type, loading: false, tableData: action.payload.result.data };
  }
  if (isType(action, fetchAsyncActions.failed)) {
    return { ...state, type: action.type, loading: false, error: action.payload.error };
  }
  return state;
};
