import actionCreatorFactory from 'typescript-fsa';
import { Dispatch } from 'redux';
import { initialize } from 'redux-form';
import { userFormName } from '../../components/User.component';
import { ErrorWrapper, NotFoundErrorWrapper } from '../../../../../../../viewModels/base';
import { UserApi } from '../../../../../../../api/User.api';
import CommonUtilities from '../../../../../../../helpers/CommonUtilities';

const actionCreator = actionCreatorFactory();
export const asyncActions = actionCreator.async<{}, { addressId: number }, ErrorWrapper>('USER/EDIT/START');

export default function start(addressId: number) {
  return async (dispatch: Dispatch<any>) => {
    async function mainAction() {
      dispatch(asyncActions.started({}));
      const address = await UserApi.getSingle(addressId);
      if (!address) {
        throw new NotFoundErrorWrapper(`Item with Id ${addressId} not found`);
      }
      dispatch(initialize(userFormName, address));
      dispatch(asyncActions.done({ params: {}, result: { addressId } }));
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
