import * as React from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

export default class PageTitleComponent extends React.Component<{ title: any }> {
  render() {
    return (
      <Row className={'mt-30'}>
        <Col md={12}>
          <div className="page-title d-flex justify-content-between align-items-center mb-0">
            <div className="page-title-wrap ml-20">
              <h2 className="">
                <span>{this.props.title}</span>
              </h2>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}
