import { Moment } from 'moment';

export default class RequestResponseViewModel {
  id: number;
  partnerId: number;
  price: string;
  status: string;
  shipments: Array<ShippingOrderShipment>;
  quantity: number;
  weight?: number;
  volume?: number;
}

export class ShippingOrder {
  shipment?: Array<ShippingOrderShipment>;
}

export class ShippingOrderProductRow {
  id: number;
  quantity: number;
  typeId?: number;
  length?: number;
  width?: number;
  height?: number;
  weight?: number;
  stackable?: boolean;
}

export class ShippingOrderShipment {
  id: number;
  number?: number;
  weight?: number;
  volume?: number;
  quantity?: number;
  loadingMeters?: number;
  consignor: string;
  consignorContactPerson: string;
  consignorContactPersonPhone: string;
  loadingCountry: string;
  loadingCity: string;
  loadingPostCode: string;
  loadingAddressLine1: string;
  loadingDate?: Date | Moment;

  consignee: string;
  consigneeContactPerson: string;
  consigneeContactPersonPhone: string;
  deliveryCountry: string;
  deliveryCity: string;
  deliveryPostCode: string;
  deliveryAddressLine1: string;
  deliveryDate?: Date | Moment;
  rows?: Array<ShippingOrderProductRow>;
}
