import * as React from 'react';
import * as moment from 'moment';
import { RouteComponentProps, withRouter } from 'react-router';
import LocaleUtilities from './LocaleUtilities';
import Badge from 'reactstrap/lib/Badge';
import Button from 'reactstrap/lib/Button';
import SeedUtilities from './SeedUtilities';
import { SpeysPagination, TableData } from '../viewModels/base';
import { Dispatch } from 'redux';
import { SortOrder } from 'speys-react-bootstrap-table';
import { Link } from 'react-router-dom';

export default class TableFormattingUtilities {
  public static dateTimeFormat(cell: any): JSX.Element {
    if (!cell) {
      return <div />;
    }
    return <div>{moment(cell).format(LocaleUtilities.DateTimeFormat)}</div>;
  }

  public static dateFormat(cell: any): JSX.Element {
    if (!cell) {
      return <span />;
    }
    return <span>{moment(cell).format(LocaleUtilities.DateFormat)}</span>;
  }

  public static currencyFormat(cell: any, currency: string): JSX.Element {
    if (!cell) {
      return <div />;
    }
    return <div>{`${cell} ${currency}`}</div>;
  }

  public static boldTextFormat(cell: any): JSX.Element {
    if (!cell) {
      return <div />;
    }
    return <span className="font-bold">{`${cell}`}</span>;
  }

  public static boldTextFormatWithLink = (cell: any, row: any, path?: string, fontBlack?: boolean): JSX.Element => {
    if (path === undefined) {
      return TableFormattingUtilities.boldTextFormat(cell);
    }

    return (
      <Link
        to={path}
        className={`${fontBlack !== undefined && fontBlack === true ? 'link-text-black font-bold' : ' font-bold'}`}
      >
        {cell}
      </Link>
    );
  };

  public static selectButtonFormat = (cell: any, row: any, acceptClick: any, rejectClick: any): JSX.Element => {
    if (!cell) {
      return <div />;
    }
    return (
      <div>
        <Button color="success" onClick={(e) => acceptClick(cell)}>
          Accept
        </Button>{' '}
        <Button color="danger" onClick={(e) => rejectClick(cell)}>
          Reject
        </Button>
      </div>
    );
  };

  public static statusFormat(cell: any, shippingOrder = false): JSX.Element {
    if (!cell) {
      return <div />;
    }
    if (shippingOrder) {
      return (
        <Badge color={SeedUtilities.getShippingOrderColor(cell)}>
          {LocaleUtilities.i18n(`shippingOrder-status-${cell}`, 'shippingOrder.status', true)}
        </Badge>
      );
    }
    return (
      <Badge color={SeedUtilities.getStatusColor(cell)}>
        {LocaleUtilities.i18n(`shipment-status-${cell}`, 'shipment.status', true)}
      </Badge>
    );
  }

  public static actionFormat = (cell: any, row: any, path: string, deleteAction: (id: number) => any): JSX.Element => {
    return <div className="text-right"></div>;
  };

  public static getOnSortChangeAction(
    submit: (SpeysPagination) => any,
    reducerKey: string,
    sortName: string,
    sortOrder: SortOrder,
  ) {
    return async (dispatch: Dispatch<any>, getState: Function) => {
      const currentState = getState()[reducerKey];
      const tableData: TableData<any> = currentState.tableData;
      const viewModel: SpeysPagination = tableData.pagination;
      viewModel.sortName = sortName;
      viewModel.sortOrder = sortOrder;
      dispatch(submit(viewModel));
    };
  }

  public static getOnPageChangeAction(
    submit: (SpeysPagination) => any,
    reducerKey: string,
    page: number,
    sizePerPage: number,
  ) {
    return async (dispatch: Dispatch<any>, getState: Function) => {
      const currentState = getState()[reducerKey];
      const tableData: TableData<any> = currentState.tableData;
      const viewModel: SpeysPagination = tableData.pagination;
      viewModel.page = page;
      viewModel.sizePerPage = sizePerPage;
      dispatch(submit(viewModel));
    };
  }

  public static getOnSizePerPageList(submit: (SpeysPagination) => any, reducerKey: string, sizePerPage: number) {
    return async (dispatch: Dispatch<any>, getState: Function) => {
      const currentState = getState()[reducerKey];
      const tableData: TableData<any> = currentState.tableData;
      const viewModel: SpeysPagination = tableData.pagination;
      viewModel.sizePerPage = sizePerPage;
      dispatch(submit(viewModel));
    };
  }
}

interface TableActionButtonsComponentProps {
  editLinkPath: string;
  deleteAction: () => any;
}

class TableActionButtonsComponentInner extends React.Component<
  TableActionButtonsComponentProps & RouteComponentProps<any>
> {
  render() {
    const {} = this.props;

    return <div></div>;
  }
}

export const TableActionButtonsComponent = withRouter(TableActionButtonsComponentInner);
