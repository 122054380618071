import { ErrorWrapper } from '../../../../../viewModels/base';

import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { asyncActions as fetchStartAction } from './actions/Request.fetch.action';
import { asyncActions as updateStatusActions } from './actions/Request.update.status.action';
import { asyncActions as updatePriceActions } from './actions/Request.price.action';
import RequestResponseViewModel from '../../../../../viewModels/request/RequestResponseViewModel';
export const REDUCER_KEY__REQUEST = 'request';

export class RequestState {
  type?: string;
  errorWrapper?: ErrorWrapper;
  requestId?: number;
  loading: boolean;
  createLoading: boolean;
  shippingOrderStatus: number;
  request?: RequestResponseViewModel;
  requestStatus: number;
  price: string;
}

const defaultState: RequestState = {
  requestId: 0,
  shippingOrderStatus: 0,
  loading: false,
  createLoading: false,
  request: undefined,
  requestStatus: 0,
  price: '',
};

export default (state: RequestState = defaultState, action: Action): RequestState => {
  //FETCH
  if (isType(action, fetchStartAction.started)) {
    return { ...state, type: action.type, loading: true };
  }
  if (isType(action, fetchStartAction.done)) {
    return {
      ...state,
      type: action.type,
      loading: false,
      requestId: action.payload.result.requestId,
      request: action.payload.result.request,
      errorWrapper: undefined,
    };
  }
  if (isType(action, fetchStartAction.failed)) {
    return {
      ...state,
      type: action.type,
      loading: false,
      errorWrapper: action.payload.error,
      request: undefined,
    };
  }

  // Update status

  if (isType(action, updateStatusActions.started)) {
    return { ...state, type: action.type, loading: true };
  }
  if (isType(action, updateStatusActions.done)) {
    return { ...state, type: action.type, loading: false, requestStatus: action.payload.result.status };
  }
  if (isType(action, updateStatusActions.failed)) {
    return { ...state, type: action.type, loading: false };
  }

  //Update Price

  if (isType(action, updatePriceActions.started)) {
    return { ...state, type: action.type, loading: true };
  }
  if (isType(action, updatePriceActions.done)) {
    return { ...state, type: action.type, loading: false, price: action.payload.result.price };
  }
  if (isType(action, updatePriceActions.failed)) {
    return { ...state, type: action.type, loading: false };
  }

  return state;
};
