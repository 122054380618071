import { BACKEND_HOST_FILE_UPLOAD } from '../constants/api.constants';
import ApiUtilities from '../helpers/ApiUtils';
import { ShipmentCommentResponseViewModel } from '../viewModels/delivery/comments';

// import CommonUtilities from '../helpers/CommonUtilities';

function getRoot() {
  return `${BACKEND_HOST_FILE_UPLOAD}api/object_comment`;
}

export class ObjectSimpleLogApi {
  public static addComment(externalId: number, formData: any, objectName: string) {
    return ApiUtilities.handlePost(`${getRoot()}/${externalId}/add-comment/${objectName}`, formData);
  }

  public static getComments(externalId: number, objectName: string) {
    return ApiUtilities.handleGet(`${getRoot()}/${externalId}/get-comments/${objectName}`);
  }

  public static remove(externalId: number, objectName: string) {
    return ApiUtilities.handleDelete(`${getRoot()}/${externalId}/delete-comment/${objectName}`);
  }

  public static getLatestPublic(
    externalId: number,
    objectName: string,
  ): Promise<ShipmentCommentResponseViewModel | null> {
    return ApiUtilities.handleGet<ShipmentCommentResponseViewModel>(
      `${getRoot()}/${externalId}/latest-public/${objectName}`,
    );
  }
}
