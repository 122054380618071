import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Dispatch } from 'redux';

import CommonUtilities from 'helpers/CommonUtilities';
import { ErrorWrapper } from 'viewModels/base';
//
// Change shipment status action
//
export type NetworkChangedPayload = {
  online: boolean;
};
const actionCreator = actionCreatorFactory();
const type = 'APP/NETWORK_CHANGED';
export const asyncActions = actionCreator.async<{}, NetworkChangedPayload, ErrorWrapper>(type);

export default function networkChangedAction(online: boolean): Function {
  return async (dispatch: Dispatch<AnyAction>): Promise<void> => {
    async function mainAction(): Promise<void> {
      const result = {
        online,
      };

      dispatch(asyncActions.done({ params: {}, result }));
    }

    async function catchAction(exception: ErrorWrapper): Promise<void> {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
