import * as React from 'react';
import { Route } from 'react-router-dom';
import { injectAsyncReducer } from '../../../store';
import asyncComponent from '../../../util/asyncComponent';
import { store } from 'index';
import { routePaths } from '../../../constants/api.constants';
import { Switch } from 'react-router';
import { UserModelRoles } from '../../../helpers/AuthUtils/AuthUtils';

/*COMPONENTS*/
import PrivateRoute from '../../../components/SpeysCloud.PrivateRoute.component';
import Layout from '../../../components/Layout';

/*REDUCERS*/
import { REDUCER_KEY__DELIVERIES } from './Shipment/Shipment.List/Shipments.reducer';
import { REDUCER_KEY__DELIVERY } from './Shipment/Shipment.View/Shipment.reducer';
import { REDUCER_KEY__DELIVERY_TRACKING } from './Shipment/Shipment.Tracking/Shipment.Tracking.reducer';
import { REDUCER_KEY__COMPANY } from './Company/Company.View/Company.reducer';
import { REDUCER_KEY__USERS } from './Users/List/Users.reducer';
import { REDUCER_KEY__USER } from './Users/View/User.reducer';
import { REDUCER_KEY__REQUESTS } from './Request/Request.List/Request.reducer';
import { REDUCER_KEY__REQUEST } from './Request/Request.View/Request.reducer';
import { REDUCER_KEY__VEHICLES } from './Vehicle/Vehicle.List/Vehicles.reducer';
import { REDUCER_KEY__VEHICLE } from './Vehicle/Vehicle.View/Vehicle.reducer';
import { REDUCER_KEY__ADDRESSBOOK } from './AddressBook/AddressBook.List/AddressBook.reducer';
import { REDUCER_KEY__ADDRESS } from './AddressBook/AddressBook.Address/Address.reducer';
import { REDUCER_KEY__PROFILE } from '../Common/Profile/Profile.reducer';

class ForAdminRoutesComponent extends React.Component<any> {
  render(): JSX.Element {
    return (
      <Layout>
        <Switch>
          {[routePaths.profile].map((path, index) => (
            <Route
              exact={true}
              path={path}
              key={index}
              component={asyncComponent(async () => {
                const container = await import('../Common/Profile/Profile.container');
                const reducer = await import('../Common/Profile/Profile.reducer');
                injectAsyncReducer(store, REDUCER_KEY__PROFILE, reducer.default);
                return container;
              })}
            />
          ))}
          {[routePaths.dashboard, routePaths.dashboard1].map((path, index) => (
            <Route
              exact={true}
              path={path}
              key={index}
              component={asyncComponent(async () => {
                const container = await import('./Dashboard/Dashboard.container');
                const reducer = await import('./Dashboard/Dashboard.reducer');
                injectAsyncReducer(store, reducer.REDUCER_NAME__DASHBOARD, reducer.default);
                return container;
              })}
            />
          ))}
          ==========================| ====| Shipments ==========================|
          <PrivateRoute
            allowedRoles={[UserModelRoles.partnerAdmin]}
            exact={true}
            path={routePaths.shipments.list}
            component={asyncComponent(async () => {
              const reducer = await import('./Shipment/Shipment.List/Shipments.reducer');
              injectAsyncReducer(store, REDUCER_KEY__DELIVERIES, reducer.default);
              return await import('./Shipment/Shipment.List');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.partnerAdmin]}
            exact={true}
            path={routePaths.shipments.new}
            component={asyncComponent(async () => {
              const reducer = await import('./Shipment/Shipment.View/Shipment.reducer');
              injectAsyncReducer(store, REDUCER_KEY__DELIVERY, reducer.default);
              return await import('./Shipment/Shipment.View/New');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.partnerAdmin]}
            exact={true}
            path={routePaths.shipments.tracking}
            component={asyncComponent(async () => {
              const reducer = await import('./Shipment/Shipment.Tracking/Shipment.Tracking.reducer');
              injectAsyncReducer(store, REDUCER_KEY__DELIVERY_TRACKING, reducer.default);
              return await import('./Shipment/Shipment.Tracking');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.partnerAdmin]}
            path={routePaths.shipments.edit}
            component={asyncComponent(async () => {
              const reducer = await import('./Shipment/Shipment.View/Shipment.reducer');
              injectAsyncReducer(store, REDUCER_KEY__DELIVERY, reducer.default);
              return await import('./Shipment/Shipment.View/Edit');
            })}
          />
          ==========================| ====| COMPANY ==========================|
          <PrivateRoute
            allowedRoles={[UserModelRoles.partnerAdmin]}
            path={routePaths.companies.myCompany}
            component={asyncComponent(async () => {
              const reducer = await import('./Company/Company.View/Company.reducer');
              injectAsyncReducer(store, REDUCER_KEY__COMPANY, reducer.default);
              return await import('./Company/Company.View/Edit');
            })}
          />
          ==========================| ====| ADDRESS BOOK ==========================|
          <PrivateRoute
            allowedRoles={[UserModelRoles.partnerAdmin]}
            exact={true}
            path={routePaths.addressBook.list}
            component={asyncComponent(async () => {
              const reducer = await import('./AddressBook/AddressBook.List/AddressBook.reducer');
              injectAsyncReducer(store, REDUCER_KEY__ADDRESSBOOK, reducer.default);
              return await import('./AddressBook/AddressBook.List');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.partnerAdmin]}
            path={routePaths.addressBook.new}
            component={asyncComponent(async () => {
              const reducer = await import('./AddressBook/AddressBook.Address/Address.reducer');
              injectAsyncReducer(store, REDUCER_KEY__ADDRESS, reducer.default);
              return await import('./AddressBook/AddressBook.Address/New');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.partnerAdmin]}
            path={routePaths.addressBook.edit}
            component={asyncComponent(async () => {
              const reducer = await import('./AddressBook/AddressBook.Address/Address.reducer');
              injectAsyncReducer(store, REDUCER_KEY__ADDRESS, reducer.default);
              return await import('./AddressBook/AddressBook.Address/Edit');
            })}
          />
          ==========================| ====| PARTNER ==========================|
          <PrivateRoute
            allowedRoles={[UserModelRoles.partnerAdmin]}
            exact={true}
            path={routePaths.vehicles.list}
            component={asyncComponent(async () => {
              const reducer = await import('./Vehicle/Vehicle.List/Vehicles.reducer');
              injectAsyncReducer(store, REDUCER_KEY__VEHICLES, reducer.default);
              return await import('./Vehicle/Vehicle.List');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.partnerAdmin]}
            path={routePaths.vehicles.new}
            component={asyncComponent(async () => {
              const reducer = await import('./Vehicle/Vehicle.View/Vehicle.reducer');
              injectAsyncReducer(store, REDUCER_KEY__VEHICLE, reducer.default);
              return await import('./Vehicle/Vehicle.View/New');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.partnerAdmin]}
            path={routePaths.vehicles.edit}
            component={asyncComponent(async () => {
              const reducer = await import('./Vehicle/Vehicle.View/Vehicle.reducer');
              injectAsyncReducer(store, REDUCER_KEY__VEHICLE, reducer.default);
              return await import('./Vehicle/Vehicle.View/Edit');
            })}
          />
          ==========================| ====| USERS ==========================| */
          <PrivateRoute
            allowedRoles={[UserModelRoles.partnerAdmin]}
            exact={true}
            path={routePaths.drivers.list}
            component={asyncComponent(async () => {
              const reducer = await import('./Users/List/Users.reducer');
              injectAsyncReducer(store, REDUCER_KEY__USERS, reducer.default);
              return await import('./Users/List');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.partnerAdmin]}
            path={routePaths.drivers.new}
            component={asyncComponent(async () => {
              const reducer = await import('./Users/View/User.reducer');
              injectAsyncReducer(store, REDUCER_KEY__USER, reducer.default);
              return await import('./Users/View/New');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.partnerAdmin]}
            path={routePaths.drivers.edit}
            component={asyncComponent(async () => {
              const reducer = await import('./Users/View/User.reducer');
              injectAsyncReducer(store, REDUCER_KEY__USER, reducer.default);
              return await import('./Users/View/Edit');
            })}
          />
          ==========================| ====| REQUESTS ==========================| */
          <PrivateRoute
            allowedRoles={[UserModelRoles.partnerAdmin]}
            exact={true}
            path={routePaths.request.list}
            component={asyncComponent(async () => {
              const reducer = await import('./Request/Request.List/Request.reducer');
              injectAsyncReducer(store, REDUCER_KEY__REQUESTS, reducer.default);
              return await import('./Request/Request.List');
            })}
          />
          <PrivateRoute
            allowedRoles={[UserModelRoles.partnerAdmin]}
            path={routePaths.request.edit}
            component={asyncComponent(async () => {
              const reducer = await import('./Request/Request.View/Request.reducer');
              injectAsyncReducer(store, REDUCER_KEY__REQUEST, reducer.default);
              return await import('./Request/Request.View');
            })}
          />
        </Switch>
      </Layout>
    );
  }
}

export default ForAdminRoutesComponent;
