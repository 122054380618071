import * as React from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

export default class DetailviewTopNavComponent extends React.Component<any> {
  render() {
    let className = 'bg-dark pt-10 pb-10 pl-20 content-container-top-nav';
    if (this.props.isFixed !== undefined && this.props.isFixed === true) {
      className = 'bg-dark pt-10 pb-10 pl-20 content-container-top-nav position-fixed';
    }
    return (
      <Row className={className}>
        <Col md={12} className="display-block">
          {this.props.children}
        </Col>
      </Row>
    );
  }
}
