import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Dispatch } from 'redux';
import { userFormName } from '../../components/User.component';
import { ErrorWrapper } from '../../../../../../../viewModels/base';

import { REDUCER_KEY__USER, UserReduxState } from '../../User.reducer';
import { UserApi } from '../../../../../../../api/User.api';
import { toastr } from 'react-redux-toastr';

import CommonUtilities from '../../../../../../../helpers/CommonUtilities';

const actionCreator = actionCreatorFactory();
export const asyncActions = actionCreator.async<{}, {}, ErrorWrapper>('USER/EDIT/SUBMIT');

export default function submit(onSuccess: Function) {
  return async (dispatch: Dispatch<AnyAction>, getState: Function) => {
    async function mainAction() {
      dispatch(asyncActions.started({ confirmLoading: true }));
      const state = getState();
      const currentState: UserReduxState = state[REDUCER_KEY__USER];
      const formValues = state.form[userFormName].values || {};
      if (!formValues) {
        throw new ErrorWrapper('Nothing was filled');
      }
      if (!currentState.userId) {
        throw new ErrorWrapper('Id is missing');
      }
      await UserApi.update(currentState.userId, formValues);
      dispatch(asyncActions.done({ params: { confirmLoading: false }, result: {} }));
      toastr.success('Success', 'Item was successfully updated');
      if (onSuccess) {
        onSuccess();
      }
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
      toastr.error('Error', 'Item was not updated');
      CommonUtilities.scrollToTop();
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
