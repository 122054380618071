import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Dispatch } from 'redux';
import { SortOrder } from 'speys-react-bootstrap-table';
import { initialize } from 'redux-form';

import { ErrorWrapper, TableData } from '../../../../../../viewModels/base';
import { AddressesResponseViewModel } from '../../../../../../viewModels/addressBook';
import { AddressApi } from '../../../../../../api/Address.api';
import AddressesRequestViewModel from '../../../../../../viewModels/addressBook/AddressesRequestViewModel1';
import { addressBookFormName } from '../AddressBook.component';
import RouterUtilities from '../../../../../../helpers/RouterUtilities';
import CommonUtilities from '../../../../../../helpers/CommonUtilities';

const actionCreator = actionCreatorFactory();
export const asyncActions = actionCreator.async<{}, { tableData: TableData<AddressesResponseViewModel> }, ErrorWrapper>(
  'ADDRESSBOOK/FETCH',
);

export declare type fetchAsyncType = (
  params: AddressesRequestViewModel | null,
  reinitializeForm: boolean,
  changeQueryParams: boolean,
) => (dispatch: Dispatch<AnyAction>, getState: Function) => Promise<void>;

const fetchAsync = (
  params: AddressesRequestViewModel | null,
  reinitializeForm: boolean,
  changeQueryParams: boolean,
) => {
  return async (dispatch: Dispatch<AnyAction>, getState: Function) => {
    const addressBookForm: AddressesRequestViewModel = params || getState().form[addressBookFormName].values;

    async function mainAction() {
      dispatch(asyncActions.started({ loading: true }));

      if (reinitializeForm) {
        dispatch(initialize(addressBookFormName, addressBookForm));
      }

      if (changeQueryParams) {
        RouterUtilities.changeQueryParamsInUrl(addressBookForm);
      }

      const paginatedList = await AddressApi.getList(addressBookForm);

      const tableData = TableData.createTableDataFrom(paginatedList, addressBookForm.page, addressBookForm.sizePerPage);

      dispatch(asyncActions.done({ params: {}, result: { tableData } }));
    }

    async function catchAction(exception: any) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
};

export default fetchAsync;

//
// Sorting and pagination
//

export function onSortChange(sortName: string, sortOrder: SortOrder) {
  return async (dispatch: Dispatch<any>, getState: Function) => {
    const viewModel: AddressesRequestViewModel = getState().form[addressBookFormName].values;
    viewModel.sortName = sortName;
    viewModel.sortOrder = sortOrder;
    dispatch(fetchAsync(viewModel, false, true));
  };
}

export function onPageChange(page: number, sizePerPage: number) {
  return async (dispatch: Dispatch<any>, getState: Function) => {
    const viewModel: AddressesRequestViewModel = getState().form[addressBookFormName].values;
    viewModel.page = page;
    viewModel.sizePerPage = sizePerPage;
    dispatch(fetchAsync(viewModel, false, true));
  };
}

export function onSizePerPageList(sizePerPage: number) {
  return async (dispatch: Dispatch<any>, getState: Function) => {
    const viewModel: AddressesRequestViewModel = getState().form[addressBookFormName].values;
    viewModel.sizePerPage = sizePerPage;
    dispatch(fetchAsync(viewModel, false, true));
  };
}
