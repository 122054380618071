import * as React from 'react';
import { Field, BaseFieldProps, WrappedFieldProps } from 'redux-form';
import LocaleUtilities from '../../helpers/LocaleUtilities';

class XXXField extends Field<BaseFieldProps> {}
class SpeysCloudCheckboxFieldComponent extends React.Component<WrappedFieldProps & BaseFieldProps> {
  render() {
    const { input } = this.props;
    const { value, onChange } = input;
    return <input type="checkbox" checked={value} onChange={() => onChange(!value)} />;
  }
}

export class SpeysCheckBoxComponent extends React.Component<{ name: string; label: string }> {
  render() {
    return (
      <div className="switch">
        <div className="demo-switch-title m-b-10 ">{this.props.label}</div>
        <label className="">
          {LocaleUtilities.i18n('No', 'base')}
          <XXXField name={this.props.name} component={SpeysCloudCheckboxFieldComponent} />
          <span className="lever switch-col-orange" />
          {LocaleUtilities.i18n('Yes', 'base')}
        </label>
      </div>
    );
  }
}
