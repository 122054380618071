import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Dispatch } from 'redux';
import { toastr } from 'react-redux-toastr';

import { ErrorWrapper } from '../../../../../../viewModels/base';
import CommonUtilities from '../../../../../../helpers/CommonUtilities';
import { FileApi } from '../../../../../../api/File.api';
import { UploadFileTypes } from '../../../../../../constants/base.constants';

// var fileDownload = require('js-file-download');

const actionCreator = actionCreatorFactory();
const type = 'DELIVERY/DOWNLOAD-FILE';
export const asyncActions = actionCreator.async<{}, {}, ErrorWrapper>(type);

export default function submit(externalId: number, fileId: number, view = false, fileName?: string) {
  return async (dispatch: Dispatch<AnyAction>, getState: Function) => {
    async function mainAction() {
      dispatch(asyncActions.started({}));
      await FileApi.downloadFile(externalId, fileId, view, UploadFileTypes.shipment, fileName);
      // fileDownload(resp, 'test.PNG');
      // console.log(resp);
      dispatch(asyncActions.done({ params: {}, result: {} }));
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
      toastr.error('Error', 'Error has occurred');
      CommonUtilities.scrollToTop();
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
