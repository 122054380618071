import * as React from 'react';
import LaddaButton from 'react-ladda';

export default class SpeysCloudLoaderButtonComponent extends React.Component<{
  loading: boolean;
  handleAction: () => void;
  className?: string;
}> {
  render() {
    return (
      <span>
        <LaddaButton
          className={this.props.className === undefined ? 'btn btn-success' : this.props.className}
          loading={this.props.loading}
          onClick={this.props.handleAction}
          data-color="#eee"
          data-style={'expand-right'}
          data-spinner-size={30}
          data-spinner-color="#ddd"
          data-spinner-lines={12}
        >
          {this.props.children}
        </LaddaButton>
        {/*<button
                className={this.props.className === undefined ? 'btn btn-success' : this.props.className}
                onClick={this.props.handleAction}
                disabled={this.props.loading}
            >
                {!this.props.loading
                    ? this.props.children
                    : <Loader active={true} inline="centered" inverted={true} />}
            </button>*/}
      </span>
    );
  }
}
