import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { asyncActions as createStartActions } from './New/actions/User.create.start.action';
import { asyncActions as createSubmitActions } from './New/actions/User.create.submit.action';
import { asyncActions as editStartActions } from './Edit/actions/User.edit.start.action';
import { asyncActions as editSubmitActions } from './Edit/actions/User.edit.submit.action';
import { createCancel } from './New/actions';
import { editCancel } from './Edit/actions';
import { ErrorWrapper } from '../../../../../viewModels/base';

export const REDUCER_KEY__USER = 'user';
export class UserReduxState {
  type?: string;
  errorWrapper?: ErrorWrapper;
  userId?: number;
  loading: boolean;
  user?: any;
  showTenantsBlock: boolean;
  showCompaniesBlock: boolean;
}

const defaultState: UserReduxState = {
  loading: false,
  showTenantsBlock: false,
  showCompaniesBlock: false,
};
export default (state: UserReduxState = defaultState, action: Action): UserReduxState => {
  // Create actions
  if (isType(action, createCancel)) {
    return { ...state, type: action.type, loading: false };
  }
  if (isType(action, createStartActions.started)) {
    return { ...state, type: action.type, loading: true };
  }
  if (isType(action, createStartActions.done)) {
    return { type: action.type, loading: false, ...defaultState };
  }
  if (isType(action, createStartActions.failed)) {
    return { ...state, type: action.type, loading: false };
  }
  if (isType(action, createSubmitActions.started)) {
    return { ...state, type: action.type, loading: true };
  }
  if (isType(action, createSubmitActions.done)) {
    return { ...state, type: action.type, loading: false };
  }
  if (isType(action, createSubmitActions.failed)) {
    return { ...state, type: action.type, errorWrapper: action.payload.error, loading: false };
  }

  // Edit actions
  if (isType(action, editCancel)) {
    return { ...state, type: action.type, loading: false, userId: undefined };
  }
  if (isType(action, editStartActions.started)) {
    return { ...state, type: action.type, loading: true };
  }
  if (isType(action, editStartActions.done)) {
    return { type: action.type, loading: false, userId: action.payload.result.addressId, ...defaultState };
  }
  if (isType(action, editStartActions.failed)) {
    return { ...state, type: action.type, loading: false, errorWrapper: action.payload.error };
  }
  if (isType(action, editSubmitActions.started)) {
    return { ...state, type: action.type, loading: true };
  }
  if (isType(action, editSubmitActions.done)) {
    return { ...state, type: action.type, userId: undefined, loading: false };
  }
  if (isType(action, editSubmitActions.failed)) {
    return { ...state, type: action.type, errorWrapper: action.payload.error, loading: false };
  }

  return state;
};
