export default class DeliveriesResponseViewModel {
  id: number;
  number: string;
  debitorCompany: string;
  company: string;
  pickup: string;
  loadingAddressCountry: string;
  shortLoadingAddress: string;
  deliveryAddressCountry: string;
  shortDeliveryAddress: string;
  destination: string;
  loadingDate?: string; // TODO: Date
  deliveryDate?: string; // TODO: Date
  status: string;
  hasPodFiles: boolean;
  driver: string;
}
