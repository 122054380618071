import * as React from 'react';
import { connect } from 'react-redux';
import { InjectedFormProps, reduxForm, formValueSelector } from 'redux-form';
import { withRouter } from 'react-router-dom';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { RouteComponentProps } from 'react-router';
import { SortOrder } from 'speys-react-bootstrap-table';
import TrucksImage from '../../../../../../assets/images/base/shippment.jpg';
import { DeliveryState } from '../Shipment.reducer';
import DetailviewTopNavComponent from '../../../../../../components/Template/Detailview.TopNav.component';
import { StickyContainer, Sticky } from 'react-sticky';
import ShipmentCreateOrUpdateRequestViewModel from '../../../../../../viewModels/delivery/ShipmentCreateOrUpdateRequestViewModel';

import SweetAlert from '../../../../../../components/SweetAlert/SweetAlert.component';
import Badge from 'reactstrap/lib/Badge';
import ValidationUtilities from '../../../../../../helpers/ValidationUtilities';
import ReusableComponentUtilities from '../../../../../../helpers/ReusableComponentUtilities';
import LocaleUtilities from '../../../../../../helpers/LocaleUtilities';
import { routePaths } from '../../../../../../constants/api.constants';
import { ShipmentDetailsRow } from '../../../../../../viewModels/delivery/ShipmentResponseViewModel';
import { NumberUtilities } from '../../../../../../helpers/NumberUtilities';
import SeedUtilities from '../../../../../../helpers/SeedUtilities';
import { seedOptions } from '../../../../../../seed';

/*material-ui*/
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

/*styles*/
import '../../../../../../assets/scss/plugins/wizard/steps.css';
import 'react-select/dist/react-select.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import 'react-datetime/css/react-datetime.css';
import Select, { Option } from 'react-select';
import { DeliveryStatus } from '../../../../../../viewModels/enums';
import ImageBlock from '../../../../../../components/ImageBlock';
import ShipmentDocumentsTabComponent from './Shipment.Documents.Tab.component';
import PageTitleComponent from '../../../../../../components/Template/PageTitle.component';
import ShipmentCommentsTabComponent from './Shipment.Comments.Tab.component';
import RouterUtilities, { URL_TAB } from '../../../../../../helpers/RouterUtilities';
import CommonUtilities from '../../../../../../helpers/CommonUtilities';

export interface DeliveryContainerStateProps extends DeliveryState {
  title: any;
  shipment?: any;
}

export interface DeliveryComponentDispatchProps {
  cancelItem: Function;
  startItem: Function;
  submitItem: Function;

  senderSearchStart: Function;
  senderSearchCancel: Function;
  senderSearchSubmit: Function;
  senderToggleVisibility: Function;

  senderAlternativeSearchStart: Function;
  senderAlternativeSearchCancel: Function;
  senderAlternativeSearchSubmit: Function;
  senderAlternativeToggleVisibility: Function;

  receiverSearchStart: Function;
  receiverSearchCancel: Function;
  receiverSearchSubmit: Function;
  receiverToggleVisibility: Function;

  receiverAlternativeSearchStart: Function;
  receiverAlternativeSearchCancel: Function;
  receiverAlternativeSearchSubmit: Function;
  receiverAlternativeToggleVisibility: Function;

  contactFetch: Function;
  updateDebtorInfo: Function;
  contactTableOnSortChange: (sortName: string, sortOrder: SortOrder) => any;
  contactTableOnPageChange: (page: number, sizePerPage: number) => any;
  contactTableOnSizePerPageList: (sizePerPage: number) => any;

  getFiles: Function;
  getPodFiles: Function;
  uploadFile: Function;
  downloadFile: Function;
  deleteFile: Function;
  toggleFileStatus: Function;
  changeStatus: Function;
  changeStatusInformation: Function;
  getComments: Function;
  addComment: Function;
  toggleCommentStatus: Function;
  deleteComment: Function;
  getShippingOrders: Function;
  createInvoice: Function;
}

export declare type AllDeliveryComponentProps = DeliveryContainerStateProps &
  DeliveryComponentDispatchProps &
  RouteComponentProps<any> &
  InjectedFormProps<any>;
export const deliveryFormName = 'deliveryForm';

export const ShipmentTabs = {
  Documents: 0,
  Comments: 1,
};

class ShipmentComponent extends React.Component<
  AllDeliveryComponentProps,
  {
    alert?: any;
    currentTab: number;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      alert: undefined,
      currentTab: ShipmentTabs.Documents,
    };
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.onClickCreateInvoice = this.onClickCreateInvoice.bind(this);
  }

  goToList = () => {
    this.props.history.push(routePaths.shipments.list);
  };
  handleSave = () => {
    this.props.submitItem(this.goToList);
  };

  UNSAFE_componentWillMount() {
    const urlParams = RouterUtilities.locationSearchStringParse(location);
    if (urlParams !== null && urlParams !== undefined && urlParams.tabId !== undefined) {
      this.setState({
        currentTab: parseInt(urlParams.tabId),
      });
    }
  }

  componentDidMount() {
    this.props.startItem(this.props.deliveryId);
  }

  handleTabChange = (tab: number) => {
    this.setState({
      currentTab: tab,
    });
    RouterUtilities.changeQueryParamsInUrl({
      [URL_TAB]: tab,
    });
  };

  handleStatusChange(newStatus: any) {
    this.setState({
      alert: (
        <SweetAlert
          title={LocaleUtilities.i18n('Are you sure', 'confirm.modal')}
          type="warning"
          onConfirm={() => this.props.changeStatus(this.props.deliveryId, newStatus)}
          onCancel={() => this.setState({ alert: undefined })}
        >
          <p>{LocaleUtilities.i18n('Are you sure you want to set a new status', 'shipment.details')}</p>
        </SweetAlert>
      ),
    });
  }

  onClickCreateInvoice() {
    if (!CommonUtilities.NotNullAndNotUndefined(this.props.shipment)) {
      return;
    }
    this.props.createInvoice([this.props.deliveryId]);
  }
  render() {
    const { title, deliveryId, deliveryStatus } = this.props;

    const errorWrapper = this.props.errorWrapper;
    const backToListRowComponent = ReusableComponentUtilities.getBackToListComponent(routePaths.shipments.list);
    const errorRowComponent = ReusableComponentUtilities.getErrorRowComponent(errorWrapper);
    if (ValidationUtilities.isCriticalError(errorWrapper)) {
      return (
        <div>
          {errorRowComponent}
          {backToListRowComponent}
        </div>
      );
    }
    return (
      <StickyContainer>
        {this.state.alert}
        <DetailviewTopNavComponent isFixed={false}>{backToListRowComponent}</DetailviewTopNavComponent>

        <Sticky topOffset={30} isActive={true} disableCompensation={true} onStickyStateChange={(e) => console.log(e)}>
          {(props) => <span></span>}
        </Sticky>
        <div className="container-fluid shipment-details">
          <Row className="pt-5 bg-light-part border pb-5">
            <Col md={9}></Col>
            <Col md={3}>
              {deliveryId !== undefined && deliveryId > 0 && (
                <Select
                  menuContainerStyle={{ zIndex: 999 }}
                  placeholder={'Change status'}
                  options={seedOptions}
                  value={DeliveryStatus[deliveryStatus]}
                  onChange={(selectedOption: Option<string>) => {
                    this.handleStatusChange(!selectedOption ? null : selectedOption.value);
                  }}
                />
              )}
            </Col>
          </Row>
          <PageTitleComponent title={LocaleUtilities.i18n(`sidebar-menu-item-Shipments`, 'sidebar.menu', true)} />
          <div className="row mb-50">
            <div className="col-md-10 offset-lg-1">
              <Row>
                <Col md={12}>
                  <h3 className="text-bold sub-title mt-5 mb-5">
                    {title}
                    {deliveryStatus > 0 && (
                      <Badge className={'font-14 ml-10'} color={SeedUtilities.getStatusColorByNumber(deliveryStatus)}>
                        {LocaleUtilities.i18n(
                          '' + SeedUtilities.getStatusStringByNumber(deliveryStatus),
                          'shipment.status',
                        )}
                      </Badge>
                    )}
                  </h3>
                </Col>
              </Row>
              <ImageBlock filename={TrucksImage} height={'25px'} />
              <Row>
                <Col md={12}>
                  <AppBar position="static" className={'speys-system-tabs'}>
                    <Tabs
                      value={this.state.currentTab}
                      variant="scrollable"
                      scrollButtons="off"
                      indicatorColor="primary"
                      className={'speys-tabs'}
                    >
                      <Tab
                        disabled={deliveryId === undefined || deliveryId === 0}
                        icon={<i className="icon-docs"></i>}
                        label={LocaleUtilities.i18n('shipment-tab-Documents', 'shipment.tab', true)}
                        onClick={(e) => this.handleTabChange(ShipmentTabs.Documents)}
                      />
                      <Tab
                        icon={<i className="icon-bubbles"></i>}
                        label={LocaleUtilities.i18n('shipment-tab-Comments', 'shipment.tab', true)}
                        disabled={deliveryId === undefined || deliveryId === 0}
                        onClick={(e) => this.handleTabChange(ShipmentTabs.Comments)}
                      />
                    </Tabs>
                  </AppBar>
                  {this.state.currentTab === ShipmentTabs.Documents && (
                    <ShipmentDocumentsTabComponent
                      {...this.props}
                      loadingLeg={this.props.loadingLeg}
                      shipment={this.props.shipment}
                    />
                  )}
                  {this.state.currentTab === ShipmentTabs.Comments && (
                    <ShipmentCommentsTabComponent
                      {...this.props}
                      loadingComments={this.props.loadingComments}
                      shipment={this.props.shipment}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </StickyContainer>
    );
  }
}

const DeliveryComponents = reduxForm<ShipmentCreateOrUpdateRequestViewModel>({ form: deliveryFormName })(
  ShipmentComponent,
);
// Decorate with connect to read form values
const selector = formValueSelector<ShipmentCreateOrUpdateRequestViewModel>(deliveryFormName);

const changeDimensions = function (data: Array<ShipmentDetailsRow>) {
  data.forEach(function (element: ShipmentDetailsRow) {
    if (element.typeId !== null && element.typeId !== 0 && element.typeId !== undefined) {
      /*FIN- pallet*/
      if (element.typeId.toString() === '2') {
        element.length = 120;
        element.width = 100;
      }
      /*EUR- pallet*/
      if (element.typeId.toString() === '1') {
        element.length = 120;
        element.width = 80;
      }
      /*463L*/
      if (element.typeId.toString() === '4') {
        element.length = 224;
        element.width = 275;
      }
      /*Trolley*/
      if (element.typeId.toString() === '5') {
        element.length = 80;
        element.width = 60;
      }
    }

    element.volume = 0;
    let quantity = 1;
    if (element.quantityId !== undefined && element.quantityId !== null) {
      quantity = NumberUtilities.tryFormatFloat(element.quantityId.toString());
    }
    /* calculate total weight */
    element.totalWeight = undefined;
    if (
      element.quantityId !== undefined &&
      element.weight !== undefined &&
      element.quantityId !== null &&
      element.weight !== null
    ) {
      element.totalWeight =
        NumberUtilities.tryFormatFloat(element.quantityId.toString()) *
        NumberUtilities.tryFormatFloat(element.weight.toString());
      element.totalWeight = NumberUtilities.tryFormatFloat(element.totalWeight.toFixed(3)); //hack
    }

    /*calculate volume*/
    if (
      element.width !== undefined &&
      element.length !== undefined &&
      element.height !== undefined &&
      element.width !== null &&
      element.length !== null &&
      element.height !== null
    ) {
      element.volume =
        quantity *
        NumberUtilities.tryFormatFloat(element.width.toString()) *
        NumberUtilities.tryFormatFloat(element.length.toString()) *
        NumberUtilities.tryFormatFloat(element.height.toString()) *
        0.000001;
      element.volume = NumberUtilities.tryFormatFloat(element.volume.toFixed(3)); //hack
    }

    const dangerousGoods = element.dangerousGoods;
    if (dangerousGoods !== undefined && dangerousGoods !== null && dangerousGoods === true) {
      if (element.dangerousGoodsInfo === undefined || element.dangerousGoodsInfo.length === 0) {
        element.dangerousGoodsInfo = [{}];
      }
    }
  });
};

export default connect<{}, {}, {}>(
  (state: any) => {
    const formName = 'deliveryForm';
    let shipment = {};
    if (state.form !== undefined && state.form[formName] !== undefined) {
      shipment = state.form[formName].values || {};
    }
    const shipmentDetailsRows = selector(state, 'shipmentDetailsRows');

    if (shipmentDetailsRows != null) {
      changeDimensions(shipmentDetailsRows);
    }
    return {
      shipmentDetailsRows,
      shipment,
    };
  },
  (dispatch: any) => ({}),
)(withRouter<any>(DeliveryComponents));
