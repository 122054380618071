import * as React from 'react';
import BaseFormFields from '../../../../../helpers/FormFieldUtilities';
import DeliveriesRequestViewModel from '../../../../../viewModels/delivery/deliveriesRequestViewModel';
import { MouseEventHandler } from 'react';
import { seedOptions } from '../../../../../seed';
import LocaleUtilities from '../../../../../helpers/LocaleUtilities';

class FormFields extends BaseFormFields<keyof DeliveriesRequestViewModel> {
  getCompany = () => this.defaultField('company');
  getReference = () => this.defaultField('reference');
  getDeliveryDateFrom = () => this.dateField2('deliveryDateFrom');
  getDeliveryDateTo = () => this.dateField2('deliveryDateTo');
  getStatus = () => this.comboboxStringField('status', seedOptions);
}

interface DeliveriesSearchFieldsComponentProps {
  handleSearch: MouseEventHandler<HTMLButtonElement>;
}

export default class ShipmentSearchFieldsComponent extends React.Component<DeliveriesSearchFieldsComponentProps> {
  render() {
    const { handleSearch } = this.props;
    const formFields = new FormFields();

    return (
      <div className="form-material p-t-20">
        <div className="row">
          <div className="col-md-3 col-xs-6">
            <div className="form-group">
              <label>{LocaleUtilities.i18n('shipment-filter-Reference', 'shipment.list')}</label>
              {formFields.getReference()}
            </div>
          </div>
          <div className="col-md-3 col-xs-6">
            <div>
              <label>{LocaleUtilities.i18n('shipment-filter-Status', 'shipment.list')}</label>
              {formFields.getStatus()}
            </div>
          </div>
          <div className="col-md-8 col-xs-12">
            <div className="form-group form-inline">
              {formFields.getDeliveryDateFrom()}
              <span className={'ml-5 mr-5'}> - </span>
              {formFields.getDeliveryDateTo()}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-align-right display-block">
            <button className="btn btn-rounded btn-info pull-right" onClick={handleSearch}>
              {LocaleUtilities.i18n('button-Search', 'base.button')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
