import React from 'react';

export interface FlagAndAddressComponetDispatchProps {
  flag: string;
  address: string;
}

export default class FlagAndAddressComponet extends React.Component<FlagAndAddressComponetDispatchProps, {}> {
  render() {
    const { flag, address } = this.props;
    //const img = require(`../../assets/flag-icons/{FLAG}.png.replace('{FLAG}',flag.toLowerCase()));
    try {
      return (
        <span>
          <img className={'mr-5'} src={require(`../../assets/flag-icons/${flag.toLowerCase()}.png`)} />
          {address}
        </span>
      );
    } catch (error) {
      return <span>no-flag</span>;
    }
  }
}
