/**
 * Reactify Sidebar
 */
import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import $ from 'jquery';
import { routePaths } from '../../constants/api.constants';

// redux actions
import { collapsedSidebarAction } from '../../app/actions/template';

// components
import UserBlock from './UserBlock';
import SidebarContent from './SidebarContent';
import LocaleUtilities from '../../helpers/LocaleUtilities';

class Sidebar extends Component {
  UNSAFE_componentWillMount() {
    this.updateDimensions();
  }

  shouldComponentUpdate(nextProps) {
    const { enableSidebarBackgroundImage, selectedSidebarImage, isDarkSidenav } = this.props;
    if (
      enableSidebarBackgroundImage !== nextProps.enableSidebarBackgroundImage ||
      selectedSidebarImage !== nextProps.selectedSidebarImage ||
      isDarkSidenav !== nextProps.isDarkSidenav
    ) {
      return true;
    } else {
      return false;
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { windowWidth } = this.state;
    const { collapsedSidebar } = this.props;
    if (nextProps.location !== this.props.location) {
      if (windowWidth <= 1199) {
        this.props.collapsedSidebarAction(false);
      }
    }
  }

  updateDimensions = () => {
    this.setState({ windowWidth: $(window).width(), windowHeight: $(window).height() });
  };

  render() {
    const { enableSidebarBackgroundImage, selectedSidebarImage, isDarkSidenav } = this.props;
    return (
      <Fragment>
        <div
          className={classNames('rct-sidebar', { 'background-none': !enableSidebarBackgroundImage })}
          style={{ backgroundImage: enableSidebarBackgroundImage ? `url(${selectedSidebarImage})` : 'none' }}
        >
          <div
            className={classNames('rct-sidebar-content', {
              'sidebar-overlay-dark': isDarkSidenav,
              'sidebar-overlay-light': !isDarkSidenav,
            })}
          >
            <div className="site-logo text-center">
              {/*<Link to="/" className="logo-mini">
								<img src={require('../../assets/img/appLogo.png')} className="mr-15" alt="site logo" width="35" height="35" />
							</Link>*/}
              <Link to="/" className="logo-normal">
                <img
                  src={require('../../assets/img/SpeysLogoTop.svg')}
                  className="img-fluid"
                  alt="site-logo"
                  width="102"
                  height="35"
                />
              </Link>
            </div>
            <div className="rct-sidebar-wrap">
              <Scrollbars
                className="rct-scroll"
                autoHide
                autoHideDuration={100}
                style={{ height: 'calc(100vh - 60px)' }}
              >
                <UserBlock />
                <div className="mb-25 text-center">
                  {/*<Link to={routePaths.shipments.new} className="btn btn-warning btn-sm">{LocaleUtilities.i18n('shipment-list-Add new shipment', 'shipment.list')}</Link>*/}
                </div>
                <SidebarContent />
              </Scrollbars>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

// map state to props
const mapStateToProps = ({ settings }) => {
  const { enableSidebarBackgroundImage, selectedSidebarImage, collapsedSidebar, isDarkSidenav } = settings;
  return { enableSidebarBackgroundImage, selectedSidebarImage, collapsedSidebar, isDarkSidenav };
};

export default withRouter(
  connect(mapStateToProps, {
    collapsedSidebarAction,
  })(Sidebar),
);
