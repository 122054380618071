import * as React from 'react';
import { Col, Row } from 'reactstrap';

interface ContentProps {
  styleName?: string;
  children: any;
  noContainerStyle?: boolean;
}

class TemtContentHeader extends React.Component<any> {
  render() {
    return (
      <Row className="content-header">
        <Col sm={12} className="content-header-left mb-2">
          <h3 className="content-header-title mb-0 ml-2">{this.props.children}</h3>
        </Col>
      </Row>
    );
  }
}
class SpeysContentBody extends React.Component<any> {
  render() {
    return (
      <Row>
        <Col sm={12}>{this.props.children}</Col>
      </Row>
    );
  }
}

class SpeysContent extends React.Component<ContentProps> {
  public static Header = TemtContentHeader;
  public static Body = SpeysContentBody;

  render() {
    const { children, styleName, noContainerStyle } = this.props;

    return (
      <div className={noContainerStyle ? 'app-content content' : 'app-content content'}>
        <div className="content-wrapper">
          <span className={`${styleName}`}>{children}</span>
        </div>
      </div>
    );
  }
}

export default SpeysContent;
