import * as React from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { RouteComponentProps, withRouter } from 'react-router';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { CompanyState } from '../Company.reducer';
import { FormFieldUtilities } from '../../../../../../helpers/FormFieldUtilities';
import ValidationUtilities from '../../../../../../helpers/ValidationUtilities';
import ReusableComponentUtilities from '../../../../../../helpers/ReusableComponentUtilities';
import { routePaths } from '../../../../../../constants/api.constants';
import DetailviewTopNavComponent from '../../../../../../components/Template/Detailview.TopNav.component';
import LocaleUtilities from '../../../../../../helpers/LocaleUtilities';
import TrucksImage from '../../../../../../assets/img/profile-banner.jpg';

import SpeysContent from '../../../../../../components/SpeysContent';
import ImageBlock from '../../../../../../components/ImageBlock';
import PageTitleComponent from '../../../../../../components/Template/PageTitle.component';

import CompanyDetailsTabComponent from './Company.Details.Tab.component';
import CompanyUsersTabComponent from './Company.Users.Tab.component';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export interface CompanyComponentStateProps extends CompanyState {
  title: any;
}

export interface CompanyComponentDispatchProps {
  cancelItem: Function;
  startItem: Function;
  submitItem: Function;
  usersOnSortChange: Function;
  usersOnPageChange: Function;
  usersOnSizePerPageList: Function;
}

export const companyFormName = 'companyForm';
export type CompanyComponentProps = CompanyComponentStateProps &
  CompanyComponentDispatchProps &
  RouteComponentProps<any> &
  InjectedFormProps<any>;

const CompanyTabs = {
  Details: 0,
  Users: 1,
};

class CompanyComponent extends React.Component<
  CompanyComponentProps,
  {
    currentTab: number;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      currentTab: CompanyTabs.Details,
    };
  }

  goToList = () => {
    // this.props.history.push(routePaths.companies.list);
  };

  handleSave = () => {
    this.props.submitItem(this.goToList);
  };

  async componentDidMount() {
    // this.props.startItem(this.props.companyId);
  }

  handleTabChange = (tab: number) => {
    this.setState({
      currentTab: tab,
    });
  };

  render() {
    const { title } = this.props;

    const errorWrapper = this.props.errorWrapper;
    const { formErrors, formErrorPropertyNames } = ValidationUtilities.getFormErrors(errorWrapper);
    const backToListRowComponent = ReusableComponentUtilities.getBackToListComponent(routePaths.companies.myCompany);
    const errorRowComponent = ReusableComponentUtilities.getErrorRowComponent(
      errorWrapper,
      'user-detail-error-',
      'user.detail',
    );
    const formHelper = new FormFieldUtilities(formErrors, formErrorPropertyNames);

    if (ValidationUtilities.isCriticalError(errorWrapper)) {
      return (
        <div>
          {errorRowComponent}
          {backToListRowComponent}
        </div>
      );
    }

    return (
      <SpeysContent styleName={'ct-table'}>
        <SpeysContent.Body>
          <div className="container-fluid">
            <DetailviewTopNavComponent>{backToListRowComponent}</DetailviewTopNavComponent>
            <PageTitleComponent title={LocaleUtilities.i18n(`sidebar-menu-item-Companies`, 'sidebar.menu', true)} />
            <div className="col-md-10 offset-lg-1">
              <div className="animated fadeIn">
                <Row>
                  <Col md={12}>
                    <h3 className="text-bold sub-title">{title}</h3>
                  </Col>
                </Row>
                <ImageBlock filename={TrucksImage} height={'45px'} />
                {errorRowComponent}
                <Row>
                  <Col md={12}>
                    <AppBar position="static" className={'speys-system-tabs'}>
                      <Tabs
                        value={this.state.currentTab}
                        variant="scrollable"
                        scrollButtons="off"
                        indicatorColor="primary"
                        className={'speys-tabs'}
                      >
                        <Tab
                          icon={<i className="ti-info"></i>}
                          label={'Company details'}
                          onClick={(e) => this.handleTabChange(CompanyTabs.Details)}
                        />
                      </Tabs>
                    </AppBar>
                    {this.state.currentTab === CompanyTabs.Details && (
                      <CompanyDetailsTabComponent
                        {...this.props}
                        formHelper={formHelper}
                        company={this.props.company}
                      />
                    )}

                    {this.state.currentTab === CompanyTabs.Users && (
                      <CompanyUsersTabComponent
                        {...this.props}
                        onPageChange={this.props.usersOnPageChange}
                        onSizePerPageList={this.props.usersOnSizePerPageList}
                        onSortChange={this.props.usersOnSortChange}
                        company={undefined}
                      />
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </SpeysContent.Body>
      </SpeysContent>
    );
  }
}

export default reduxForm({ form: companyFormName })(withRouter(CompanyComponent));
