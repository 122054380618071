import * as React from 'react';
import { CompanyComponentProps } from './Company.component';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { BootstrapTable, Options, SortOrder, TableHeaderColumn } from 'speys-react-bootstrap-table';
import TableFormattingUtilities from '../../../../../../helpers/TableFormattingUtilities';
import UsersResponseViewModel from '../../../../../../viewModels/driver/DriversResponseViewModel';
import CommonUtilities from '../../../../../../helpers/CommonUtilities';

export declare type CompanyUsersTabComponentProps = CompanyComponentProps & {
  company?: any;
  onSortChange: Function;
  onPageChange: Function;
  onSizePerPageList: Function;
};

function safe(key: keyof UsersResponseViewModel) {
  return CommonUtilities.typeSafeName<UsersResponseViewModel>(key);
}

export default class CompanyUsersTabComponent extends React.Component<CompanyUsersTabComponentProps, {}> {
  actionFormat = (cell: any, row: UsersResponseViewModel): JSX.Element => {
    return <div className={'text-right'}></div>;
  };
  render() {
    const { onSortChange, onPageChange, onSizePerPageList } = this.props;
    const { data, pagination } = this.props.usersTableData;
    const { page, total, sizePerPage } = pagination;

    const tableOptions: Options = {
      page: page,
      sizePerPage: sizePerPage,
      sizePerPageList: [5, 10, 25],
      onSortChange: (sortName: any, sortOrder: SortOrder) => onSortChange(sortName, sortOrder),
      onPageChange: (pageParam: number, sizePerPageParam: number) => onPageChange(pageParam, sizePerPageParam),
      onSizePerPageList: (sizePerPageParam: number) => onSizePerPageList(sizePerPageParam),
    };
    return (
      <div className="form p-t-20 smaller-label-font">
        <Row>
          <Col md={12}>
            <div className="card">
              <div className="card-body">
                <h2 className="heading">
                  <span>Company users</span>
                </h2>
                <BootstrapTable
                  data={data}
                  version="4"
                  containerClass="speys-data-table table-hover"
                  bordered={false}
                  hover={true}
                  pagination={true}
                  remote={true}
                  fetchInfo={{ dataTotalSize: total }}
                  options={tableOptions}
                >
                  <TableHeaderColumn isKey={true} hidden={true} dataField={safe('id')}>
                    Id
                  </TableHeaderColumn>
                  <TableHeaderColumn />
                  <TableHeaderColumn
                    dataField={safe('firstName')}
                    dataSort={true}
                    dataFormat={TableFormattingUtilities.boldTextFormat}
                  >
                    Name
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField={safe('lastName')}
                    dataSort={true}
                    dataFormat={TableFormattingUtilities.boldTextFormat}
                  >
                    Last name
                  </TableHeaderColumn>
                  <TableHeaderColumn dataFormat={this.actionFormat} width="10%" />
                </BootstrapTable>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
