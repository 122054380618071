import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { asyncActions as editStartActions } from './Edit/actions/Company.edit.start.action';
import { asyncActions as editSubmitActions } from './Edit/actions/Company.edit.submit.action';
import { editCancel } from './Edit/actions';
import { ErrorWrapper, TableData } from '../../../../../viewModels/base';
import UsersResponseViewModel from '../../../../../viewModels/driver/DriversResponseViewModel';
import CompanyResponseViewModel from '../../../../../viewModels/company/CompanyResponseViewModel';

export const REDUCER_KEY__COMPANY = 'company';

export class CompanyState {
  type?: string;
  errorWrapper?: ErrorWrapper;
  companyId?: number;
  loading: boolean;
  company?: CompanyResponseViewModel;
  usersTableData: TableData<UsersResponseViewModel>;
  usersPagination: { total: number; current: number; pageSize: number };
}

const defaultState: CompanyState = {
  loading: false,
  usersPagination: { total: 0, current: 0, pageSize: 25 },
  usersTableData: TableData.getDefault<UsersResponseViewModel>(),
};
export default (state: CompanyState = defaultState, action: Action): CompanyState => {
  // Edit actions
  if (isType(action, editCancel)) {
    return { ...state, type: action.type, loading: false, companyId: undefined };
  }
  if (isType(action, editStartActions.started)) {
    return { ...state, type: action.type, loading: true, company: undefined, companyId: 0 };
  }
  if (isType(action, editStartActions.done)) {
    return {
      type: action.type,
      loading: false,
      companyId: action.payload.result.addressId,
      company: action.payload.result.company,
      ...defaultState,
    };
  }
  if (isType(action, editStartActions.failed)) {
    return { ...state, type: action.type, loading: false, errorWrapper: action.payload.error };
  }
  if (isType(action, editSubmitActions.started)) {
    return { ...state, type: action.type, loading: true };
  }
  if (isType(action, editSubmitActions.done)) {
    return { ...state, type: action.type, companyId: undefined, loading: false };
  }
  if (isType(action, editSubmitActions.failed)) {
    return { ...state, type: action.type, errorWrapper: action.payload.error, loading: false };
  }

  return state;
};
