import actionCreatorFactory from 'typescript-fsa';
import { Dispatch } from 'redux';
import { toastr } from 'react-redux-toastr';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { ErrorWrapper } from '../../viewModels/base';
import CommonUtils from '../../helpers/CommonUtilities';
import AuthUtils, { NullableLocalStorageUserModel } from '../../helpers/AuthUtils/AuthUtils';
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';
import { store } from 'index';
import LocaleUtilities from '../../helpers/LocaleUtilities';
import TranslationApi from '../../api/Translations.api';

import shipmentStatusOptions from '../../seed/StatusOptions';
import typeOptions from '../../seed/TypeOptions';

const actionCreator = actionCreatorFactory();
export const asyncActions = actionCreator.async<{}, { user: NullableLocalStorageUserModel }, ErrorWrapper>('APP/START');

/**
 * Configurations when starting the application.
 */
function configureApiInterceptors(): void {
  const requestCallbacks = {
    onFulfilled: function onFulfilled(config: AxiosRequestConfig): AxiosRequestConfig {
      config.validateStatus = (status: number): boolean => {
        return status < 400 || status === 422;
      };
      return config;
    },
    onRejected: function onRejected(error: AxiosError): Promise<AxiosError> {
      return Promise.reject(error);
    },
  };

  const responseCallbacks = {
    onFulfilled: function onFulfilled(response: AxiosResponse): AxiosResponse {
      return response;
    },
    onRejected: function onRejected(error: AxiosError): Promise<AxiosError> {
      return Promise.reject(error);
    },
  };

  axios.interceptors.request.use(requestCallbacks.onFulfilled, requestCallbacks.onRejected);
  axios.interceptors.response.use(responseCallbacks.onFulfilled, responseCallbacks.onRejected);
}

export default function submit() {
  return async (dispatch: Dispatch<any>): Promise<void> => {
    async function mainAction(): Promise<void> {
      dispatch(asyncActions.started({}));

      configureApiInterceptors();
      const user = new AuthUtils().getCurrentUser();

      try {
        if (location.href.indexOf('sessions/error') > -1) {
        } else {
          const translations = await TranslationApi.getAll();
          syncTranslationWithStore(store);
          dispatch(loadTranslations(translations));
          const currentLanguage = LocaleUtilities.getCurrentLanguage();
          // console.log(currentLanguage)
          dispatch(setLocale(currentLanguage));

          LocaleUtilities.translateSelectCompnentLabels(shipmentStatusOptions);
          LocaleUtilities.translateSelectCompnentLabels(typeOptions);
        }
      } catch {
        console.error('Failed to load translations. Swallowing the exception and continuing...');
      }

      dispatch(asyncActions.done({ params: {}, result: { user } }));
    }

    async function catchAction(exception: ErrorWrapper): Promise<void> {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
      toastr.error('Error', 'Error has occurred');
      CommonUtils.scrollToTop();
    }

    await CommonUtils.tryCatchWrapper(mainAction, catchAction);
  };
}
