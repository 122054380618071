import actionCreatorFactory from 'typescript-fsa';
import { Dispatch } from 'redux';
import { initialize } from 'redux-form';
import { deliveryFormName } from '../../components/Shipment.component';

import { ErrorWrapper } from '../../../../../../../viewModels/base';
import CommonUtilities from '../../../../../../../helpers/CommonUtilities';
import DeliveryResponseViewModel, {
  ShipmentDetailsRow,
} from '../../../../../../../viewModels/delivery/ShipmentResponseViewModel';
import { ShipmentType } from '../../../../../../../viewModels/enums';

const actionCreator = actionCreatorFactory();
export const asyncActions = actionCreator.async<{}, { deliveryId: number }, ErrorWrapper>('DELIVERY/CREATE/START');

export default function start(deliveryId: number) {
  return async (dispatch: Dispatch<any>) => {
    async function mainAction() {
      dispatch(asyncActions.started({}));
      const delivery = new DeliveryResponseViewModel();
      /*f (!isAdmin() && (deliveryId === undefined || deliveryId === 0)) {
                let unitGroupInfo = await TenantApi.getUserTenantInformation();
                if (unitGroupInfo !== undefined && unitGroupInfo !== null) {
                    delivery.sender = new ShipmentContact();
                    delivery.sender.companyName = unitGroupInfo.name;
                    delivery.sender.postCode = unitGroupInfo.postalCode;
                    delivery.sender.city = unitGroupInfo.city;
                    delivery.sender.country = unitGroupInfo.country;
                    delivery.sender.addressLine1 = unitGroupInfo.addressLine1;
                    delivery.sender.addressLine2 = unitGroupInfo.addressLine2;
                }
            }*/
      delivery.type = ShipmentType.Sender.toString();
      delivery.shipmentDetailsRows = [];
      const row = new ShipmentDetailsRow();
      row.stackable = true;
      delivery.shipmentDetailsRows.push(row);
      dispatch(initialize(deliveryFormName, delivery));
      dispatch(asyncActions.done({ params: {}, result: { deliveryId } }));
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
