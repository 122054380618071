import * as React from 'react';
import { Link } from 'react-router-dom';
import { BootstrapTable, Options, SortOrder, TableHeaderColumn } from 'speys-react-bootstrap-table';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Button from 'reactstrap/lib/Button';
import { Row, Col } from 'reactstrap';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import * as Rx from 'rxjs/Rx';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import { AddressBookState } from './AddressBook.reducer';
import AddressesResponseViewModel from '../../../../../viewModels/addressBook/AddressesResponseViewModel1';
import TableFormattingUtilities from '../../../../../helpers/TableFormattingUtilities';
import { fetchAsyncType } from './actions/AddressBook.fetch.action';
import { AddressesRequestViewModel } from '../../../../../viewModels/addressBook/index';
import LocaleUtilities from '../../../../../helpers/LocaleUtilities';
import { routePaths } from '../../../../../constants/api.constants';
import CommonUtilities from '../../../../../helpers/CommonUtilities';
import SpeysContent from '../../../../../components/SpeysContent';
import DetailviewTopNavComponent from '../../../../../components/Template/Detailview.TopNav.component';
import PageTitleComponent from '../../../../../components/Template/PageTitle.component';
import IconButton from '@material-ui/core/IconButton';
import { RouteComponentProps, withRouter } from 'react-router';
import { ReactElement } from 'react';
import FlagAndAddressComponet from '../../../../../components/Address/FlagAndAddress.componet';
import SpeysCloudLoadingComponent from '../../../../../components/SpeysCloud.Loading.component';

export type AddressBookComponentStateProps = AddressBookState;

export interface AddressBookComponentDispatchProps {
  fetchAsync: fetchAsyncType;
  deleteCancel: Function;
  deleteStart: Function;
  deleteSubmit: Function;
  onSortChange: Function;
  onPageChange: Function;
  onSizePerPageList: Function;
}

function safeReq(key: keyof AddressesRequestViewModel) {
  return CommonUtilities.typeSafeName<AddressesRequestViewModel>(key);
}

function safeResp(key: keyof AddressesResponseViewModel) {
  return CommonUtilities.typeSafeName<AddressesResponseViewModel>(key);
}

type AddressBookComponentProps = AddressBookComponentStateProps &
  AddressBookComponentDispatchProps &
  RouteComponentProps<any> &
  InjectedFormProps<any>;

export const addressBookFormName = 'addressBookForm';

class AddressBookComponent extends React.Component<AddressBookComponentProps> {
  subscription: Subscription;
  onSearch$: Subject<{}>;

  constructor(props: any) {
    super(props);
    this.onSearch$ = new Rx.Subject();
  }

  componentDidMount() {
    const request = new AddressesRequestViewModel();
    request.parse(location);
    this.props.fetchAsync(request, true, false);

    this.subscription = this.onSearch$
      .debounceTime(300)
      .subscribe((debounced) => this.props.fetchAsync(null, false, true));
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  handleSearch = (e: any) => {
    this.onSearch$.next();
  };
  handleDeleteStart = (id: number) => {
    this.props.deleteStart(id);
  };
  handleDeleteCancel = () => {
    this.props.deleteCancel();
  };
  handleDeleteSubmit = () => {
    this.props.deleteSubmit();
  };
  handleEditClick = (id: number) => {
    this.props.history.push(routePaths.addressBook.editId(id));
  };
  actionFormat = (cell: any, row: any): JSX.Element => {
    return (
      <div className={'text-right'}>
        <IconButton className={'table-action-button'} color="primary" onClick={(e) => this.handleEditClick(row.id)}>
          <i className={' ti-pencil'} />
        </IconButton>
      </div>
    );
  };

  private cityCountryFormat = (cell: any, row: any, formatExtraData: any, rowIndex: number): ReactElement<any> => {
    return <FlagAndAddressComponet flag={row.country} address={row.city} />;
  };

  private editLinkFormat = (cell: any, row: any): ReactElement<any> => {
    return TableFormattingUtilities.boldTextFormatWithLink(
      `${cell}`,
      undefined,
      routePaths.addressBook.editId(row.id),
      false,
    );
  };

  render() {
    const { pendingDeleteId, confirmLoading, onSortChange, onPageChange, onSizePerPageList, loading } = this.props;
    const { data, pagination } = this.props.tableData;
    const { page, total, sizePerPage } = pagination;

    const tableOptions: Options = {
      page: page,
      sizePerPage: sizePerPage,
      sizePerPageList: [5, 10, 25],
      onSortChange: (sortName: any, sortOrder: SortOrder) => onSortChange(sortName, sortOrder),
      onPageChange: (pageParam: number, sizePerPageParam: number) => onPageChange(pageParam, sizePerPageParam),
      onSizePerPageList: (sizePerPageParam: number) => onSizePerPageList(sizePerPageParam),
    };
    const searchTranslation = LocaleUtilities.i18n('form-input-placeholder-Search', 'form.input', true);
    return (
      <div>
        <Modal isOpen={pendingDeleteId > 0} toggle={this.handleDeleteCancel}>
          <ModalHeader toggle={this.handleDeleteCancel}>
            {LocaleUtilities.i18n('AddressBook-Modal-Deleting')}
          </ModalHeader>
          <ModalBody>{LocaleUtilities.i18n('AddressBook-Modal-DoYouWantToDeleteThis')}</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.handleDeleteSubmit} disabled={confirmLoading}>
              {LocaleUtilities.i18n('modal-button-Continue', 'confirm.modal')}
            </Button>{' '}
            <Button color="secondary" onClick={this.handleDeleteCancel} disabled={confirmLoading}>
              {LocaleUtilities.i18n('modal-button-Cancel', 'confirm.modal')}
            </Button>
          </ModalFooter>
        </Modal>
        <SpeysContent styleName={'ct-table'}>
          <SpeysContent.Body>
            <SpeysCloudLoadingComponent isLoading={loading} error={undefined} />
            <div className="container-fluid">
              <DetailviewTopNavComponent>
                <div className="text-right">
                  <Link to={routePaths.addressBook.new} className="btn btn-speys btn-sm">
                    + {LocaleUtilities.i18n('addressBook-list-Add new address', 'addressBook')}
                  </Link>
                </div>
              </DetailviewTopNavComponent>
              <PageTitleComponent
                title={LocaleUtilities.i18n(`sidebar-menu-item-Address book`, 'sidebar.menu', true)}
              />
              <Row className={'mt-15'}>
                <Col md={3}>
                  <form className="form p-t-20">
                    <div className="form-group">
                      <div className="input-group">
                        <div className="input-group-prepend bg-warning">
                          <span className="input-group-text bg-warning">
                            <i className="ti-search text-white" />
                          </span>
                        </div>
                        <Field
                          name={safeReq('keyword')}
                          className="form-control"
                          placeholder={searchTranslation}
                          component="input"
                          onChange={this.handleSearch}
                        />
                      </div>
                    </div>
                  </form>
                </Col>
              </Row>
              <div className=" animated fadeIn speys-data-table-container">
                <Row className="mt-15">
                  <Col md={12}>
                    <div className="card">
                      <Segment className="card-body p-0 card-with-data-table">
                        <Dimmer active={this.props.loading}>
                          <Loader />
                        </Dimmer>
                        <h4 className="card-title">{LocaleUtilities.i18n('Addresses list')}</h4>
                        <BootstrapTable
                          data={data}
                          version="4"
                          containerClass="speys-data-table table-hover"
                          bordered={false}
                          pagination={true}
                          remote={true}
                          fetchInfo={{ dataTotalSize: total }}
                          options={tableOptions}
                        >
                          <TableHeaderColumn isKey={true} hidden={true} dataField={safeResp('id')}>
                            Id
                          </TableHeaderColumn>
                          <TableHeaderColumn></TableHeaderColumn>
                          <TableHeaderColumn
                            dataFormat={this.editLinkFormat}
                            dataField={safeResp('company')}
                            dataSort={true}
                          >
                            {LocaleUtilities.i18n('addressbook-Company')}
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField={safeResp('cityCountry')}
                            dataSort={true}
                            dataFormat={this.cityCountryFormat}
                          >
                            {LocaleUtilities.i18n('addressBook-Table-City Country', 'addressBook')}
                          </TableHeaderColumn>
                          <TableHeaderColumn dataField={safeResp('street')} dataSort={true}>
                            {LocaleUtilities.i18n('addressBook-Table-Address', 'addressBook')}
                          </TableHeaderColumn>
                          <TableHeaderColumn dataField={safeResp('contact')} dataSort={true}>
                            {LocaleUtilities.i18n('addressBook-Table-Contact', 'addressBook')}
                          </TableHeaderColumn>
                          <TableHeaderColumn dataField={safeResp('contactPhone')} dataSort={true}>
                            {LocaleUtilities.i18n('addressbook-Phone')}
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataFormat={TableFormattingUtilities.dateTimeFormat}
                            dataField={safeResp('creationDate')}
                            dataSort={true}
                          >
                            {LocaleUtilities.i18n('addressBook-Table-Date added', 'addressBook')}
                          </TableHeaderColumn>
                          <TableHeaderColumn dataFormat={this.actionFormat} columnClassName="text-right" width="10%" />
                        </BootstrapTable>
                      </Segment>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </SpeysContent.Body>
        </SpeysContent>
      </div>
    );
  }
}

export default reduxForm({ form: addressBookFormName })(withRouter(AddressBookComponent));
