import { applyMiddleware, compose, createStore, Reducer, Store, CombinedState } from 'redux';
import { createBrowserHistory } from 'history';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import createReducer from '../reducers';
import LocaleUtilities from '../helpers/LocaleUtilities';
import moment from 'moment';
import { I18n } from 'react-i18nify';
const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);

const middlewares = [routeMiddleware, thunkMiddleware];

const __DEV__ = true; // TODO: get development mode from environments
const asyncReducers: any = { b: () => 2 };

/*
 * Localication configuration
 *
 */

if (LocaleUtilities.getCurrentLanguage() == 'Estonian') {
  moment.locale('et');
} else if (LocaleUtilities.getCurrentLanguage() == 'English') {
  moment.locale('en-gb');
} else {
  moment.locale('fi');
}

// How to display key if translation is missing. In this example, missing translation is surrounded with [].
const myHandleMissingTranslation = (key: any) => !key || `[[${key.split(/\.|-/).slice(-1)[0]}]]`;
I18n.setHandleMissingTranslation(myHandleMissingTranslation);

export default function configureStore(initialState: any = {}) {
  // TODO: use typedef

  // ======================================================
  // Store Enhancers
  // ======================================================
  const windowIfDefined = typeof window === 'undefined' ? null : (window as any);
  const enhancers: any[] = [];
  if (__DEV__) {
    const devToolsExtension: any = windowIfDefined && windowIfDefined.devToolsExtension;
    if (typeof devToolsExtension === 'function') {
      const ext: any = devToolsExtension({ shouldHotReload: false });
      enhancers.push(ext); // correct fix to avoid toastr endless popup
    }
  }

  // ======================================================
  // Store Instantiation
  // ======================================================
  const store = createStore<any, any, any, any>(
    createReducer(history, asyncReducers),
    initialState,
    compose(applyMiddleware(...middlewares), ...enhancers),
  );

  return store;
}

export function injectAsyncReducer(store: Store<CombinedState<any>, any>, name: string, asyncReducer: Reducer<any>) {
  // TODO: Fix anys
  asyncReducers[name] = asyncReducer;
  store.replaceReducer(createReducer(history, asyncReducers));
}

export { history };
