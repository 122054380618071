import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Dispatch } from 'redux';
import { SortOrder } from 'speys-react-bootstrap-table';
import { initialize } from 'redux-form';

import { ErrorWrapper, TableData } from '../../../../../../viewModels/base';
import { RequestResponseViewModel } from '../../../../../../viewModels/request';
import { RequestApi } from '../../../../../../api/Request.api';
import RequestRequestViewModel from '../../../../../../viewModels/request/RequestRequestViewModel';
import { requestFormName } from '../Request.component';
import RouterUtilities from '../../../../../../helpers/RouterUtilities';
import CommonUtilities from '../../../../../../helpers/CommonUtilities';

const actionCreator = actionCreatorFactory();
export const asyncActions = actionCreator.async<{}, { tableData: TableData<RequestResponseViewModel> }, ErrorWrapper>(
  'REQUEST/FETCH',
);

export declare type fetchAsyncType = (
  params: RequestRequestViewModel | null,
  reinitializeForm: boolean,
  changeQueryParams: boolean,
) => (dispatch: Dispatch<AnyAction>, getState: Function) => Promise<void>;

const fetchAsync = (params: RequestRequestViewModel | null, reinitializeForm: boolean, changeQueryParams: boolean) => {
  return async (dispatch: Dispatch<AnyAction>, getState: Function) => {
    const requestForm: RequestRequestViewModel = params || getState().form[requestFormName].values;

    async function mainAction() {
      dispatch(asyncActions.started({ loading: true }));

      if (reinitializeForm) {
        dispatch(initialize(requestFormName, requestForm));
      }

      if (changeQueryParams) {
        RouterUtilities.changeQueryParamsInUrl(requestForm);
      }

      const paginatedList = await RequestApi.getList(requestForm);

      const tableData = TableData.createTableDataFrom(paginatedList, requestForm.page, requestForm.sizePerPage);

      dispatch(asyncActions.done({ params: {}, result: { tableData } }));
    }

    async function catchAction(exception: any) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
};

export default fetchAsync;

//
// Sorting and pagination
//

export function onSortChange(sortName: string, sortOrder: SortOrder) {
  return async (dispatch: Dispatch<any>, getState: Function) => {
    const viewModel: RequestRequestViewModel = getState().form[requestFormName].values;
    viewModel.sortName = sortName;
    viewModel.sortOrder = sortOrder;
    dispatch(fetchAsync(viewModel, false, true));
  };
}

export function onPageChange(page: number, sizePerPage: number) {
  return async (dispatch: Dispatch<any>, getState: Function) => {
    const viewModel: RequestRequestViewModel = getState().form[requestFormName].values;
    viewModel.page = page;
    viewModel.sizePerPage = sizePerPage;
    dispatch(fetchAsync(viewModel, false, true));
  };
}

export function onSizePerPageList(sizePerPage: number) {
  return async (dispatch: Dispatch<any>, getState: Function) => {
    const viewModel: RequestRequestViewModel = getState().form[requestFormName].values;
    viewModel.sizePerPage = sizePerPage;
    dispatch(fetchAsync(viewModel, false, true));
  };
}
