import ApiUtilities from '../helpers/ApiUtils';
import { BACKEND_HOST_AUTH } from '../constants/api.constants';
import { PaginatedListResult } from '../viewModels/base';
import {
  RequestResponseViewModel,
  RequestRequestViewModel,
  RequestCreateOrUpdateRequestViewModel,
} from '../viewModels/request';

function getRoot() {
  return `${BACKEND_HOST_AUTH()}api/partner-portal/request`;
}

export class RequestApi {
  public static getList(params: RequestRequestViewModel): Promise<PaginatedListResult<RequestResponseViewModel>> {
    return ApiUtilities.handleGet<PaginatedListResult<RequestResponseViewModel>>(getRoot(), { ...params });
  }

  public static getSingle(id: number): Promise<RequestCreateOrUpdateRequestViewModel | null> {
    return ApiUtilities.handleGet<RequestCreateOrUpdateRequestViewModel>(`${getRoot()}/${id}`);
  }

  public static updatePrice(id: number, price: string) {
    return ApiUtilities.handlePost<boolean>(`${getRoot()}/update-price/${id}`, { price: price });
  }

  public static changeStatus(id: number, statusId: number) {
    return ApiUtilities.handlePost<boolean>(`${getRoot()}/update-status/${id}`, { status: statusId });
  }
}
