import * as React from 'react';
import * as $ from 'jquery';
import { isIOS, isMobile } from 'react-device-detect';
import ReduxToastr from 'react-redux-toastr';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { AuthUtils } from '../helpers/AuthUtils';
import { AppScope } from '../constants/base.constants';
import AdminActions from '../app/routes/Admin';

class MainAppComponent extends React.Component<{
  isLoggedIn?: boolean;
}> {
  componentDidMount(): void {
    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      $('#body').addClass('ios-mobile-view-height');
    } else if ($('#body').hasClass('ios-mobile-view-height')) {
      $('#body').removeClass('ios-mobile-view-height');
    }
  }

  render(): JSX.Element {
    const scope: AppScope = AuthUtils.getAppScope();
    return (
      <div>
        {scope === AppScope.customer ? <AdminActions /> : null}
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates={true}
          position="top-center"
          transitionIn="bounceInDown"
          transitionOut="bounceOutUp"
          progressBar={true}
        />
      </div>
    );
  }
}

export default MainAppComponent;
