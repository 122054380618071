import React from 'react';
import { Route } from 'react-router';
import { Redirect } from 'react-router-dom';
import { UserModelRoles } from '../helpers/AuthUtils';
import AuthUtilities from '../helpers/AuthUtils/AuthUtils';

const PrivateRoute = (
  // TODO: fix any
  { component: TheComponent, currentUser = null, allowedRoles = [UserModelRoles.partnerAdmin], ...rest }: any,
) => {
  const utils = new AuthUtilities();
  return (
    <Route
      {...rest}
      render={(props) =>
        !utils.isCurrentUserLoggedIn(currentUser) ? (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        ) : !utils.hasValidRole(allowedRoles, currentUser) ? (
          <div>404 Not found</div>
        ) : (
          <TheComponent {...props} />
        )
      }
    />
  );
};

export default PrivateRoute;
