export default class AddressResponseViewModel {
  //
  // Address data
  //

  country: string;
  postalCode: string;
  city: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;

  //
  // Personal data
  //

  company: string;
  contactName: string;
  contactPhoneNumber: string;
  contactEmail: string;
  kmkRegistrationNumber: string;
  tntClientNumber: string;
  contactReference: string;
}
