import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Dispatch } from 'redux';

import { ErrorWrapper } from '../../../../../../viewModels/base';
import { change } from 'redux-form';
import { deliveryFormName } from '../components/Shipment.component';
import { AddressApi } from '../../../../../../api/Address.api';
import { AsyncActionCreators } from 'typescript-fsa/dist/typescript-fsa';
import CommonUtilities from '../../../../../../helpers/CommonUtilities';
const actionCreator = actionCreatorFactory();

export const senderToggleVisibility = actionCreator<{}>('DELIVERY/SENDER-VISIBILITY/TOGGLE');
export const senderSearchCancel = actionCreator<{}>('DELIVERY/SENDER-SEARCH/CANCEL');
export const senderSearchStart = actionCreator<{}>('DELIVERY/SENDER-SEARCH/START');
export const senderSearchSubmitAsyncActions = actionCreator.async<{}, {}, { errorWrapper: ErrorWrapper }>(
  'DELIVERY/SENDER-SEARCH/SUBMIT',
);

export const senderAlternativeToggleVisibility = actionCreator<{}>('DELIVERY/SENDER-ALTERNATIVE-VISIBILITY/TOGGLE');
export const senderAlternativeSearchCancel = actionCreator<{}>('DELIVERY/SENDER-ALTERNATIVE-SEARCH/CANCEL');
export const senderAlternativeSearchStart = actionCreator<{}>('DELIVERY/SENDER-ALTERNATIVE-SEARCH/START');
export const senderAlternativeSearchSubmitAsyncActions = actionCreator.async<{}, {}, { errorWrapper: ErrorWrapper }>(
  'DELIVERY/SENDER-ALTERNATIVE-SEARCH/SUBMIT',
);

export const receiverToggleVisibility = actionCreator<{}>('DELIVERY/RECEIVER-VISIBILITY/TOGGLE');
export const receiverSearchCancel = actionCreator<{}>('DELIVERY/RECEIVER-SEARCH/CANCEL');
export const receiverSearchStart = actionCreator<{}>('DELIVERY/RECEIVER-SEARCH/START');
export const receiverSearchSubmitAsyncActions = actionCreator.async<{}, {}, { errorWrapper: ErrorWrapper }>(
  'DELIVERY/RECEIVER-SEARCH/SUBMIT',
);

export const receiverAlternativeToggleVisibility = actionCreator<{}>('DELIVERY/RECEIVER-ALTERNATIVE-VISIBILITY/TOGGLE');
export const receiverAlternativeSearchCancel = actionCreator<{}>('DELIVERY/RECEIVER-ALTERNATIVE-SEARCH/CANCEL');
export const receiverAlternativeSearchStart = actionCreator<{}>('DELIVERY/RECEIVER-ALTERNATIVE-SEARCH/START');
export const receiverAlternativeSearchSubmitAsyncActions = actionCreator.async<{}, {}, { errorWrapper: ErrorWrapper }>(
  'DELIVERY/RECEIVER-ALTERNATIVE-SEARCH/SUBMIT',
);

function fetch(contactId: number, key: string, asyncActions: AsyncActionCreators<{}, {}, any>): Function {
  return async (dispatch: Dispatch<AnyAction>): Promise<void> => {
    async function mainAction(): Promise<void> {
      dispatch(asyncActions.started({}));
      const contact = await AddressApi.getSingleContact(contactId);
      dispatch(change(deliveryFormName, key, contact));
      dispatch(asyncActions.done({ params: {}, result: {} }));
    }

    async function cancelAction(exception: any): Promise<void> {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, cancelAction);
  };
}

export function senderSearchSubmit(contactId: number): Function {
  return fetch(contactId, 'sender', senderSearchSubmitAsyncActions);
}
export function senderAlternativeSearchSubmit(contactId: number): Function {
  return fetch(contactId, 'senderAlternative', senderAlternativeSearchSubmitAsyncActions);
}
export function receiverSearchSubmit(contactId: number): Function {
  return fetch(contactId, 'receiver', receiverSearchSubmitAsyncActions);
}
export function receiverAlternativeSearchSubmit(contactId: number): Function {
  return fetch(contactId, 'receiverAlternative', receiverAlternativeSearchSubmitAsyncActions);
}
