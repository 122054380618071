import actionCreatorFactory from 'typescript-fsa';
import { Dispatch } from 'redux';
import { initialize } from 'redux-form';
import { vehicleFormName } from '../../components/Vehicle.component';
import { ErrorWrapper, NotFoundErrorWrapper } from '../../../../../../../viewModels/base';
import { VehicleApi } from '../../../../../../../api/Vehicle.api';
import CommonUtilities from '../../../../../../../helpers/CommonUtilities';

const actionCreator = actionCreatorFactory();
const type = 'PARTNER/EDIT/START';
export const asyncActions = actionCreator.async<{}, { addressId: number }, ErrorWrapper>(type);

export default function start(addressId: number) {
  return async (dispatch: Dispatch<any>) => {
    async function mainAction() {
      dispatch(asyncActions.started({}));
      const address = await VehicleApi.getSingle(addressId);
      if (!address) {
        throw new NotFoundErrorWrapper(`Item with Id ${addressId} not found`);
      }
      dispatch(initialize(vehicleFormName, address));
      dispatch(asyncActions.done({ params: {}, result: { addressId } }));
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
