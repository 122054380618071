import * as React from 'react';
import { ReactElement } from 'react';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment';
import { BootstrapTable, Options, SortOrder, TableHeaderColumn } from 'speys-react-bootstrap-table';
import Select from 'react-select';
import DeliveriesResponseViewModel from '../../../../../viewModels/delivery/deliveriesResponseViewModel';
import TableFormattingUtilities from '../../../../../helpers/TableFormattingUtilities';
import LocaleUtilities from '../../../../../helpers/LocaleUtilities';
import CommonUtilities from '../../../../../helpers/CommonUtilities';

import IconButton from '@material-ui/core/IconButton';
import ButtonMenu from '../../../../../components/SpeysButtons/ButtonMenu';
import MenuItem from '@material-ui/core/MenuItem';
import FlagAndAddressComponet from '../../../../../components/Address/FlagAndAddress.componet';
import SpeysCloudLoadingComponent from '../../../../../components/SpeysCloud.Loading.component';
import { routePaths } from '../../../../../constants/api.constants';
import { UserApi } from '../../../../../api/User.api';
//import { FieldArray } from 'redux-form';

// const FieldArray2: any = FieldArray; //hack
function safe(key: keyof DeliveriesResponseViewModel) {
  return CommonUtilities.typeSafeName<DeliveriesResponseViewModel>(key);
}

interface DriverComponentState {
  drivers: any[];
  driver: string;
}

//React hook need more investigate
/*
const DriverComponent = (props) => {
    const [drivers, setDrivers] = React.useState([])
    const [driver, setDriver] = React.useState("");
    const row = props.row;
    React.useEffect(() => {
        async function fetchDriver() {
            var newDrivers = await UserApi.getSelectComponentList({});
            setDrivers({
                drivers: newDrivers
            });
        }
        fetchDriver();
    })
    return (
        <div>
            {drivers !== null &&
                <Select
                    multi={true}
                    menuContainerStyle={{ 'zIndex': 999 }}
                    placeholder={'Choose driver'}
                    options={drivers}
                    value={driver}
                    onChange={e => this.handleDriverChange(e, row)}
                />
            }
        </div>
    );
}
*/
export class DriverComponent extends React.Component<{ row: any }, DriverComponentState> {
  state = {
    drivers: [],
    driver: '',
  };

  async componentDidMount(): Promise<void> {
    const drivers = await UserApi.getSelectComponentList({});
    this.setState({
      drivers: drivers,
    });
  }

  handleDriverChange = (newDriver: any, row): void => {
    this.setState(
      () => ({
        driver: newDriver,
      }),
      () => {
        console.log(this.state.driver);
        console.log(row);
      },
    );
  };

  render(): JSX.Element {
    const row = this.props.row;
    return (
      <Select
        multi={true}
        menuContainerStyle={{ zIndex: 999 }}
        placeholder={'Choose driver'}
        options={this.state.drivers}
        value={this.state.driver}
        onChange={(e): void => this.handleDriverChange(e, row)}
      />
    );
  }
}

export default class ShipmentsTableComponent extends React.Component<
  {
    data: Array<DeliveriesResponseViewModel>;
    handleEditClick: Function;
    total: number;
    page: number;
    sizePerPage: number;
    onSortChange: Function;
    onPageChange: Function;
    onSizePerPageList: Function;
    handleDeleteStart: Function;
    handleDownloadPdf: Function;
    fetchCreateShippingOrder: Function;
    loading: boolean;
  },
  {}
> {
  handleEditClick = (id: number): void => {
    this.props.handleEditClick(id);
  };

  private statusFormat = (cell: any, row: any, formatExtraData: any, rowIndex: number): ReactElement<any> => {
    return TableFormattingUtilities.statusFormat(cell, false);
  };

  private editLinkFormat = (cell: any, row: any): ReactElement<any> => {
    return TableFormattingUtilities.boldTextFormatWithLink(
      `#${cell}`,
      undefined,
      routePaths.shipments.editId(row.id),
      false,
    );
  };

  render(): JSX.Element {
    const { data, page, total, sizePerPage, loading } = this.props;
    const { onSortChange, onPageChange, onSizePerPageList } = this.props;
    const tableOptions: Options = {
      page: page,
      sizePerPage: sizePerPage,
      sizePerPageList: [5, 10, 25],
      onSortChange: (sortName: any, sortOrder: SortOrder) => onSortChange(sortName, sortOrder),
      onPageChange: (pageParam: number, sizePerPageParam: number) => onPageChange(pageParam, sizePerPageParam),
      onSizePerPageList: (sizePerPageParam: number) => onSizePerPageList(sizePerPageParam),
    };

    return (
      <div className="card">
        <Segment className="card-body p-0 card-with-data-table">
          <SpeysCloudLoadingComponent isLoading={loading} error={undefined} />
          <h4 className="card-title">{LocaleUtilities.i18n('shipment-list-Shipments list', 'shipment.list')}</h4>
          <BootstrapTable
            data={data}
            remote={true}
            pagination={true}
            bordered={false}
            version="4"
            containerClass="speys-data-table table-hover"
            fetchInfo={{ dataTotalSize: total }}
            options={tableOptions}
          >
            <TableHeaderColumn isKey={true} hidden={true} dataField="id">
              Id
            </TableHeaderColumn>
            <TableHeaderColumn />
            <TableHeaderColumn dataField={safe('id')} dataFormat={this.editLinkFormat} dataSort={true}>
              {LocaleUtilities.i18n('shipment-list-Number', 'shipment.list')}
            </TableHeaderColumn>
            {/*<TableHeaderColumn
                            dataField={safe('company')}
                            dataSort={true}
                        >
                            {LocaleUtilities.i18n('shipment-list-Company', 'shipment.list')}
                        </TableHeaderColumn>*/}
            <TableHeaderColumn dataField={safe('pickup')} dataSort={true} dataFormat={this.loadingAddressFormat}>
              {LocaleUtilities.i18n('shipment-list-Loading address', 'shipment.list')}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField={safe('loadingDate')}
              dataFormat={TableFormattingUtilities.dateFormat}
              dataSort={true}
            >
              {LocaleUtilities.i18n('shipment-list-Loading date', 'shipment.list')}
            </TableHeaderColumn>
            <TableHeaderColumn dataField={safe('destination')} dataSort={true} dataFormat={this.deliveryAddressFormat}>
              {LocaleUtilities.i18n('shipment-list-Delivery address', 'shipment.list')}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField={safe('deliveryDate')}
              dataFormat={TableFormattingUtilities.dateFormat}
              dataSort={true}
            >
              {LocaleUtilities.i18n('shipment-list-Delivery date', 'shipment.list')}
            </TableHeaderColumn>
            {/* <TableHeaderColumn
                            dataField={safe('hasPodFiles')}
                            dataFormat={this.statusPodFormat}
                            dataSort={true}
                            className={'text-center'}
                            columnClassName={'text-center'}
                        >
                            {LocaleUtilities.i18n('shipment-list-POD', 'shipment.list')}
                        </TableHeaderColumn> 
                        <TableHeaderColumn
                            width="200px"
                            dataField={safe('driver')}
                            dataFormat={this.driverFormat}
                            dataSort={true}
                            className={'text-center'}
                            columnClassName={'text-center'}
                        >
                            {LocaleUtilities.i18n('shipment-list-Driver', 'shipment.list')}
                        </TableHeaderColumn>*/}
            <TableHeaderColumn
              dataField={safe('status')}
              dataFormat={this.statusFormat}
              dataSort={true}
              className={'text-right'}
              columnClassName={'text-right'}
            >
              {LocaleUtilities.i18n('shipment-list-Status', 'shipment.list')}
            </TableHeaderColumn>
            <TableHeaderColumn dataFormat={this.actionFormat} width="10%" />
          </BootstrapTable>
        </Segment>
      </div>
    );
  }

  /* private numberFormat = (cell: any, row: any, formatExtraData: any, rowIndex: number): ReactElement<any> => {
         return (
             <span className='font-bold'>#{row.id}</span>
         );
     }*/

  private loadingAddressFormat = (cell: any, row: any, formatExtraData: any, rowIndex: number): ReactElement<any> => {
    return <FlagAndAddressComponet flag={row.loadingAddressCountry} address={row.shortLoadingAddress} />;
  };
  private deliveryAddressFormat = (cell: any, row: any, formatExtraData: any, rowIndex: number): ReactElement<any> => {
    return <FlagAndAddressComponet flag={row.deliveryAddressCountry} address={row.shortDeliveryAddress} />;
  };
  /* private statusPodFormat = (cell: any, row: any, formatExtraData: any, rowIndex: number): ReactElement<any> => {
        if (cell === true) {
            return (
                <span className="badge-success ladgend"></span>
            );
        }
        return (
            <span></span>
        );
    } 

    private driverFormat = (cell: any, row: any, formatExtraData: any, rowIndex: number): ReactElement<any> => {
        return (
            <FieldArray
                name="driver"
                component={() => {
                    return <DriverComponent row={row.id} />;
                }}
            />
        );
    }
    */
  private actionFormat = (cell: any, row: any, formatExtraData: any, rowIndex: number): ReactElement<any> => {
    return (
      <div className={'text-right'}>
        <IconButton className={'table-action-button'} color="primary" onClick={(e) => this.handleEditClick(row.id)}>
          <i className={' ti-pencil'} />
        </IconButton>
        <ButtonMenu>
          <MenuItem className={'table-menu-item'} onClick={(): void => this.handleEditClick(row.id)}>
            <a href="javascript:void(0)" className="list-action-button list-button-edit">
              <i className="ti-download" /> {LocaleUtilities.i18n('shipment-menu-item-Edit', 'shipment.list')}
            </a>
          </MenuItem>
          {/*<MenuItem className={'table-menu-item hidden'} onClick={e => this.props.handleDownloadPdf(row.id)}>
                        <a href="javascript:void(0)" className="list-action-button list-button-edit">
                            <i className='ti-download' /> {LocaleUtilities.i18n('shipment-menu-item-Download PDF', 'shipment.list')}
                        </a>
                    </MenuItem>
                    <MenuItem className={'table-menu-item'} onClick={e => this.props.handleDeleteStart(row.id)}>
                        <a href="javascript:void(0)" className="list-action-button list-button-edit">
                            <i className='ti-trash' /> {LocaleUtilities.i18n('menu-item-Delete', 'base.button')}
                        </a>
                    </MenuItem> */}
        </ButtonMenu>
      </div>
    );
  };
}
