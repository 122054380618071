import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Dispatch } from 'redux';

import { DriverViewApi } from 'api/Driver.api';
import CommonUtilities from 'helpers/CommonUtilities';
import { ErrorWrapper } from 'viewModels/base';
import { DeliveryStatus } from 'viewModels/enums';
import ChangeShipmentStatusResponseViewModel from 'viewModels/driverView/ChangeShipmentStatusResponseViewModel';

//
// Change shipment status action
//

const actionCreator = actionCreatorFactory();
const type = 'DRIVERVIEW/CHANGE_SHIPMENT_STATUS';
export const asyncActions = actionCreator.async<{}, ChangeShipmentStatusResponseViewModel, ErrorWrapper>(type);

export default function fetchAsync(driverViewGuid: string, shipmentId: number, status: DeliveryStatus): Function {
  return async (dispatch: Dispatch<AnyAction>): Promise<void> => {
    async function mainAction(): Promise<void> {
      dispatch(asyncActions.started({}));

      const result = await DriverViewApi.changeShipmentStatus(driverViewGuid, shipmentId, status);

      dispatch(asyncActions.done({ params: {}, result }));
    }

    async function catchAction(exception: ErrorWrapper): Promise<void> {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
