import actionCreatorFactory from 'typescript-fsa';
import { Dispatch } from 'redux';
import { DeliveryApi } from '../../../../../../api/Delivery.api';

import { ErrorWrapper, NotFoundErrorWrapper } from '../../../../../../viewModels/base';
import CommonUtilities from '../../../../../../helpers/CommonUtilities';
import { DeliveryStatus } from '../../../../../../viewModels/enums';

const actionCreator = actionCreatorFactory();
const type = 'SHIPMENT/CHANGE_STATUS';
export const asyncActions = actionCreator.async<{}, { deliveryId: number; status: number }, ErrorWrapper>(type);

export default function changeStatus(deliveryId: number, statusId: string) {
  return async (dispatch: Dispatch<any>) => {
    async function mainAction() {
      dispatch(asyncActions.started({}));
      const delivery = await DeliveryApi.getSingle(deliveryId);
      if (!delivery) {
        throw new NotFoundErrorWrapper(`Item with Id ${deliveryId} not found`);
      }
      let newStatus = DeliveryStatus.NotSelected;
      try {
        newStatus = DeliveryStatus[statusId];
      } catch (e) {}
      await DeliveryApi.changeStatus(deliveryId, newStatus);
      dispatch(asyncActions.done({ params: {}, result: { deliveryId: deliveryId, status: newStatus } }));
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
