import * as React from 'react';
import { ErrorWrapper, ValidationFailure } from '../viewModels/base';
import LocaleUtilities from '../helpers/LocaleUtilities';

export default class ErrorBlock extends React.Component<{
  error?: ErrorWrapper;
  errorTranslationKey?: string;
  errorTranslationGroup?: string;
}> {
  removeDuplicates(myArr: ValidationFailure[], prop: any) {
    const messages: string[] = [];
    return myArr.filter((obj, pos, arr) => {
      obj.errorMessage = obj.errorMessage.replace('.', '');
      if (obj.errorMessage) {
        if (messages.indexOf(obj.errorMessage) === -1) {
          const message = obj.errorMessage.replace('.', '');
          messages.push(message);
          return arr.push(obj);
        }
        return;
      } else {
        return arr.push(obj);
      }
    });
  }

  render(): React.ReactNode {
    const { error, errorTranslationKey, errorTranslationGroup } = this.props;
    if (!error) {
      return null;
    }
    const formValidations = error.formValidations;
    return (
      <div className="alert alert-danger">
        {error.title
          ? errorTranslationKey === undefined || errorTranslationGroup === undefined
            ? error.title
            : LocaleUtilities.i18n(errorTranslationKey + error.title.replace('.', ''), errorTranslationGroup)
          : null}
        <br />
        {error.description
          ? errorTranslationKey === undefined || errorTranslationGroup === undefined
            ? error.description
            : LocaleUtilities.i18n(errorTranslationKey + error.description.replace('.', ''), errorTranslationGroup)
          : null}
        {formValidations && formValidations.errors ? (
          <ul className={'pl-20'}>
            {this.removeDuplicates(formValidations.errors, 'fake').map((item, index) =>
              errorTranslationKey === undefined || errorTranslationGroup === undefined ? (
                <li key={index}>{item.errorMessage}</li>
              ) : (
                <li key={index}>
                  {LocaleUtilities.i18n(
                    errorTranslationKey + item.errorMessage.replace('.', ''),
                    errorTranslationGroup,
                  )}
                </li>
              ),
            )}
          </ul>
        ) : (
          <div />
        )}
      </div>
    );
  }
}
