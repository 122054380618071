import * as React from 'react';
import { Field, FieldArray, InjectedFormProps, WrappedFieldArrayProps, formValueSelector, reduxForm } from 'redux-form';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SeedUtilities from '../../../../../helpers/SeedUtilities';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import FlagAndAddressComponet from '../../../../../components/Address/FlagAndAddress.componet';
import { countryOptions } from '../../../../../seed';
import { AllRequestComponentProps } from './Request.component';

/*COMPONENTS*/
import ShipmentExpandableBlockComponent from '../../Shipment/Shipment.View/components/Shipment.Expandable.Block.component';

/*ViewModels*/
import RequestCreateOrUpdateRequestViewModel from '../../../../../viewModels/request/RequestCreateOrUpdateRequestViewModel';
import {
  ShippingOrderShipment,
  ShippingOrderProductRow,
} from '../../../../../viewModels/request/RequestResponseViewModel';
import { default as BaseFormFields } from '../../../../../helpers/FormFieldUtilities';
import { SpeysSelectStringField } from '../../../../../components/Speys/SpeysCloud.SelectField.component';
import LocaleUtilities from '../../../../../helpers/LocaleUtilities';

const FieldArray2: any = FieldArray; // hack

interface ShippingOrderShipmentRowProps {
  key: number;
}

class FormFields extends BaseFormFields<any> {
  getLoadingCountry = (member: string) => (
    <SpeysSelectStringField name={`${member}.loadingCountry`} options={countryOptions} customClass={'mb-0'} />
  );

  getLoadingAddressLineField = (member: string) => {
    return <Field name={`${member}.loadingAddressLine1`} component="input" className="form-control" placeholder="" />;
  };

  getLoadingPostCodeField = (member: string) => {
    return <Field name={`${member}.loadingPostCode`} component="input" className="form-control" placeholder="" />;
  };
  getLoadingDate = (member: string) => {
    return super.dateField2(`${member}.loadingDate`, 'Date & Time', false);
  };

  getLoadingCityField = (member: string) => {
    return <Field name={`${member}.loadingCity`} component="input" className="form-control" placeholder="" />;
  };

  getConsignorField = (member: string) => {
    return <Field name={`${member}.consignor`} component="input" className="form-control" placeholder="" />;
  };

  getConsignorContactPersonField = (member: string) => {
    return (
      <Field name={`${member}.consignorContactPerson`} component="input" className="form-control" placeholder="" />
    );
  };

  getLoadingContactPersonPhoneField = (member: string) => {
    return (
      <Field name={`${member}.consignorContactPersonPhone`} component="input" className="form-control" placeholder="" />
    );
  };
  getShipmentPriceField = (member: string) => {
    return <Field name={`${member}.netPrice`} component="input" className="form-control" placeholder="" />;
  };

  getDeliveryCountry = (member: string) => (
    <SpeysSelectStringField name={`${member}.deliveryCountry`} options={countryOptions} customClass={'mb-0'} />
  );

  getDeliveryAddressLineField = (member: string) => {
    return <Field name={`${member}.deliveryAddressLine1`} component="input" className="form-control" placeholder="" />;
  };

  getDeliveryPostCodeField = (member: string) => {
    return <Field name={`${member}.deliveryPostCode`} component="input" className="form-control" placeholder="" />;
  };
  getDeliveryDate = (member: string) => {
    return super.dateField2(`${member}.deliveryDate`, 'Date & Time', false);
  };

  getDeliveryCityField = (member: string) => {
    return <Field name={`${member}.deliveryCity`} component="input" className="form-control" placeholder="" />;
  };

  getConsigneeField = (member: string) => {
    return <Field name={`${member}.consignee`} component="input" className="form-control" placeholder="" />;
  };

  getConsigneeContactPersonField = (member: string) => {
    return (
      <Field name={`${member}.consigneeContactPerson`} component="input" className="form-control" placeholder="" />
    );
  };

  getConsigneeContactPersonPhoneField = (member: string) => {
    return (
      <Field name={`${member}.consigneeContactPersonPhone`} component="input" className="form-control" placeholder="" />
    );
  };
}

export const shippingOrderRequestFormName = 'requestForm';

export declare type AllShippingOrderShipmentRequestProps = ShippingOrderShipmentRowProps &
  RouteComponentProps<any> &
  WrappedFieldArrayProps<any> &
  InjectedFormProps<any> & {
    canDeleteShipments: boolean;
    onRemove: Function;
    updateShipmentPrices: Function;
    requestId: number;
    deliveryStatus: number;
  };

class ShippingOrderShipmentRequestComponent extends React.Component<
  AllShippingOrderShipmentRequestProps & {},
  {
    alert?: any;
    activeIndex: number;
  }
> {
  constructor(props: any) {
    super(props);
  }

  getTitleDangerousHtml(member?: ShippingOrderShipment) {
    if (member == undefined) {
      return '<span></span>';
    }
    return (
      ' <span class="ml-10 text-normal">#' +
      member.number +
      '</span>\n' +
      '                    <span class="ml-10 text-normal">Quantity: ' +
      member.quantity +
      '</span>\n' +
      '                    <span class="ml-10 text-normal">Volume: ' +
      member.volume +
      'm<sup>3</sup></span>\n' +
      '                    <span class="ml-10 text-normal">Weight: ' +
      member.weight +
      'kg</span>\n' +
      '                    <span class="ml-10 text-normal">Loading meters: ' +
      member.loadingMeters +
      '</span>'
    );
  }

  getRowData = (key: number): ShippingOrderShipment => {
    return this.props.fields.get(key);
  };

  render() {
    const { fields } = this.props;
    const formFields = new FormFields();
    return (
      <div>
        {fields !== undefined &&
          fields.length > 0 &&
          fields.map((member: string, key: number) => {
            const element = this.getRowData(key);
            return (
              <ShipmentExpandableBlockComponent
                key={key + 12}
                panelKey={1}
                dangerousHtml={this.getTitleDangerousHtml(element)}
                title={'Shipment'}
                icon={'ti-package'}
              >
                <Row className={'mt-10'}>
                  <Col md={6}>
                    <span>
                      <div className="address mt-5">
                        <div className={'ml-5'}>
                          {element.consignor}
                          <div className={'ml-5'}>
                            <small>
                              Contact: {element.consignorContactPerson} {element.consignorContactPersonPhone}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div className="address mt-15">
                        <div className="font-bold">
                          {LocaleUtilities.i18n('shippingOrder-view-From', 'shippingOrder.details')}
                        </div>
                        <FlagAndAddressComponet flag={element.loadingCountry} address={''} />
                        <div className={'ml-5'}>
                          {element.loadingCountry}-{element.loadingPostCode} {element.loadingCity}
                        </div>
                        <div className={'ml-5'}>{element.loadingAddressLine1}</div>
                        <div className={'ml-5 mt-10'}>
                          <small>
                            {LocaleUtilities.i18n('shipment-list-Loading date', 'shipment.list')}{' '}
                            {formFields.getLoadingDate(member)}
                          </small>
                        </div>
                      </div>
                    </span>
                  </Col>
                  <Col md={6} className="border-left display-block">
                    <div className="address mt-5">
                      <div className={'ml-5'}>
                        {element.consignee}
                        <div className={'ml-5'}>
                          <small>
                            Contact: {element.consigneeContactPerson} {element.consigneeContactPersonPhone}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="address  mt-15">
                      <div className="font-bold">
                        {LocaleUtilities.i18n('shippingOrder-view-To', 'shippingOrder.details')}
                      </div>
                      <FlagAndAddressComponet flag={element.deliveryCountry} address={''} />
                      <div className={'ml-5'}>
                        {element.deliveryCountry}-{element.deliveryPostCode} {element.deliveryCity}
                      </div>
                      <div className={'ml-5'}>{element.deliveryAddressLine1}</div>
                      <div className={'ml-5 mt-10'}>
                        <small>
                          {LocaleUtilities.i18n('shipment-list-Delivery date', 'shipment.list')}{' '}
                          {formFields.getDeliveryDate(member)}
                        </small>
                      </div>
                    </div>
                  </Col>
                </Row>
                <hr style={{ width: '100%', marginBottom: '1rem', marginTop: '1rem' }} />
                <Row className={'mt-15'}>
                  <Col md={12}>
                    <div className="table-responsive bg-white">
                      <table className={'table table-stripped table-hover'}>
                        <thead>
                          <tr>
                            <th>{LocaleUtilities.i18n('Quantity', 'shipment.details')}</th>
                            <th>{LocaleUtilities.i18n('shipment-details-Parcel type', 'shipment.details')}</th>
                            <th>{LocaleUtilities.i18n('shipment-details-Measure', 'shipment.details')}</th>
                            <th>{LocaleUtilities.i18n('Weight', 'shipment.details')}</th>
                            <th>{LocaleUtilities.i18n('Stackable', 'shipment.details', true)}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {element.rows !== undefined &&
                            element.rows.map((member: ShippingOrderProductRow, key: number) => (
                              <tr key={key}>
                                <td>{member.quantity}</td>
                                <td>
                                  {member.typeId !== undefined
                                    ? SeedUtilities.getShipmentType(member.typeId.toString())
                                    : null}
                                </td>
                                <td>
                                  {member.length} x {member.width} x {member.height} cm
                                </td>
                                <td>{member.weight} kg</td>
                                <td>{member.stackable ? 'Yes' : 'No'}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
                <hr style={{ width: '100%', marginBottom: '1rem', marginTop: '1rem' }} />
              </ShipmentExpandableBlockComponent>
            );
          })}
      </div>
    );
  }
}

class ShipmentComponent extends React.Component<AllRequestComponentProps> {
  render() {
    return <FieldArray2 name="shipments" component={ShippingOrderShipmentRequestComponent} />;
  }
}

const ShippingOrderRequestFormComponent = reduxForm<RequestCreateOrUpdateRequestViewModel>({
  form: shippingOrderRequestFormName,
})(ShipmentComponent);
// Decorate with connect to read form values
const selector = formValueSelector<RequestCreateOrUpdateRequestViewModel>(shippingOrderRequestFormName);

export default connect<{}, {}, {}>(
  (state: any) => {
    const formName = 'requestForm';
    let request = {};
    if (state.form !== undefined && state.form[formName] !== undefined) {
      request = state.form[formName].values || {};
    }
    const shipmentDetailsRows = selector(state, 'shipmentDetailsRows');
    return {
      shipmentDetailsRows,
      request,
    };
  },
  (dispatch: any) => ({}),
)(withRouter<any>(ShippingOrderRequestFormComponent));
