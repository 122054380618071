import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Dispatch } from 'redux';
import { toastr } from 'react-redux-toastr';

import { ErrorWrapper } from '../../../../../../viewModels/base';
import CommonUtilities from '../../../../../../helpers/CommonUtilities';
import AccountApi from '../../../../../../api/Account.api';

const actionCreator = actionCreatorFactory();
export const asyncActions = actionCreator.async<{}, {}, ErrorWrapper>('USERS/TOGGLE_ACTIVATION');

export default function submit(userId: number, activated: boolean, onSuccess: Function) {
  return async (dispatch: Dispatch<AnyAction>) => {
    async function mainAction() {
      dispatch(asyncActions.started({}));
      await AccountApi.toggleActivation(userId, activated);
      dispatch(asyncActions.done({ params: {}, result: {} }));
      if (onSuccess) {
        onSuccess();
      }
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
      toastr.error('Error', 'Error has occurred');
      CommonUtilities.scrollToTop();
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
