import * as React from 'react';
//import {Link} from 'react-router-dom';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Modal from 'reactstrap/lib/Modal';
import Button from 'reactstrap/lib/Button';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { RouteComponentProps, withRouter } from 'react-router';

import { DeliveriesReducerState } from './Shipments.reducer';
import SearchFieldsComponent from './Shipment.SearchFields.component';
import DeliveriesTableComponent from './Shipment.Table.component';
import { fetchAsyncType } from './actions/Shipment.fetch.action';
import DeliveriesRequestViewModel from '../../../../../viewModels/delivery/deliveriesRequestViewModel';
import LocaleUtilities from '../../../../../helpers/LocaleUtilities';
import { routePaths } from '../../../../../constants/api.constants';
import SpeysContent from '../../../../../components/SpeysContent';
import DetailviewTopNavComponent from '../../../../../components/Template/Detailview.TopNav.component';
import PageTitleComponent from '../../../../../components/Template/PageTitle.component';

export type DeliveriesComponentStateProps = DeliveriesReducerState;

export interface DeliveriesComponentDispatchProps {
  fetchAsync: fetchAsyncType;
  deleteCancel: Function;
  deleteStart: Function;
  deleteSubmit: Function;
  downloadPdf: Function;

  onSortChange: Function;
  onPageChange: Function;
  onSizePerPageList: Function;
  fetchCreateShippingOrder: Function;
}

type DeliveryListComponentProps = DeliveriesComponentStateProps &
  DeliveriesComponentDispatchProps &
  RouteComponentProps<any> &
  InjectedFormProps<any>;

export const deliveriesFormName = 'deliveriesForm';

class ShipmentComponent extends React.Component<DeliveryListComponentProps> {
  componentDidMount() {
    const request = new DeliveriesRequestViewModel();
    request.parse(location);
    this.props.fetchAsync(request, true, false);
    this.handleEditClick = this.handleEditClick.bind(this);
  }

  handleSearch = () => {
    this.props.fetchAsync(null, false, true);
  };
  handleDeleteStart = (id: number) => {
    this.props.deleteStart(id);
  };
  handleDeleteCancel = () => {
    this.props.deleteCancel();
  };
  handleDeleteSubmit = () => {
    this.props.deleteSubmit();
  };
  handleDownloadPdf = (id: number) => {
    this.props.downloadPdf(id);
  };
  handleEditClick = (id: number) => {
    this.props.history.push(routePaths.shipments.editId(id));
  };
  render() {
    const { data, pagination } = this.props.tableData;
    const { pendingDeleteId, confirmLoading } = this.props;
    const { onSortChange, onPageChange, onSizePerPageList } = this.props;

    return (
      <SpeysContent styleName={'ct-table'}>
        <SpeysContent.Body>
          <div className="container-fluid">
            <DetailviewTopNavComponent>
              <div className="text-right">
                {/*
                                <Link
                                    to={routePaths.shipments.new}
                                    className="btn waves-effect waves-dark btn-speys btn-sm"
                                >
                                    + {LocaleUtilities.i18n('shipment-list-Add new shipment', 'shipment.list')}
                                </Link>*/}
              </div>
            </DetailviewTopNavComponent>
            <PageTitleComponent title={LocaleUtilities.i18n(`sidebar-menu-item-Shipments`, 'sidebar.menu', true)} />
            <Row className={'mt-15'}>
              <Col md={12}>
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex">
                      <div>
                        <h4 className="card-title">
                          <span className="lstick" />
                          {LocaleUtilities.i18n('form-title-Filter', 'base.filter')}
                        </h4>
                      </div>
                    </div>
                    <SearchFieldsComponent handleSearch={() => this.handleSearch()} />
                  </div>
                </div>
              </Col>
            </Row>

            {/* margin-top 150px is temporary fix for datepicker and status selector */}
            <div className=" animated fadeIn speys-data-table-container">
              <Row className="animated bounceInRight mt-15">
                <Col md={12}>
                  <DeliveriesTableComponent
                    data={data}
                    total={pagination.total}
                    page={pagination.page}
                    sizePerPage={pagination.sizePerPage}
                    onSortChange={onSortChange}
                    onPageChange={onPageChange}
                    onSizePerPageList={onSizePerPageList}
                    handleDeleteStart={this.handleDeleteStart}
                    handleDownloadPdf={this.handleDownloadPdf}
                    handleEditClick={this.handleEditClick}
                    loading={this.props.loading}
                    fetchCreateShippingOrder={this.props.fetchCreateShippingOrder}
                  />
                </Col>
              </Row>
            </div>
          </div>

          <Modal isOpen={pendingDeleteId > 0} toggle={this.handleDeleteCancel}>
            <ModalHeader toggle={this.handleDeleteCancel}>
              {LocaleUtilities.i18n('confirm-modal-title-Are you sure', 'confirm.modal')}
            </ModalHeader>
            <ModalBody>{LocaleUtilities.i18n('Deliveries-Modal-DoYouWantToDeleteThis')}</ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.handleDeleteSubmit} disabled={confirmLoading}>
                {LocaleUtilities.i18n('modal-button-Continue', 'confirm.modal')}
              </Button>{' '}
              <Button color="secondary" onClick={this.handleDeleteCancel} disabled={confirmLoading}>
                {LocaleUtilities.i18n('modal-button-Cancel', 'confirm.modal')}
              </Button>
            </ModalFooter>
          </Modal>
        </SpeysContent.Body>
      </SpeysContent>
    );
  }
}

export default reduxForm({ form: deliveriesFormName })(withRouter(ShipmentComponent));
