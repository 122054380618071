import { PaginatedSearchBaseOptionsResult } from '../base';
import RouterUtilities from '../../helpers/RouterUtilities';
// import { Moment } from 'moment';
// import LocaleUtilities from '../../helpers/LocaleUtilities';

export default class CompaniesRequestViewModel extends PaginatedSearchBaseOptionsResult {
  name: string;
  status: string;

  public parse(location: Location) {
    const obj = RouterUtilities.locationSearchStringParse(location);
    if (!obj) {
      return;
    }

    super.baseParse(obj);
    if ('name' in obj && typeof obj.number === 'string') {
      this.name = obj.name;
    }
    if ('status' in obj && typeof obj.status === 'string') {
      this.status = obj.status;
    }
  }
}
