import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Dispatch } from 'redux';
import { vehicleFormName } from '../../components/Vehicle.component';
import { ErrorWrapper } from '../../../../../../../viewModels/base';
import { VehicleApi } from '../../../../../../../api/Vehicle.api';
import { toastr } from 'react-redux-toastr';

import CommonUtilities from '../../../../../../../helpers/CommonUtilities';

const actionCreator = actionCreatorFactory();
const type = 'PARTNER/CREATE/SUBMIT';
export const asyncActions = actionCreator.async<{}, {}, ErrorWrapper>(type);

export default function submit(onSuccess: Function) {
  return async (dispatch: Dispatch<AnyAction>, getState: Function) => {
    async function mainAction() {
      const formValues = getState().form[vehicleFormName].values;
      if (!formValues) {
        throw new ErrorWrapper('Nothing was filled');
      }
      dispatch(asyncActions.started({}));
      await VehicleApi.add(formValues);
      dispatch(asyncActions.done({ params: {}, result: {} }));
      toastr.success('Success', 'Item was successfully created');
      if (onSuccess) {
        onSuccess();
      }
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
      toastr.error('Error', 'Item was not created');
      CommonUtilities.scrollToTop();
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
