import actionCreatorFactory from 'typescript-fsa';
import { Dispatch } from 'redux';
import { RequestApi } from '../../../../../../api/Request.api';

import { ErrorWrapper } from '../../../../../../viewModels/base';
import CommonUtilities from '../../../../../../helpers/CommonUtilities';

const actionCreator = actionCreatorFactory();
const type = 'REQUEST/EDIT/PRICE';
export const asyncActions = actionCreator.async<{}, { requestId: number; price: string }, ErrorWrapper>(type);

export default function updatePrice(requestId: number, price: string) {
  return async (dispatch: Dispatch<any>) => {
    async function mainAction() {
      dispatch(asyncActions.started({}));

      await RequestApi.updatePrice(requestId, price);
      dispatch(asyncActions.done({ params: {}, result: { requestId: requestId, price: price } }));
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
