import * as React from 'react';
import { reduxForm, InjectedFormProps, formValueSelector } from 'redux-form';
import { RouteComponentProps, withRouter } from 'react-router';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { UserReduxState } from '../User.reducer';
import BaseFormFields, { FormFieldUtilities } from '../../../../../../helpers/FormFieldUtilities';
import UserCreateOrUpdateRequestViewModel from '../../../../../../viewModels/driver/DriverCreateOrUpdateRequestViewModel';
import SpeysCloudLoaderButtonComponent from '../../../../../../components/SpeysCloud.LoaderButton.component';
import ValidationUtilities from '../../../../../../helpers/ValidationUtilities';
import ReusableComponentUtilities from '../../../../../../helpers/ReusableComponentUtilities';
import { routePaths } from '../../../../../../constants/api.constants';
import DetailviewTopNavComponent from '../../../../../../components/Template/Detailview.TopNav.component';
import LocaleUtilities from '../../../../../../helpers/LocaleUtilities';
import TrucksImage from '../../../../../../assets/img/profile-bg.jpg';

//API
import SpeysContent from '../../../../../../components/SpeysContent';
import ImageBlock from '../../../../../../components/ImageBlock';
import PageTitleComponent from '../../../../../../components/Template/PageTitle.component';
import { connect } from 'react-redux';

class FormFields extends BaseFormFields<keyof UserCreateOrUpdateRequestViewModel> {
  getFirstName = () => this.defaultField('firstName');
  getLastName = () => this.defaultField('lastName');
  getLicenseNr = () => this.defaultField('drivingLicenseNr');
  getEmail = () => this.defaultField('email');
  getPhone = () => this.defaultField('phone');
}

class UserColumnOneFieldsComponent extends React.Component<{
  formFields: FormFields;
  formHelper: FormFieldUtilities;
}> {
  render() {
    const { formFields, formHelper } = this.props;

    return (
      <div className="card">
        <div className="card-body">
          <Row>
            <Col md={6}>
              <h2 className="heading">
                <span>Personal Details</span>
              </h2>
              {formHelper.getField(
                LocaleUtilities.i18n('driver-detail-First Name', 'driver.detail', true),
                formFields.getFirstName(),
                'First Name',
                true,
              )}
              {formHelper.getField(
                LocaleUtilities.i18n('driver-detail-Last Name', 'driver.detail', true),
                formFields.getLastName(),
                'Last Name',
                true,
              )}
              {formHelper.getField(
                LocaleUtilities.i18n('driver-detail-Driving License Number', 'driver.detail', true),
                formFields.getLicenseNr(),
                'Driving License Number',
                true,
              )}
              {formHelper.getField(
                LocaleUtilities.i18n('driver-detail-Email', 'driver.detail', true),
                formFields.getEmail(),
                'Email',
                true,
              )}
              {formHelper.getField(
                LocaleUtilities.i18n('driver-detail-Phone', 'driver.detail', true),
                formFields.getPhone(),
                'Phone',
                true,
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export interface UserComponentStateProps extends UserReduxState {
  title: any;
}

export interface UserComponentDispatchProps {
  cancelItem: Function;
  startItem: Function;
  submitItem: Function;
}

export const userFormName = 'userForm';
type UserComponentProps = UserComponentStateProps &
  UserComponentDispatchProps &
  RouteComponentProps<any> &
  InjectedFormProps<any>;

class UserComponent extends React.Component<UserComponentProps, {}> {
  constructor(props: any) {
    super(props);
  }

  goToList = () => {
    this.props.history.push(routePaths.drivers.list);
  };
  handleSave = () => {
    this.props.submitItem(this.goToList);
  };

  componentDidMount() {
    this.props.startItem(this.props.userId);
  }

  render() {
    const { title } = this.props;
    const errorWrapper = this.props.errorWrapper;
    const { formErrors, formErrorPropertyNames } = ValidationUtilities.getFormErrors(errorWrapper);
    const backToListRowComponent = ReusableComponentUtilities.getBackToListComponent(routePaths.drivers.list);
    const errorRowComponent = ReusableComponentUtilities.getErrorRowComponent(
      errorWrapper,
      'user-detail-error-',
      'user.detail',
    );
    const formHelper = new FormFieldUtilities(formErrors, formErrorPropertyNames);
    const formFields = new FormFields();

    if (ValidationUtilities.isCriticalError(errorWrapper)) {
      return (
        <div>
          {errorRowComponent}
          {backToListRowComponent}
        </div>
      );
    }

    return (
      <SpeysContent styleName={'ct-table'}>
        <SpeysContent.Body>
          <div className="container-fluid">
            <DetailviewTopNavComponent>{backToListRowComponent}</DetailviewTopNavComponent>
            <PageTitleComponent title={LocaleUtilities.i18n(`sidebar-menu-item-Drivers`, 'sidebar.menu', true)} />
            <div className="col-md-10 offset-lg-1">
              <div className="userProfile-wrapper animated fadeIn">
                <Row>
                  <Col md={12}>
                    <h3 className="text-bold sub-title">{title}</h3>
                  </Col>
                </Row>
                <ImageBlock filename={TrucksImage} height={'45px'} />
                {errorRowComponent}
                <Row className="animated bounceInRight">
                  <Col md={12}>
                    <div className="form p-t-20 smaller-label-font">
                      <Row>
                        <Col md={12}>
                          <UserColumnOneFieldsComponent formFields={formFields} formHelper={formHelper} />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} className="text-right mb-20 mt-25">
                          <SpeysCloudLoaderButtonComponent
                            loading={this.props.loading}
                            handleAction={this.handleSave}
                            className={'btn waves-effect waves-light btn-success text-white'}
                          >
                            {LocaleUtilities.i18n('Save', 'base.button')}
                          </SpeysCloudLoaderButtonComponent>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </SpeysContent.Body>
      </SpeysContent>
    );
  }
}

const UsersComponents = reduxForm<UserCreateOrUpdateRequestViewModel>({ form: userFormName })(UserComponent);
// Decorate with connect to read form values
const selector = formValueSelector<UserCreateOrUpdateRequestViewModel>(userFormName);

const changeDimensions = function (data: any) {
  // console.log(this.state)
};
export default connect<{}, {}, {}>(
  (state: any) => {
    const formName = userFormName;
    let user = {};
    let showTenantsBlock = false;
    let showCompaniesBlock = false;
    if (state.form !== undefined && state.form[formName] !== undefined) {
      user = state.form[formName].values || {};
    }
    const userRoles: any[] = selector(state, 'roleNames');
    if (userRoles !== undefined && userRoles.length > 0) {
      userRoles.forEach(function (element: string) {
        if (element === 'TenantUser' || element === 'TenantAdministrator') {
          showTenantsBlock = true;
        }
        console.log(element);
        if (element === 'CompanyUser' || element === 'CompanyAdministrator') {
          showCompaniesBlock = true;
        }
      });
    }
    changeDimensions({});
    return {
      user,
      showTenantsBlock,
      showCompaniesBlock,
    };
  },
  (dispatch: any) => ({}),
)(withRouter<any>(UsersComponents));
// export default reduxForm({form: userFormName})(withRouter(UserComponent));
