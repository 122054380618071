import actionCreatorFactory from 'typescript-fsa';
import { Dispatch } from 'redux';
import { RequestApi } from '../../../../../../api/Request.api';

import { ErrorWrapper } from '../../../../../../viewModels/base';
import CommonUtilities from '../../../../../../helpers/CommonUtilities';
import { RequestStatus } from '../../../../../../viewModels/enums';
// import getFiles from '../../actions/Shipment.FileUpload.get-files.action';

const actionCreator = actionCreatorFactory();
const type = 'REQUEST/EDIT/START';
export const asyncActions = actionCreator.async<{}, { requestId: number; status: number }, ErrorWrapper>(type);

export default function start(requestId: number, statusId: string) {
  return async (dispatch: Dispatch<any>) => {
    async function mainAction() {
      dispatch(asyncActions.started({}));

      let newStatus = RequestStatus.Open;
      try {
        newStatus = RequestStatus[statusId];
      } catch (e) {}
      await RequestApi.changeStatus(requestId, newStatus);
      dispatch(asyncActions.done({ params: {}, result: { requestId: requestId, status: newStatus } }));
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
