import * as moment from 'moment';

export default class DateUtilities {
  /**
   * Filter, which goes through all properties (on level 1) and converts some typescript specific
   * classes to value type/string type fields so they can be sent through API
   * @param data
   * @returns {any} - object with same props as entry parameter but with normalized data
   */
  public static tryConvertAllMomentsToDates(data: any): any {
    try {
      if (!data) {
        return data;
      }

      const prepared = {};

      if (data instanceof Object) {
        const objectKeys = Object.keys(data);
        for (const key in data) {
          if (key in data && objectKeys.indexOf(key) >= 0) {
            // Convert moment to Date
            if (moment.isMoment(data[key])) {
              prepared[key] = data[key].toDate();
              continue;
            }

            // Other cases
            prepared[key] = data[key];
          }
        }
      }

      return prepared;
    } catch (exception) {
      console.error('Failed to normalize data. Still proceeding...', exception);
      return data;
    }
  }
}
