export const IS_DEV = process.env.NODE_ENV === 'development';

// This must match sub-domain name of the application:
export const APP_NAME = 'partner';

export const IS_PRODUCTION = `https://${APP_NAME}.speys.com` === window.location.origin;

export const LOCAL_STORAGE_TOKEN_KEY = 'speys_id_token';
export const LOCAL_STORAGE_PROFILE_KEY = 'speys_profile';
export const LOCAL_STORAGE_REFRESH_TOKEN_KEY = 'speys_id_refresh_token';

export const LOCAL_STORAGE_YOU_WERE_LOGGED_OFF_MESSAGE = 'speys_you_were_logged_off';
export const LOCAL_STORAGE_CURRENT_LANGUAGE = 'speys_current_language';
export const LOCAL_STORAGE_CURRENT_LANGUAGE_DEBUG = 'speys_current_language_debug';
export const LOCAL_STORAGE_LANGUAGE_STATUS = 'speys_language_status';

export const LOCAL_STORAGE_APP_SCOPE = 'speys_app_scope';
// export type AppScope = 'admin' | 'customer';
export enum AppScope {
  admin = 'admin',
  customer = 'customer',
}

export enum ObjecSimpleLogType {
  shipment = 'Shipment',
}

export enum UploadFileTypes {
  shipment = 'Shipment',
  shipmentPod = 'ShipmentPod',
}
