import * as React from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { RouteComponentProps, withRouter } from 'react-router';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

import { ProfileState } from './Profile.reducer';
import BaseFormFields, { FormFieldUtilities } from '../../../../helpers/FormFieldUtilities';
import ReusableComponentUtilities from '../../../../helpers/ReusableComponentUtilities';
import ValidationUtilities from '../../../../helpers/ValidationUtilities';
import AccountChangePasswordRequestViewModel from '../../../../viewModels/account/AccountChangePasswordRequestViewModel';
import SpeysCloudLoaderButtonComponent from '../../../../components/SpeysCloud.LoaderButton.component';
import DetailviewTopNavComponent from '../../../../components/Template/Detailview.TopNav.component';
import LocaleUtilities from '../../../../helpers/LocaleUtilities';
import PageTitleComponent from '../../../../components/Template/PageTitle.component';
import ImageBlock from '../../../../components/ImageBlock';
import TrucksImage from '../../../../assets/img/profile-bg.jpg';

class FormFields extends BaseFormFields<keyof AccountChangePasswordRequestViewModel> {
  getOldPassword = () => this.passwordField('oldPassword');
  getNewPassword = () => this.passwordField('newPassword');
  getConfirmPassword = () => this.passwordField('confirmPassword');
}

export type ProfileComponentStateProps = ProfileState;
export interface ProfileComponentDispatchProps {
  changePassword: Function;
}
export const profileFormName = 'profileForm';
type ProfileComponentProps = ProfileComponentStateProps &
  ProfileComponentDispatchProps &
  RouteComponentProps<any> &
  InjectedFormProps<any>;
class AddressComponent extends React.Component<ProfileComponentProps> {
  changePassword = (): void => {
    this.props.changePassword();
  };

  render(): JSX.Element {
    const errorWrapper = this.props.errorWrapper;
    const { formErrors, formErrorPropertyNames } = ValidationUtilities.getFormErrors(errorWrapper);
    const backToListRowComponent = ReusableComponentUtilities.getBackToListComponent('/');
    const errorRowComponent = ReusableComponentUtilities.getErrorRowComponent(errorWrapper);
    const formHelper = new FormFieldUtilities(formErrors, formErrorPropertyNames);
    const formFields = new FormFields();

    if (ValidationUtilities.isCriticalError(errorWrapper)) {
      return (
        <div>
          {errorRowComponent}
          {backToListRowComponent}
        </div>
      );
    }

    return (
      <div>
        <div className="container-fluid">
          <DetailviewTopNavComponent></DetailviewTopNavComponent>
          <PageTitleComponent title={LocaleUtilities.i18n(`user-profile-My profile`, 'profile', true)} />
          <div className="container animated fadeIn">
            <ImageBlock filename={TrucksImage} height={'45px'} />
            {errorRowComponent}
            <Row>
              <Col md={12}>
                <h3 className="text-bold sub-title">
                  {LocaleUtilities.i18n(`user-profile-Change password`, 'profile', true)}
                </h3>
              </Col>
            </Row>
            <Row className="animated bounceInRight">
              <Col md={12}>
                <div className="form p-t-20 smaller-label-font">
                  <Row>
                    <Col md={12}>
                      {formHelper.getField(
                        LocaleUtilities.i18n(`user-profile-Old password`, 'profile', true),
                        formFields.getOldPassword(),
                        'oldPassword',
                      )}

                      {formHelper.getField(
                        LocaleUtilities.i18n(`user-profile-New password`, 'profile', true),
                        formFields.getNewPassword(),
                        'newPassword',
                      )}

                      {formHelper.getField(
                        LocaleUtilities.i18n(`user-profile-Confirm password`, 'profile', true),
                        formFields.getConfirmPassword(),
                        'confirmPassword',
                      )}
                    </Col>
                    <Col md={12} className={'text-right'}>
                      <SpeysCloudLoaderButtonComponent loading={this.props.loading} handleAction={this.changePassword}>
                        {LocaleUtilities.i18n(`Update`, 'base.button', true)}
                      </SpeysCloudLoaderButtonComponent>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default reduxForm({ form: profileFormName })(withRouter(AddressComponent));
