import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Dispatch } from 'redux';
import { SortOrder } from 'speys-react-bootstrap-table';

import { ErrorWrapper, PaginatedListResult, SpeysPagination, TableData } from '../../../../../../viewModels/base';
import { AddressApi } from '../../../../../../api/Address.api';
import { DeliveryState, REDUCER_KEY__DELIVERY } from '../Shipment.reducer';
import AddressesResponseViewModel from '../../../../../../viewModels/addressBook/AddressesResponseViewModel1';
import { deliveryFormName } from '../components/Shipment.component';
import AddressesRequestViewModel from '../../../../../../viewModels/addressBook/AddressesRequestViewModel1';
import CommonUtilities from '../../../../../../helpers/CommonUtilities';

const actionCreator = actionCreatorFactory();
export const asyncActions = actionCreator.async<
  { loading: boolean },
  { tableData: TableData<AddressesResponseViewModel> },
  ErrorWrapper
>('DELIVERY/CONTACT/FETCH');
export declare type fetchAsyncType = (
  params: SpeysPagination | null,
) => (dispatch: Dispatch<AnyAction>, getState: Function) => Promise<void>;

//
// Main fetch action
//

const fetchAsync: fetchAsyncType = (currentPagination: SpeysPagination | null) => {
  return async (dispatch: Dispatch<AnyAction>, getState: Function) => {
    const state = getState();
    const formValues = state.form[deliveryFormName].values || {};
    const keyword = formValues.contactKeyword;

    async function mainAction() {
      dispatch(asyncActions.started({ loading: true }));

      if (!currentPagination) {
        currentPagination = SpeysPagination.getDefault();
      }

      const request = new AddressesRequestViewModel();
      request.baseParse(currentPagination);
      request.keyword = keyword;
      const paginatedList: PaginatedListResult<AddressesResponseViewModel> = await AddressApi.getContactList(request);

      const tableData = TableData.createTableDataFrom(
        paginatedList,
        currentPagination.page,
        currentPagination.sizePerPage,
      );

      dispatch(asyncActions.done({ params: { loading: false }, result: { tableData } }));
    }

    async function catchAction(exception: any) {
      dispatch(asyncActions.failed({ params: { loading: false }, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
};

export default fetchAsync;

//
// Sorting and pagination
//

export function onSortChange(sortName: string, sortOrder: SortOrder) {
  return async (dispatch: Dispatch<any>, getState: Function) => {
    const currentState: DeliveryState = getState()[REDUCER_KEY__DELIVERY];
    dispatch(fetchAsync({ ...currentState.contactTableData.pagination, sortName, sortOrder }));
  };
}

export function onPageChange(page: number, sizePerPage: number) {
  return async (dispatch: Dispatch<any>, getState: Function) => {
    const currentState: DeliveryState = getState()[REDUCER_KEY__DELIVERY];
    dispatch(fetchAsync({ ...currentState.contactTableData.pagination, page, sizePerPage }));
  };
}

export function onSizePerPageList(sizePerPage: number) {
  return async (dispatch: Dispatch<any>, getState: Function) => {
    const currentState: DeliveryState = getState()[REDUCER_KEY__DELIVERY];
    dispatch(fetchAsync({ ...currentState.contactTableData.pagination, sizePerPage }));
  };
}
