import actionCreatorFactory from 'typescript-fsa';
import { Dispatch } from 'redux';
import { initialize } from 'redux-form';

import { vehicleFormName } from '../../components/Vehicle.component';
import { ErrorWrapper } from '../../../../../../../viewModels/base';
import CommonUtilities from '../../../../../../../helpers/CommonUtilities';

const actionCreator = actionCreatorFactory();
export const asyncActions = actionCreator.async<{}, { deliveryId: number }, ErrorWrapper>('PARTNER/CREATE/START');

export default function start(deliveryId: number) {
  return async (dispatch: Dispatch<any>) => {
    async function mainAction() {
      dispatch(asyncActions.started({}));
      dispatch(initialize(vehicleFormName, {}));
      dispatch(asyncActions.done({ params: {}, result: { deliveryId } }));
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
