import * as React from 'react';
import DatePicker from 'react-datetime';
import { Field, GenericFieldHTMLAttributes, BaseFieldProps, WrappedFieldProps } from 'redux-form';
import * as moment from 'moment';
import $ from 'jquery';
import TimeKeeper from 'react-timekeeper';
import Modal from 'reactstrap/lib/Modal';

class XXXField extends Field<
  GenericFieldHTMLAttributes &
    BaseFieldProps & { placeHolder: string; showTimeSelect?: boolean; defaultAddHours?: number }
> {}

class SpeysTimePickerInnerComponent extends React.Component<
  WrappedFieldProps &
    GenericFieldHTMLAttributes &
    BaseFieldProps & { placeHolder: string; showTimeSelect?: boolean; defaultAddHours?: number },
  {
    alert?: any;
    displayTimePicker: boolean;
    time: any;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      displayTimePicker: false,
      time: '',
    };
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.handleHideModal = this.handleHideModal.bind(this);
    this.handleOnFocus = this.handleOnFocus.bind(this);
  }

  UNSAFE_componentWillMount(): void {
    $(document).ready(function () {
      $('.rdt.readonly').find('input').attr('readonly', 'true');
    });
  }

  handleTimeChange(newTime: any): void {
    const date = moment.utc();
    date.hours(newTime.hour24);
    date.minute(newTime.minute);
    this.props.input.value = date;
    this.props.input.onChange(date);
  }

  handleHideModal(): void {
    this.setState({
      displayTimePicker: false,
    });
  }

  handleOnFocus(): void {
    return;
    /*this.setState({
            displayTimePicker: true
        })*/
  }

  render(): JSX.Element {
    const { input, className } = this.props;
    const { value, onChange } = input;

    const momentDate = !value ? undefined : moment.isMoment(value) ? value : moment.utc(value);
    return (
      <div>
        <Modal isOpen={this.state.displayTimePicker} toggle={this.handleHideModal} size={'xs'}>
          <TimeKeeper time={this.state.time.hour + ':' + this.state.time.minute} onChange={this.handleTimeChange} />
        </Modal>
        <div className="input-group">
          <DatePicker
            utc={true}
            dateFormat={false}
            timeFormat={'HH:mm'}
            className={className}
            value={momentDate}
            onChange={(date: string) => {
              const momentDate2 = moment(date);
              if (moment(date).isValid()) {
                if (
                  momentDate2.hour() === 0 &&
                  momentDate2.minute() === 0 &&
                  this.props.defaultAddHours !== undefined
                ) {
                  momentDate2.hours(this.props.defaultAddHours);
                }
                onChange(momentDate2);
                this.setState({
                  time: {
                    hour: momentDate2.hour(),
                    minute: momentDate2.minute(),
                  },
                });
              } else {
                onChange(null);
                this.setState({
                  time: '',
                });
              }
            }}
          />
          <div className="input-group-append">
            <span className="input-group-text pointer" onClick={(event1) => this.handleOnFocus()}>
              <i className="icon-clock"></i>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

class SpeysTimePickerComponentProps {
  name: string;
  placeHolder: string;
  className: string;
  showTimeSelect?: boolean;
  defaultAddHours?: number;
}

export class SpeysCloudTimepicker2FieldComponent extends React.Component<SpeysTimePickerComponentProps> {
  render() {
    const { name, placeHolder, className, showTimeSelect, defaultAddHours } = this.props;
    return (
      <XXXField
        name={name}
        className={className}
        component={SpeysTimePickerInnerComponent}
        placeHolder={placeHolder}
        showTimeSelect={showTimeSelect}
        defaultAddHours={defaultAddHours}
      />
    );
  }
}
