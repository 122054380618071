import actionCreatorFactory from 'typescript-fsa';
import { Dispatch } from 'redux';
import { toastr } from 'react-redux-toastr';
import { setLocale } from 'react-redux-i18n';

import { ErrorWrapper } from '../../viewModels/base';
import CommonUtilities from '../../helpers/CommonUtilities';
import { default as LocaleUtils, ValidFlag, ValidLanguage } from '../../helpers/LocaleUtilities';

const actionCreator = actionCreatorFactory();
export const setFlag = actionCreator<ValidFlag>('APP/SET-FLAG');
export const asyncActions = actionCreator.async<{}, { flag: ValidFlag }, ErrorWrapper>('APP/CHANGE_LANGUAGE');

export default function submit(language: ValidLanguage) {
  return async (dispatch: Dispatch<any>, getState: Function) => {
    async function mainAction() {
      dispatch(asyncActions.started({}));
      LocaleUtils.setCurrentLanguage(language);
      if (LocaleUtils.getDebug()) {
        location.reload();
      } else {
        dispatch(setLocale(language));
        const flag = LocaleUtils.getFlagByLanguage(language);
        dispatch(asyncActions.done({ params: {}, result: { flag } }));
      }
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
      toastr.error('Error', 'Error has occurred');
      CommonUtilities.scrollToTop();
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
