import * as React from 'react';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import LocaleUtilities from '../../helpers/LocaleUtilities';
import Draggable from 'react-draggable'; // The default

// http://djorg83.github.io/react-bootstrap-sweetalert/
// styles
// import sweetAlertStyles from './styles/SweetAlertStyles';
import Button from 'reactstrap/lib/Button';
// import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';
// import SpeysSpinner from '../SpeysCloud.Loading.component';

export interface SweetAlertComponentStateProps {
  // 0neOf(['default', 'info', 'success', 'warning', 'danger', 'error', 'input', 'custom'])
  type: string;
  title: any;
  onCancel?: Function;
  onConfirm?: Function;
  confirmBtnText?: any;
  confirmBtnBsStyle?: any;
  confirmBtnCssClass?: string;
  confirmBtnStyle?: object;
  cancelBtnText?: any;
  cancelBtnBsStyle?: any;
  cancelBtnCssClass?: string;
  cancelBtnStyle?: object;
  btnSize?: string;
  customIcon?: any;
  show?: boolean;
  required?: boolean;
  placeholder?: string;
  validationMsg?: string;
  validationRegex?: object;
  defaultValue?: string;
  inputType?: string;
  style?: object;
  customClass?: string;
  showConfirm?: boolean;
  showCancel?: boolean;
  allowEscape?: boolean;
  closeOnClickOutside?: boolean;
  closeOnCancelButton?: boolean;
  hideOverlay?: boolean;
  disabled?: boolean;
  focusConfirmBtn?: boolean;
  beforeMount?: () => void;
  afterMount?: () => void;
  beforeUpdate?: (nextProps: any, nextState: any) => void;
  afterUpdate?: (nextProps: any, nextState: any) => void;
  beforeUnmount?: () => void;
  timeout?: boolean;
  biggerModal?: boolean;
  dimmarIsActive?: boolean;
}

const defaultRegex = /^.+$/;
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// let style = sweetAlertStyles.sweetAlert;

export default class SweetAlertComponent extends React.Component<
  SweetAlertComponentStateProps,
  {
    type: string;
    showModal: boolean;
    focusConfirmBtn?: boolean;
    inputValue?: string;
    showValidationMessage?: boolean;
    timer?: any;
    biggerModal?: boolean;
  }
> {
  static defaultProps: SweetAlertComponentStateProps = {
    type: 'default',
    title: '',
    biggerModal: false,
    dimmarIsActive: false,
    allowEscape: true,
    closeOnClickOutside: true,
    inputType: 'text',
    customClass: '',
    hideOverlay: false,
    show: true,
    closeOnCancelButton: true,
    required: true,
    disabled: false,
    focusConfirmBtn: true,
    confirmBtnText: LocaleUtilities.i18n('modal-button-Continue', 'confirm.modal'),
    cancelBtnText: LocaleUtilities.i18n('modal-button-Cancel', 'confirm.modal'),
    timeout: false,
  };

  constructor(props: any) {
    super(props);
  }

  UNSAFE_componentWillMount(): void {
    if (this.props.beforeMount !== undefined) {
      this.props.beforeMount();
    }
    this.setState({
      type: 'default',
      showModal: true,
    });
    this.setStateFromProps(this.props);
  }

  handleTimeout(props: any): void {
    props = props || this.props;

    if (this.state.timer) {
      clearTimeout(this.state.timer);
    }
  }

  setStateFromProps = (props: any) => {
    props = props || this.props;
    const type = this.getTypeFromProps(props);
    this.setState({
      type,
      focusConfirmBtn: props.focusConfirmBtn && type !== 'input',
    });
  };

  getTypeFromProps = (props: any) => {
    if (props.type) return props.type;
    if (props.info) return 'info';
    if (props.success) return 'success';
    if (props.warning) return 'warning';
    if (props.danger || props.error) return 'danger';
    if (props.input) return 'input';
    if (props.custom) return 'custom';
    return this.state.type;
  };

  unsupportedProp = (oldProp: any, message: any) => {
    try {
      console.warn(`react-bootstrap-sweetalert: Unsupported prop '${oldProp}'. Please ${message}`);
    } catch (e) {}
  };

  onConfirm = (): void => {
    const { onConfirm } = this.props;
    if (this.state.type === 'input') {
      if (this.isValidInput()) {
        onConfirm && onConfirm(this.state.inputValue);
      } else {
        this.setState({
          showValidationMessage: true,
        });
      }
    } else {
      onConfirm && onConfirm();
      this.onCancel();
    }
  };

  onCancel = (): void => {
    const { onConfirm, onCancel } = this.props;
    if (this.state.type === 'input' && !this.props.closeOnCancelButton) {
      if (this.isValidInput()) {
        onConfirm && onConfirm(this.state.inputValue);
      } else {
        this.setState({
          showValidationMessage: true,
        });
      }
    } else {
      onCancel && onCancel();
      this.setState({
        showModal: false,
      });
    }
  };

  onInputKeyDown = (e: any): void => {
    if (e.keyCode === 13) {
      if (this.props.onConfirm) {
        this.onConfirm();
        e.stopPropagation();
      }
    }
  };

  getIcon = (): void => {
    <span></span>;
  };

  onChangeInput = (e: any): void => {
    this.setState({
      inputValue: e.target.value,
      showValidationMessage: false,
    });
  };

  isValidInput = (): object | boolean => {
    if (!this.props.required) {
      return true;
    }
    const regex = this.props.validationRegex || (this.props.inputType === 'email' ? emailRegex : defaultRegex);
    return regex;
  };

  onKeyDown = (e: any): void => {
    if (e.keyCode === 27) {
      if (this.props.allowEscape && this.props.onCancel) {
        this.props.onCancel();
        e.stopPropagation();
      }
    }
  };

  onClickOutside = (): void => {
    if (this.props.closeOnClickOutside && this.props.onCancel) {
      this.props.onCancel();
    }
  };

  render(): JSX.Element {
    const {} = this.props;

    let headerClass = 'default';
    let modalClass = 'no-border';
    if (this.props.biggerModal !== undefined && this.props.biggerModal) {
      modalClass = 'no-border modal-lg';
    }
    switch (this.props.type) {
      case 'info':
        headerClass = 'bg-info';
        break;
      case 'input':
        headerClass = 'bg-info';
        break;
      case 'danger':
        headerClass = 'bg-danger';
        break;
      case 'warning':
        headerClass = 'bg-warning';
        break;
      case 'success':
        headerClass = 'bg-success';
        break;
      default:
        headerClass = 'bg-default';
        break;
    }

    return (
      <div>
        {!this.props.hideOverlay && <div onClick={this.onClickOutside}></div>}
        <Draggable>
          <Modal
            style={{ zIndex: 9999 }}
            isOpen={this.state.showModal}
            toggle={this.onCancel}
            className={modalClass}
            backdrop={!this.props.closeOnClickOutside ? 'static' : true}
          >
            {/*<Dimmer active={this.props.dimmarIsActive}>
                            <SpeysSpinner isLoading={true} error={[]}/>
                        </Dimmer>*/}
            <ModalHeader className={headerClass} toggle={this.onCancel}>
              <span className={'text-white'}>{this.props.title}</span>
            </ModalHeader>
            <ModalBody>{this.props.children}</ModalBody>
            <ModalFooter>
              <Button className={'btn-sm'} color="secondary" onClick={this.onCancel}>
                {this.props.cancelBtnText}
              </Button>{' '}
              <Button className={'btn-sm'} color="success" onClick={this.onConfirm}>
                {this.props.confirmBtnText}
              </Button>
            </ModalFooter>
          </Modal>
        </Draggable>
        {/*this.state.type === 'input' && (
                        <Input
                            {...this.props}
                            {...this.state}
                            type={this.state.type}
                            onInputKeyDown={this.onInputKeyDown}
                            onChangeInput={this.onChangeInput}
                        />
                    )*/}

        {/*this.state.showValidationMessage && <ValidationMessage {...this.props} />*/}

        {/*<Buttons
                        {...this.props}
                        type={this.state.type}
                        onConfirm={this.onConfirm}
                        focusConfirmBtn={this.state.focusConfirmBtn}
                    />*/}
      </div>
    );
  }
}
