import { Moment } from 'moment';

export class ShipmentContact {
  tenantId: number;
  id: number;
  country?: string;
  postCode?: string;
  city?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  companyName?: string;
  contactPersonName?: string;
  phone?: string;
  email?: string;
  vat?: string;
}

export class ShipmentDetailsRow {
  id: number;
  quantityId: number;
  typeId?: number;
  length?: number;
  width?: number;
  height?: number;
  weight?: number;
  totalWeight?: number;
  productDescription: string;
  productAdditionalInfo: string;
  dangerousGoods?: boolean;
  dangerousGoodsInfo?: any;
  spxNumber?: string;
  spxVal?: boolean;
  stackable?: boolean;
  packageId: string;
  classVal: string;
  volume: number;
  remarks: string;
  un: string;
  coldFrozen?: boolean;
  temperature?: number;
  tracker?: number;
  lastTrackerLocation: string;
  lastTrackerLocationTime?: string;
}

export default class ShipmentResponseViewModel {
  number?: string;
  type?: string;
  reference?: string;
  loadingReference?: string;
  status: number;
  deliveredDate?: Date | Moment;
  deliveredSignBy: number;
  transportCompany?: string;
  totalEur?: number;
  originAddress?: string;
  destinationAddress?: string;
  shipmentDetailsRows?: Array<ShipmentDetailsRow>;
  sender?: ShipmentContact;
  senderAlternative?: ShipmentContact;
  useSernderAlternative?: boolean;
  receiver?: ShipmentContact;
  receiverAlternative?: ShipmentContact;
  useReceiverAlternative?: boolean;

  earliestPickupDate?: Date | Moment;
  earliestPickupTime?: string;
  latestPickupDate?: Date | Moment;
  latestPickupTime?: string;
  earliestArrivalDate?: Date | Moment;
  earliestArrivalTime?: string;
  latestArrivalDate?: Date | Moment;
  latestArrivalTime?: string;
  otherInstructions?: string;
  printableInformation?: string;
}
