import { isType, Action } from 'typescript-fsa';
import { ErrorWrapper } from '../../../../viewModels/base';
import DriverViewResponseViewModel, { Shipment } from 'viewModels/driverView/DriverViewResponseViewModel';
import { asyncActions as driverViewActions } from './actions/DriverView.fetch.action';
import { asyncActions as changeShipmentStatusActions } from './actions/DriverView.changeShipmentStatus.action';
import { asyncActions as uploadPodFilesActions } from './actions/DriverView.uploadPodFiles.action';
import { asyncActions as networkChangedActions } from '../../../actions/App.networkChanged.action';
import produce from 'immer';
import get from 'lodash/get';
import { DeliveryStatus } from 'viewModels/enums';

export class DriverViewState {
  type?: string;
  driverView?: DriverViewResponseViewModel;
  errorWrapper?: ErrorWrapper;
  loading: boolean;
  changeStatusLoading: boolean;
  isWaitingForApi: boolean;
}

const defaultState: DriverViewState = {
  driverView: undefined,
  errorWrapper: undefined,
  loading: false,
  changeStatusLoading: false,
  isWaitingForApi: false,
};

export const REDUCER_KEY__DRIVER_VIEW = 'driver_view';
export default (state: DriverViewState = defaultState, action: Action<any>): DriverViewState => {
  const { type, payload } = action;

  if (isType(action, driverViewActions.started)) {
    return { ...state, type, loading: true, errorWrapper: undefined };
  } else if (isType(action, driverViewActions.done)) {
    return { ...state, type, driverView: payload.result.driverView, loading: false, errorWrapper: undefined };
  } else if (isType(action, driverViewActions.failed)) {
    return { ...state, type, errorWrapper: payload.error, loading: false };
  } else if (isType(action, changeShipmentStatusActions.started)) {
    return { ...state, type, changeStatusLoading: true, errorWrapper: undefined };
  } else if (isType(action, changeShipmentStatusActions.done)) {
    const { shipmentId, status, date, time } = payload.result;

    const newState = produce(state, (draftState) => {
      const shipments: Shipment[] = get(draftState, 'driverView.shippingOrder.shipments', []);
      const updatedShipment = shipments.find(({ id }) => id === shipmentId);
      if (updatedShipment) {
        updatedShipment.status = status;
        if (status < DeliveryStatus.Delivered) {
          updatedShipment.collection = { ...updatedShipment.collection, date, time };
        } else {
          updatedShipment.delivery = { ...updatedShipment.delivery, date, time };
        }
      }
    });

    return { ...newState, type, changeStatusLoading: false, errorWrapper: undefined };
  } else if (isType(action, changeShipmentStatusActions.failed)) {
    return { ...state, type, errorWrapper: payload.error, changeStatusLoading: false };
  } else if (isType(action, uploadPodFilesActions.started)) {
    return { ...state, type, isWaitingForApi: true, errorWrapper: undefined };
  } else if (isType(action, uploadPodFilesActions.done)) {
    // didn't refactor this copy-paste assuming that we'll drop redux asap.
    const { shipmentId, pod } = payload.result;

    const newState = produce(state, (draftState) => {
      const shipments: Shipment[] = get(draftState, 'driverView.shippingOrder.shipments', []);
      const updatedShipment = shipments.find(({ id }) => id === shipmentId);
      if (updatedShipment) {
        updatedShipment.pod = pod;
      }
    });

    return { ...newState, type, isWaitingForApi: false, errorWrapper: undefined };
  } else if (isType(action, uploadPodFilesActions.failed)) {
    return { ...state, type, isWaitingForApi: false, errorWrapper: payload.error };
  }
  // Network changed
  if (isType(action, networkChangedActions.done)) {
    return { ...state, errorWrapper: payload.result.online ? undefined : state.errorWrapper };
  }

  return state;
};
