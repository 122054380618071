import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { asyncActions as fetchAsyncActions } from './actions/Vehicles.fetch.action';
import { asyncActions as deleteSubmitActions } from './actions/Vehicles.delete.submit.action';
import { deleteCancel, deleteStart } from './actions';
import VehiclesResponseViewModel from '../../../../../viewModels/vehicle/VehiclesResponseViewModel';
import { ErrorWrapper, TableData } from '../../../../../viewModels/base';

export const REDUCER_KEY__VEHICLES = 'vehicles';
export interface VehiclesReducerState {
  type?: string;
  tableData: TableData<VehiclesResponseViewModel>;
  loading: boolean;
  confirmLoading: boolean;
  pendingDeleteId: number;
  error?: ErrorWrapper;
  notification?: string;
}

const defaultState: VehiclesReducerState = {
  loading: false,
  confirmLoading: false,
  pendingDeleteId: 0,
  tableData: TableData.getDefault<VehiclesResponseViewModel>(),
};
export default (state: VehiclesReducerState = defaultState, action: Action): VehiclesReducerState => {
  // Fetch
  if (isType(action, fetchAsyncActions.started)) {
    return { ...state, type: action.type, loading: true };
  }
  if (isType(action, fetchAsyncActions.done)) {
    return {
      ...state,
      type: action.type,
      loading: false,
      tableData: action.payload.result.tableData,
    };
  }
  if (isType(action, fetchAsyncActions.failed)) {
    return { ...state, type: action.type, loading: false, error: action.payload.error };
  }

  // Delete actions
  if (isType(action, deleteCancel)) {
    return { ...state, type: action.type, pendingDeleteId: 0 };
  }
  if (isType(action, deleteStart)) {
    return { ...state, type: action.type, pendingDeleteId: action.payload };
  }
  if (isType(action, deleteSubmitActions.started)) {
    return { ...state, type: action.type, confirmLoading: true };
  }
  if (isType(action, deleteSubmitActions.done)) {
    return { ...state, type: action.type, confirmLoading: false, pendingDeleteId: 0 };
  }
  if (isType(action, deleteSubmitActions.failed)) {
    return { ...state, type: action.type, confirmLoading: false };
  }

  return state;
};
