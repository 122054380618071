import { PaginatedSearchBaseOptionsResult } from '../base';
import RouterUtilities from '../../helpers/RouterUtilities';

export default class RequestRequestViewModel extends PaginatedSearchBaseOptionsResult {
  keyword: string;

  public parse(location: Location) {
    const obj = RouterUtilities.locationSearchStringParse(location);
    if (!obj) {
      return;
    }

    super.baseParse(obj);
    if ('keyword' in obj && typeof obj.keyword === 'string') {
      this.keyword = obj.keyword;
    }
  }
}
