/* eslint-disable @typescript-eslint/camelcase */
import { UserModelRoles } from '../helpers/AuthUtils/AuthUtils';

// sidebar nav links
export default {
  adminGeneral: [
    {
      menu_title: 'Dashboard',
      menu_icon: 'zmdi zmdi-view-dashboard',
      path: '/dashboard',
      allowedRoles: [],
    },
    {
      menu_title: 'Shipments',
      menu_icon: 'zmdi zmdi-assignment',
      path: '/shipments',
      allowedRoles: [],
    },
    {
      menu_title: 'Request',
      menu_icon: 'zmdi zmdi-code-setting',
      path: '/partner-portal/request',
      allowedRoles: [],
    },
    /*
        {
            "menu_title": "Address book",
            "menu_icon": "zmdi ti-book",
            "path": "/address_book",
            "allowedRoles":[],
        },
        */
    {
      menu_title: 'Vehicle',
      menu_icon: 'zmdi ti-car',
      path: '/partner-portal/vehicle',
      allowedRoles: [],
    },
    {
      menu_title: 'Drivers',
      menu_icon: 'zmdi ti-truck',
      path: '/partner-portal/driver',
      allowedRoles: [],
    },
  ],
  adminSettings: [
    {
      menu_title: 'My company',
      menu_icon: 'zmdi zmdi-city',
      path: '/company',
      allowedRoles: [UserModelRoles.companyAdmin, UserModelRoles.companyUser],
    },
  ],
};
