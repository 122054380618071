/**
 * User Block Component
 */
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Badge } from 'reactstrap';
import { NotificationManager } from 'react-notifications';
import { routePaths } from '../../constants/api.constants';

// redux action
//import { logoutUserFromFirebase } from '../../app/actions/template';
import { logout } from '../../app/actions';

// intl messages
import IntlMessages from '../../util/IntlMessages';
import LocaleUtilities from '../../helpers/LocaleUtilities';

class UserBlock extends Component {
  state = {
    userDropdownMenu: false,
    isSupportModal: false,
  };

  /**
   * Logout User
   */
  logoutUser() {
    this.props.logout();
  }

  /**
   * Toggle User Dropdown Menu
   */
  toggleUserDropdownMenu() {
    this.setState({ userDropdownMenu: !this.state.userDropdownMenu });
  }

  /**
   * Open Support Modal
   */
  openSupportModal() {
    this.setState({ isSupportModal: true });
  }

  /**
   * On Close Support Page
   */
  onCloseSupportPage() {
    this.setState({ isSupportModal: false });
  }

  /**
   * On Submit Support Page
   */
  onSubmitSupport() {
    this.setState({ isSupportModal: false });
    NotificationManager.success('Message has been sent successfully!');
  }

  render() {
    const { app } = this.props;
    const profilePath = routePaths.profile;
    return (
      <div className="top-sidebar">
        <div className="sidebar-user-block">
          <div className="company-info text-center">
            <p className="font-weight-bold">{app.user.company ? app.user.company : null}</p>
          </div>
          <Dropdown
            isOpen={this.state.userDropdownMenu}
            toggle={() => this.toggleUserDropdownMenu()}
            className="rct-dropdown"
          >
            <DropdownToggle tag="div" className="text-center align-items-center">
              <div className="user-profile hidden">
                <img
                  src={require('../../assets/avatars/user-40.jpg')}
                  alt="user profile"
                  className="img-fluid rounded-circle"
                  width="50"
                  height="100"
                />
              </div>
              <div className="user-info">
                <span className="user-name ml-4"> {app.user.name ? app.user.name : 'Administrator'}</span>
                <i className="zmdi zmdi-chevron-down dropdown-icon ml-5"></i>
              </div>
            </DropdownToggle>
            <DropdownMenu>
              <ul className="list-unstyled mb-0">
                <li className="p-15 border-bottom user-profile-top bg-primary rounded-top">
                  <p className="text-white mb-0 fs-14">{app.user.name}</p>
                  <span className="text-white fs-14">{app.user.username}</span>
                </li>
                <li>
                  <Link to={profilePath}>
                    <i className="zmdi zmdi-account text-primary mr-3"></i>
                    <span>{LocaleUtilities.i18n(`sidebar-user-menu-Profile`, 'sidebar.user', true)}</span>
                  </Link>
                </li>
                {/*<li>
									<Link to={{
										pathname: '/app/users/user-profile-1',
										state: { activeTab: 2 }
									}}>
										<i className="zmdi zmdi-comment-text-alt text-success mr-3"></i>
										<IntlMessages id="widgets.messages" />
										<Badge color="danger" className="pull-right">3</Badge>
									</Link>
								</li>
								<li>
									<Link to="/app/pages/feedback">
										<i className="zmdi zmdi-edit text-warning mr-3"></i>
										<IntlMessages id="sidebar.feedback" />
										<Badge color="info" className="pull-right">1</Badge>
									</Link>
								</li>*/}
                <li className="border-top">
                  <a href="#!" onClick={() => this.logoutUser()}>
                    <i className="zmdi zmdi-power text-danger mr-3"></i>
                    <span>{LocaleUtilities.i18n(`sidebar-user-menu-Log out`, 'sidebar.user', true)}</span>
                  </a>
                </li>
              </ul>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    );
  }
}

// map state to props
const mapStateToProps = ({ settings, app }) => {
  return { settings, app };
};

export default connect(mapStateToProps, {
  logout,
})(UserBlock);
