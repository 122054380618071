import * as React from 'react';
import Select, { Option } from 'react-select';
import { Field, BaseFieldProps, WrappedFieldProps } from 'redux-form';
import LocaleUtilities from '../../helpers/LocaleUtilities';

export type GenericSelectOptions<T> = { label: string; value: T }[];
type SelectFieldHTMLAttributes<T = string> = {
  options: GenericSelectOptions<T>;
  fn?: Function;
  fnUseValue?: boolean;
} & BaseFieldProps;

export class SpeysSelectFieldInner<T> extends Field<SelectFieldHTMLAttributes<T>> {}

class SpeysSelectStringInnerFieldComponent extends React.Component<WrappedFieldProps & SelectFieldHTMLAttributes> {
  render() {
    const selectString = LocaleUtilities.i18n('form-component-first-option-Select', 'form', true).toString();
    return (
      <Select
        menuContainerStyle={{ zIndex: 999 }}
        placeholder={selectString}
        options={this.props.options}
        value={this.props.input.value}
        onChange={(selectedOption: Option<string>) => {
          if (this.props.fn && (this.props.fnUseValue === null || this.props.fnUseValue === undefined)) {
            this.props.fn();
          }
          if (this.props.fn && this.props.fnUseValue !== null && this.props.fnUseValue) {
            this.props.fn(!selectedOption ? null : selectedOption.value);
          }
          return this.props.input.onChange(!selectedOption ? null : selectedOption.value);
        }}
      />
    );
  }
}

type Props = {
  name: string;
  options: GenericSelectOptions<string>;
  fn?: Function;
  label?: any;
  customClass?: string;
  fnUseValue?: boolean;
};

export class SpeysSelectStringField extends React.Component<Props> {
  render() {
    const { name, options, customClass } = this.props;
    return (
      <div className={customClass !== undefined && customClass.length ? customClass + ' form-group' : 'form-group'}>
        {this.props.label !== undefined ? <label>{this.props.label}</label> : null}
        <SpeysSelectFieldInner
          name={name}
          component={SpeysSelectStringInnerFieldComponent}
          options={options}
          fn={this.props.fn}
          fnUseValue={this.props.fnUseValue}
        />
      </div>
    );
  }
}
