import * as React from 'react';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Modal from 'reactstrap/lib/Modal';
import Button from 'reactstrap/lib/Button';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { RouteComponentProps, withRouter } from 'react-router';

import { VehiclesReducerState } from './Vehicles.reducer';
import SearchFieldsComponent from './Vehicles.SearchFields.component';
import PartnersTableComponent from './Vehicles.Table.component';
import { fetchAsyncType } from './actions/Vehicles.fetch.action';
import CompaniesRequestViewModel from '../../../../../viewModels/company/CompaniesRequestViewModel';
import LocaleUtilities from '../../../../../helpers/LocaleUtilities';
import DetailviewTopNavComponent from '../../../../../components/Template/Detailview.TopNav.component';
import PageTitleComponent from '../../../../../components/Template/PageTitle.component';
import SpeysContent from '../../../../../components/SpeysContent';
import { routePaths } from '../../../../../constants/api.constants';
import { Link } from 'react-router-dom';

export type VehiclesComponentStateProps = VehiclesReducerState;
export interface VehiclesComponentDispatchProps {
  fetchAsync: fetchAsyncType;
  deleteCancel: Function;
  deleteStart: Function;
  deleteSubmit: Function;
  downloadPdf: Function;

  onSortChange: Function;
  onPageChange: Function;
  onSizePerPageList: Function;
}

type VehicleListComponentProps = VehiclesComponentStateProps &
  VehiclesComponentDispatchProps &
  RouteComponentProps<any> &
  InjectedFormProps<any>;

export const vehiclesFormName = 'vehiclesForm';
class VehiclesComponent extends React.Component<VehicleListComponentProps> {
  componentDidMount() {
    const request = new CompaniesRequestViewModel();
    request.parse(location);
    this.props.fetchAsync(request, true, false);
    this.handleEditClick = this.handleEditClick.bind(this);
  }

  handleSearch = () => {
    this.props.fetchAsync(null, false, true);
  };
  handleDeleteStart = (id: number) => {
    this.props.deleteStart(id);
  };
  handleDeleteCancel = () => {
    this.props.deleteCancel();
  };
  handleDeleteSubmit = () => {
    this.props.deleteSubmit();
  };
  handleDownloadPdf = (id: number) => {
    this.props.downloadPdf(id);
  };

  handleEditClick = (id: number) => {
    this.props.history.push(routePaths.vehicles.editId(id));
  };

  render() {
    const { data, pagination } = this.props.tableData;
    const { pendingDeleteId, confirmLoading } = this.props;
    const { onSortChange, onPageChange, onSizePerPageList } = this.props;

    return (
      <div>
        <DetailviewTopNavComponent>
          <div className="text-right">
            <Link
              to={routePaths.vehicles.new}
              className="btn waves-effect waves-light btn-outline-primary btn-sm text-white"
            >
              + {LocaleUtilities.i18n('Add new vehicle', 'vehicle')}
            </Link>
          </div>
        </DetailviewTopNavComponent>
        <SpeysContent styleName={'ct-table'}>
          <SpeysContent.Body>
            <div className="container-fluid">
              <PageTitleComponent title={LocaleUtilities.i18n(`sidebar-menu-item-Vehicles`, 'sidebar.menu', true)} />
              <Row className={'mt-15'}>
                <Col md={12}>
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex">
                        <div>
                          <h4 className="card-title">
                            <span className="lstick" />
                            {LocaleUtilities.i18n('Filter')}
                          </h4>
                        </div>
                      </div>
                      <SearchFieldsComponent handleSearch={() => this.handleSearch()} />
                    </div>
                  </div>
                </Col>
              </Row>

              {/* margin-top 150px is temporary fix for datepicker and status selector */}
              <div className=" animated fadeIn speys-data-table-container">
                <Row className="animated bounceInRight mt-15">
                  <Col md={12}>
                    <PartnersTableComponent
                      data={data}
                      total={pagination.total}
                      page={pagination.page}
                      sizePerPage={pagination.sizePerPage}
                      onSortChange={onSortChange}
                      onPageChange={onPageChange}
                      onSizePerPageList={onSizePerPageList}
                      handleDeleteStart={this.handleDeleteStart}
                      handleDownloadPdf={this.handleDownloadPdf}
                      handleEditClick={this.handleEditClick}
                      loading={this.props.loading}
                    />
                  </Col>
                </Row>
              </div>
            </div>

            <Modal isOpen={pendingDeleteId > 0} toggle={this.handleDeleteCancel}>
              <ModalHeader toggle={this.handleDeleteCancel}>
                {LocaleUtilities.i18n('Deliveries-Label-Deleting')}
              </ModalHeader>
              <ModalBody>{LocaleUtilities.i18n('Deliveries-Modal-DoYouWantToDeleteThis')}</ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={this.handleDeleteSubmit} disabled={confirmLoading}>
                  {LocaleUtilities.i18n('Deliveries-Modal-Button-Ok')}
                </Button>{' '}
                <Button color="secondary" onClick={this.handleDeleteCancel} disabled={confirmLoading}>
                  {LocaleUtilities.i18n('Deliveries-Modal-Button-Cancel')}
                </Button>
              </ModalFooter>
            </Modal>
          </SpeysContent.Body>
        </SpeysContent>
      </div>
    );
  }
}

export default reduxForm({ form: vehiclesFormName })(withRouter(VehiclesComponent));
