import * as React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
interface DashboardBlockComponentProps {
  title: any;
  icon: string;
  dangerousHtml?: string;
  expanded?: boolean;
  key: number;
  panelKey: number;
  onClick?: Function;
}

class ShipmentExpandableBlockComponent extends React.Component<
  DashboardBlockComponentProps,
  {
    expanded: null;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      expanded: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (panel) => (event, expanded) => {
    this.setState({
      expanded: expanded,
    });
    if (this.props.onClick !== undefined) {
      this.props.onClick(this.props.panelKey);
    }
  };

  toggleHandled(): boolean {
    if (this.state.expanded !== undefined && this.state.expanded === true) {
      return true;
    }
    if (this.props.expanded !== undefined && this.props.expanded === true) {
      return true;
    }
    return false;
  }

  render() {
    let classNames = 'expandable-wrapper-with-form';
    if (
      this.state.expanded === true ||
      (this.state.expanded == null && this.props.expanded !== undefined && this.props.expanded === true)
    ) {
      classNames = 'expandable-wrapper-with-form collapse-in';
    }

    return (
      <div className={'speys-expandable-wrapper'}>
        <ExpansionPanel
          defaultExpanded={this.toggleHandled()}
          className={classNames}
          onChange={this.handleChange(this)}
        >
          <ExpansionPanelSummary
            expandIcon={<i className="zmdi zmdi-chevron-down"></i>}
            className={'expandable-header'}
          >
            <i className={this.props.icon} />
            <span className={'ml-5'}>{this.props.title}</span>
            {this.props.dangerousHtml !== undefined && (
              <div dangerouslySetInnerHTML={{ __html: this.props.dangerousHtml.toString() }}></div>
            )}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={'expandable-content'}>
            <div className="row">
              <div className="col-md-12">{this.props.children !== undefined ? this.props.children : ''}</div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

export default ShipmentExpandableBlockComponent;
