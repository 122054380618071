import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Dispatch } from 'redux';
import { companyFormName } from '../../components/Company.component';
import { ErrorWrapper } from '../../../../../../../viewModels/base';

import { CompanyState, REDUCER_KEY__COMPANY } from '../../Company.reducer';
// import { CompanyApi } from '../../../../../../../api/Company.api';
import { toastr } from 'react-redux-toastr';

import CommonUtilities from '../../../../../../../helpers/CommonUtilities';

const actionCreator = actionCreatorFactory();
const type = 'COMPANY/EDIT/SUBMIT';
export const asyncActions = actionCreator.async<{}, {}, ErrorWrapper>(type);

export default function submit(onSuccess: Function) {
  return async (dispatch: Dispatch<AnyAction>, getState: Function) => {
    async function mainAction() {
      dispatch(asyncActions.started({ confirmLoading: true }));
      const state = getState();
      const currentState: CompanyState = state[REDUCER_KEY__COMPANY];
      const formValues = state.form[companyFormName].values || {};
      if (!formValues) {
        throw new ErrorWrapper('Nothing was filled');
      }
      if (!currentState.companyId) {
        throw new ErrorWrapper('Id is missing');
      }
      //console.log(currentState.unitGroupId, formValues);
      // await CompanyApi.update(currentState.companyId, formValues);
      dispatch(asyncActions.done({ params: { confirmLoading: false }, result: {} }));
      toastr.success('Success', 'Item was successfully updated');
      if (onSuccess) {
        onSuccess();
      }
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
      toastr.error('Error', 'Item was not updated');
      CommonUtilities.scrollToTop();
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
