/* eslint-disable @typescript-eslint/camelcase */
import * as $ from 'jquery';
import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Dispatch } from 'redux';
import { reset } from 'redux-form';

import AuthUtilities, { NullableLocalStorageUserModel } from '../../helpers/AuthUtils/AuthUtils';
import AccountApi from '../../api/Account.api';
import { ErrorWrapper } from '../../viewModels/base';
import LoginUserRequest1ViewModel from '../../viewModels/account/loginUserRequest1ViewModel';
import CommonUtilities from '../../helpers/CommonUtilities';
import { loginFormName } from '../routes/Login/Login.form.component';
import { AppScope } from '../../constants/base.constants';

const actionCreator = actionCreatorFactory();
export const asyncActions = actionCreator.async<{}, { user: NullableLocalStorageUserModel }, ErrorWrapper>(
  'APP/LOGIN/SUBMIT',
);

export default function submit() {
  return async (dispatch: Dispatch<AnyAction>, getState: any) => {
    const formValues: LoginUserRequest1ViewModel = getState().form[loginFormName].values || {};

    async function mainAction(): Promise<void> {
      formValues.grant_type = 'password';
      formValues.scope = 'openid offline_access'; // for refresh token
      const { access_token, expires_in, refresh_token } = await AccountApi.loginUser(formValues);
      const user = await AccountApi.getCurrentUser(access_token);

      const dateData = new AuthUtilities().calculateExpirationDateAndHalfExpirationDate(expires_in);
      user.expiresOn = dateData.expiresOn;
      user.halfTimeExpiresOn = dateData.halfTimeExpiresOn;
      // console.info('Expires on', user.expiresOn);
      // console.info('Half time expires on', user.halfTimeExpiresOn);

      new AuthUtilities().setToken(access_token, user, refresh_token);
      dispatch(reset(loginFormName));
      AuthUtilities.setAppScope(AppScope[formValues.userCode || '']);
      dispatch(asyncActions.done({ params: {}, result: { user } }));
      $('body').removeClass(' bg-full-screen-image');
    }

    async function catchAction(exception: any): Promise<void> {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    dispatch(asyncActions.started({}));
    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
