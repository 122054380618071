import * as React from 'react';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment';
import { BootstrapTable, Options, SortOrder, TableHeaderColumn } from 'speys-react-bootstrap-table';
import MenuItem from '@material-ui/core/MenuItem';

import VehiclesResponseViewModel from '../../../../../viewModels/vehicle/VehiclesResponseViewModel';
import LocaleUtilities from '../../../../../helpers/LocaleUtilities';
import { routePaths } from '../../../../../constants/api.constants';
import CommonUtilities from '../../../../../helpers/CommonUtilities';
import ButtonMenu from '../../../../../components/SpeysButtons/ButtonMenu';
import IconButton from '@material-ui/core/IconButton';
import SpeysCloudLoadingComponent from '../../../../../components/SpeysCloud.Loading.component';

function safe(key: keyof VehiclesResponseViewModel) {
  return CommonUtilities.typeSafeName<VehiclesResponseViewModel>(key);
}

export default class VehiclesTableComponent extends React.Component<{
  data: Array<VehiclesResponseViewModel>;
  handleEditClick: Function;
  total: number;
  page: number;
  sizePerPage: number;
  onSortChange: Function;
  onPageChange: Function;
  onSizePerPageList: Function;
  handleDeleteStart: Function;
  handleDownloadPdf: Function;
  loading: boolean;
}> {
  handleEditClick = (id: number) => {
    this.props.handleEditClick(id);
  };

  render() {
    const { data, page, total, sizePerPage, loading } = this.props;
    const { onSortChange, onPageChange, onSizePerPageList } = this.props;

    const tableOptions: Options = {
      page: page,
      sizePerPage: sizePerPage,
      sizePerPageList: [5, 10, 25],
      onSortChange: (sortName: any, sortOrder: SortOrder) => onSortChange(sortName, sortOrder),
      onPageChange: (pageParam: number, sizePerPageParam: number) => onPageChange(pageParam, sizePerPageParam),
      onSizePerPageList: (sizePerPageParam: number) => onSizePerPageList(sizePerPageParam),
    };

    return (
      <div className="card">
        <Segment className="card-body p-0 card-with-data-table">
          <SpeysCloudLoadingComponent isLoading={loading} error={undefined} showOverlayType={'white-overlay'} />

          <h4 className="card-title">{LocaleUtilities.i18n('Vehicles list')}</h4>
          <BootstrapTable
            data={data}
            remote={true}
            pagination={true}
            bordered={false}
            version="4"
            containerClass="speys-data-table table-hover"
            fetchInfo={{ dataTotalSize: total }}
            options={tableOptions}
          >
            <TableHeaderColumn isKey={true} hidden={true} dataField="id">
              Id
            </TableHeaderColumn>
            <TableHeaderColumn width="10px" />
            <TableHeaderColumn dataField={safe('loadCapacity')} dataSort={true}>
              {LocaleUtilities.i18n('Category')}
            </TableHeaderColumn>
            <TableHeaderColumn dataField={safe('regNumber')} dataSort={true}>
              {LocaleUtilities.i18n('License Plate')}
            </TableHeaderColumn>
            <TableHeaderColumn dataField={safe('type')} dataSort={true}>
              {LocaleUtilities.i18n('Vehicle Type')}
            </TableHeaderColumn>
            <TableHeaderColumn dataField={safe('cargoLength')} dataSort={true}>
              {LocaleUtilities.i18n('Cargo Length')}
            </TableHeaderColumn>
            <TableHeaderColumn dataField={safe('cargoWidth')} dataSort={true}>
              {LocaleUtilities.i18n('Cargo Width')}
            </TableHeaderColumn>
            <TableHeaderColumn dataField={safe('cargoHeight')} dataSort={true}>
              {LocaleUtilities.i18n('Cargo Height')}
            </TableHeaderColumn>
            <TableHeaderColumn dataFormat={this.actionFormat} />
          </BootstrapTable>
        </Segment>
      </div>
    );
  }

  private actionFormat = (cell: any, row: any, formatExtraData: any, rowIndex: number): ReactElement<any> => {
    return (
      <div className={'text-right'}>
        <IconButton className={'table-action-button'} color="primary" onClick={(e) => this.handleEditClick(row.id)}>
          <i className={' ti-pencil'} />
        </IconButton>
        <ButtonMenu>
          <MenuItem className={'table-menu-item'}>
            <Link to={routePaths.vehicles.editId(row.id)} className="list-action-button list-button-edit">
              <i className={' ti-pencil'} /> Edit
            </Link>
          </MenuItem>
          <MenuItem className={'table-menu-item'} onClick={(e) => this.props.handleDeleteStart(row.id)}>
            <a href="javascript:void(0)" className="list-action-button list-button-edit">
              <i className="ti-trash" /> {LocaleUtilities.i18n('menu-item-Delete', 'base.button')}
            </a>
          </MenuItem>
        </ButtonMenu>
      </div>
    );
  };
}
