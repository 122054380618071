import * as React from 'react';
import { ReactElement } from 'react';
import { BootstrapTable, Options, SortOrder, TableHeaderColumn } from 'speys-react-bootstrap-table';
import TableFormattingUtilities from '../../../../../helpers/TableFormattingUtilities';
import { Row, Col, Input, ModalFooter, ModalHeader, ModalBody, Modal, Button } from 'reactstrap';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import * as Rx from 'rxjs/Rx';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import SweetAlert from '../../../../../components/SweetAlert/SweetAlert.component';
import { RequestState } from './Request.reducer';
import RequestResponseViewModel from '../../../../../viewModels/request/RequestResponseViewModel';
import { fetchAsyncType } from './actions/Request.fetch.action';
import { RequestRequestViewModel } from '../../../../../viewModels/request/index';
import LocaleUtilities from '../../../../../helpers/LocaleUtilities';
import { routePaths } from '../../../../../constants/api.constants';
import CommonUtilities from '../../../../../helpers/CommonUtilities';
import SpeysContent from '../../../../../components/SpeysContent';
import DetailviewTopNavComponent from '../../../../../components/Template/Detailview.TopNav.component';
import PageTitleComponent from '../../../../../components/Template/PageTitle.component';
import { RouteComponentProps, withRouter } from 'react-router';
import SpeysCloudLoadingComponent from '../../../../../components/SpeysCloud.Loading.component';

export type RequestComponentStateProps = RequestState;

export interface RequestComponentDispatchProps {
  fetchAsync: fetchAsyncType;
  updateCancel: Function;
  updateStart: Function;
  updatePrice: Function;
  onSortChange: Function;
  onPageChange: Function;
  onSizePerPageList: Function;
}

function safeReq(key: keyof RequestRequestViewModel) {
  return CommonUtilities.typeSafeName<RequestRequestViewModel>(key);
}

function safeResp(key: keyof RequestResponseViewModel) {
  return CommonUtilities.typeSafeName<RequestResponseViewModel>(key);
}

type RequestComponentProps = RequestComponentStateProps &
  RequestComponentDispatchProps &
  RouteComponentProps<any> &
  InjectedFormProps<any>;

export const requestFormName = 'requestForm';

class RequestComponent extends React.Component<RequestComponentProps, { alert?: any; price: string; modal: boolean }> {
  subscription: Subscription;
  onSearch$: Subject<{}>;

  constructor(props: any) {
    super(props);
    this.onSearch$ = new Rx.Subject();
    this.state = {
      alert: '',
      price: '',
      modal: false,
    };
    this.onClickAccept = this.onClickAccept.bind(this);
    this.onClickReject = this.onClickReject.bind(this);
    this.handleChangePrice = this.handleChangePrice.bind(this);
  }

  componentDidMount() {
    const request = new RequestRequestViewModel();
    request.parse(location);
    this.props.fetchAsync(request, true, false);

    this.subscription = this.onSearch$
      .debounceTime(300)
      .subscribe((debounced) => this.props.fetchAsync(null, false, true));
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onClickAccept(id: number) {
    this.setState(
      () => ({ modal: true }),
      () => {
        this.setState({
          alert: (
            <Modal isOpen={this.state.modal} toggle={() => this.setState({ modal: false, alert: undefined })}>
              <ModalHeader toggle={() => this.setState({ modal: false, alert: undefined })}>Accepting</ModalHeader>
              <ModalBody>
                <Input
                  name={'price'}
                  placeholder="Price"
                  onChange={this.handleChangePrice}
                  className="form-control form-control-sm"
                />
                Are you sure?
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  onClick={() =>
                    this.props
                      .updateStart(id, 2)
                      .then(() => {
                        this.props.updatePrice(id, this.state.price);
                      })
                      .then(this.handleUpdateStatus)
                  }
                >
                  Ok
                </Button>{' '}
                <Button color="secondary" onClick={() => this.setState({ modal: false, alert: undefined })}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          ),
          /*
                <SweetAlert title={LocaleUtilities.i18n('Are you sure', 'confirm.modal')}
                            type="success"
                            onConfirm={() => this.props.updateStart(id, 2).then(() => {this.props.updatePrice(id,"120")}).then(this.handleUpdateStatus)}
                            onCancel={() => this.setState({alert: undefined})}>
                    <Input
                        name={'price'}
                        onChange={this.handleChangePrice}
                        className="form-control form-control-sm"
                    />
                    <p>{LocaleUtilities.i18n('Are you sure you?', 'request.accept')}</p>
                </SweetAlert>
                */
        });
      },
    );
  }

  handleChangePrice(e: React.ChangeEvent<HTMLInputElement>) {
    const priceValue = e.target.value;
    this.setState(
      () => ({
        price: priceValue,
      }),
      () => {
        console.log(this.state.price);
      },
    );
  }

  onClickReject(id: number) {
    this.setState({
      alert: (
        <SweetAlert
          title={LocaleUtilities.i18n('Are you sure', 'confirm.modal')}
          type="danger"
          onConfirm={() => this.props.updateStart(id, 3).then(this.handleUpdateStatus)}
          onCancel={() => this.setState({ alert: undefined })}
        >
          <p>{LocaleUtilities.i18n('Are you sure?', 'request.reject')}</p>
        </SweetAlert>
      ),
    });
  }

  handleUpdateStatus = () => {
    const request = new RequestRequestViewModel();
    request.parse(location);
    this.props.fetchAsync(request, true, false);
    this.setState({ modal: false, alert: undefined });
  };

  handleSearch = (e: any) => {
    this.onSearch$.next();
  };

  private editLinkFormat = (cell: any, row: any): ReactElement<any> => {
    return TableFormattingUtilities.boldTextFormatWithLink(
      `#${cell}`,
      undefined,
      routePaths.request.editId(row.id),
      false,
    );
  };

  private selectButton = (cell: any, row: any): ReactElement<any> => {
    const isOpen = row.status === 'Open';
    return (
      <div>
        {isOpen
          ? TableFormattingUtilities.selectButtonFormat(`${cell}`, row, this.onClickAccept, this.onClickReject)
          : null}
      </div>
    );
  };

  render() {
    const { onSortChange, onPageChange, onSizePerPageList, loading } = this.props;
    const { data, pagination } = this.props.tableData;
    const { page, total, sizePerPage } = pagination;

    const tableOptions: Options = {
      page: page,
      sizePerPage: sizePerPage,
      sizePerPageList: [5, 10, 25],
      onSortChange: (sortName: any, sortOrder: SortOrder) => onSortChange(sortName, sortOrder),
      onPageChange: (pageParam: number, sizePerPageParam: number) => onPageChange(pageParam, sizePerPageParam),
      onSizePerPageList: (sizePerPageParam: number) => onSizePerPageList(sizePerPageParam),
    };
    const searchTranslation = LocaleUtilities.i18n('form-input-placeholder-Search', 'form.input', true);
    return (
      <div>
        {this.state.alert}
        <SpeysContent styleName={'ct-table'}>
          <SpeysContent.Body>
            <SpeysCloudLoadingComponent isLoading={loading} error={undefined} />
            <div className="container-fluid">
              <DetailviewTopNavComponent>
                <div className="text-right"></div>
              </DetailviewTopNavComponent>
              <PageTitleComponent title={LocaleUtilities.i18n(`sidebar-menu-item-Request`, 'sidebar.menu', true)} />
              <Row className={'mt-15'}>
                <Col md={3}>
                  <form className="form p-t-20">
                    <div className="form-group">
                      <div className="input-group">
                        <div className="input-group-prepend bg-warning">
                          <span className="input-group-text bg-warning">
                            <i className="ti-search text-white" />
                          </span>
                        </div>
                        <Field
                          name={safeReq('keyword')}
                          className="form-control"
                          placeholder={searchTranslation}
                          component="input"
                          onChange={this.handleSearch}
                        />
                      </div>
                    </div>
                  </form>
                </Col>
              </Row>
              <div className=" animated fadeIn speys-data-table-container">
                <Row className="mt-15">
                  <Col md={12}>
                    <div className="card">
                      <Segment className="card-body p-0 card-with-data-table">
                        <Dimmer active={this.props.loading}>
                          <Loader />
                        </Dimmer>
                        <h4 className="card-title">{LocaleUtilities.i18n('Request list')}</h4>
                        <BootstrapTable
                          data={data}
                          version="4"
                          containerClass="speys-data-table table-hover"
                          bordered={false}
                          pagination={true}
                          remote={true}
                          fetchInfo={{ dataTotalSize: total }}
                          options={tableOptions}
                        >
                          <TableHeaderColumn isKey={true} hidden={true} dataField={safeResp('id')}>
                            Id
                          </TableHeaderColumn>
                          <TableHeaderColumn></TableHeaderColumn>
                          <TableHeaderColumn
                            dataField={safeResp('id')}
                            dataSort={true}
                            dataFormat={this.editLinkFormat}
                          >
                            {LocaleUtilities.i18n('Request-Request number')}
                          </TableHeaderColumn>
                          <TableHeaderColumn dataField={safeResp('quantity')} dataSort={true}>
                            {LocaleUtilities.i18n('Request-Quantity')}
                          </TableHeaderColumn>
                          <TableHeaderColumn dataField={safeResp('volume')} dataSort={true}>
                            {LocaleUtilities.i18n('Request-Volume')}
                          </TableHeaderColumn>
                          <TableHeaderColumn dataField={safeResp('weight')} dataSort={true}>
                            {LocaleUtilities.i18n('Request-Weight')}
                          </TableHeaderColumn>
                          <TableHeaderColumn dataField={safeResp('price')} dataSort={true}>
                            {LocaleUtilities.i18n('Request-Price')}
                          </TableHeaderColumn>
                          <TableHeaderColumn dataField={safeResp('status')} dataSort={true}>
                            {LocaleUtilities.i18n('Request-Status')}
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField={safeResp('id')}
                            dataFormat={this.selectButton}
                            className={'text-right'}
                            columnClassName={'text-right'}
                          ></TableHeaderColumn>
                          {/*
                                                    <TableHeaderColumn
                                                        dataFormat={this.actionFormat}
                                                        columnClassName="text-right"
                                                        width="10%"
                                                    />
                                                    */}
                        </BootstrapTable>
                      </Segment>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </SpeysContent.Body>
        </SpeysContent>
      </div>
    );
  }
}

export default reduxForm({ form: requestFormName })(withRouter(RequestComponent));
