import actionCreatorFactory from 'typescript-fsa';
import { Dispatch } from 'redux';

import fetchAsync from './AddressBook.fetch.action';
import { AddressBookState, REDUCER_KEY__ADDRESSBOOK } from '../AddressBook.reducer';
import { AddressApi } from '../../../../../../api/Address.api';
import { toastr } from 'react-redux-toastr';
import CommonUtilities from '../../../../../../helpers/CommonUtilities';

const actionCreator = actionCreatorFactory();
export const asyncActions = actionCreator.async<{}, {}, {}>('ADDRESSBOOK/DELETE/SUBMIT');

export default function submit() {
  return async (dispatch: Dispatch<any>, getState: Function) => {
    const currentState: AddressBookState = getState()[REDUCER_KEY__ADDRESSBOOK];

    async function mainAction() {
      dispatch(asyncActions.started({}));
      await AddressApi.remove(currentState.pendingDeleteId);
      dispatch(asyncActions.done({ params: {}, result: {} }));
      toastr.success('Success', 'Item was successfully deleted');
      dispatch(fetchAsync(null, false, false));
    }

    async function cancelAction(exception: any) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
      toastr.error('Error', 'Item was not deleted');
    }

    await CommonUtilities.tryCatchWrapper(mainAction, cancelAction);
  };
}
