import * as React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
//import { Link } from 'react-router-dom';
//import {routePaths} from "../../../constants/api.constants";
import { Form, FormGroup, Button } from 'reactstrap';
import LocaleUtilities from '../../../helpers/LocaleUtilities';

export const loginFormName = 'loginForm';
interface LoginFormComponentProps {
  username: string;
  password: string;
  login: Function;
}
class LoginFormComponent extends React.Component<
  { login: Function; username: string } & InjectedFormProps<LoginFormComponentProps>
> {
  constructor(props: any) {
    super(props);

    this.handleLoginKeyPress = this.handleLoginKeyPress.bind(this);
    this.handleLoginButtonClick = this.handleLoginButtonClick.bind(this);
  }

  render() {
    const { username } = this.props;
    const userNamePlaceHolder = LocaleUtilities.i18n('login-form-Your Username', 'login.form', true);
    const passwordPlaceHolder = LocaleUtilities.i18n('login-form-Enter Password', 'login.form', true);
    return (
      <div>
        <Form onKeyPress={this.handleLoginKeyPress}>
          <FormGroup className="has-wrapper">
            <Field
              name="username"
              className="form-control has-input input-lg"
              placeholder={userNamePlaceHolder}
              value={username}
              component="input"
            />
            <span className="has-icon">
              <i className="ti-user"></i>
            </span>
          </FormGroup>
          <FormGroup className="has-wrapper">
            <Field
              name="password"
              className="form-control has-input input-lg"
              type="password"
              placeholder={passwordPlaceHolder}
              component="input"
            />
            <span className="has-icon">
              <i className="ti-lock"></i>
            </span>
          </FormGroup>
          <FormGroup className="mb-15">
            <Button
              color="secondary"
              className="btn-block text-white w-100"
              size="large"
              onClick={this.handleLoginButtonClick}
            >
              {LocaleUtilities.i18n('login-form-SIGN IN', 'login.form')}
            </Button>
          </FormGroup>
        </Form>
      </div>
    );
  }

  handleLoginKeyPress(event: any) {
    if (event.key === 'Enter') {
      this.props.login();
    }
  }

  handleLoginButtonClick() {
    this.props.login();
  }
}
export default reduxForm<any, any>({ form: loginFormName })(LoginFormComponent);
