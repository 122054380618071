import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Dispatch } from 'redux';
import { toastr } from 'react-redux-toastr';

import { ErrorWrapper } from '../../../../../../viewModels/base';
import CommonUtilities from '../../../../../../helpers/CommonUtilities';
import { FileApi } from '../../../../../../api/File.api';

const actionCreator = actionCreatorFactory();
export const asyncActions = actionCreator.async<{}, { files: any }, ErrorWrapper>('DELIVERY/GET-FILES');
import { UploadFileTypes } from '../../../../../../constants/base.constants';

export default function submit(externalId: number, onSuccess: Function | undefined) {
  return async (dispatch: Dispatch<AnyAction>, getState: Function) => {
    async function mainAction() {
      dispatch(asyncActions.started({}));
      const files = await FileApi.getFiles(externalId, UploadFileTypes.shipment);
      dispatch(asyncActions.done({ params: {}, result: { files } }));
      if (onSuccess) {
        onSuccess();
      }
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
      toastr.error('Error', 'Error has occurred');
      CommonUtilities.scrollToTop();
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
