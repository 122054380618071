import * as React from 'react';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';

class ButtonMenu extends React.Component<
  any,
  {
    anchorEl: any;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      anchorEl: null,
    };
    this.handleToggleMenu = this.handleToggleMenu.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleToggleMenu = (event: any) => {
    this.setState({ anchorEl: event });
  };

  render() {
    const open = Boolean(this.state.anchorEl);
    return (
      <React.Fragment>
        <IconButton
          className={'table-action-button'}
          color="default"
          onClick={(event) => this.handleToggleMenu(event.currentTarget)}
        >
          <i className="ti-more-alt"></i>
        </IconButton>
        <Menu
          className={'table-popup-menu'}
          id="render-props-menu"
          anchorEl={this.state.anchorEl}
          open={open}
          onClose={this.handleClose}
        >
          {this.props.children}
        </Menu>
      </React.Fragment>
    );
  }
}

export default ButtonMenu;
