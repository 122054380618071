import React from 'react';
import AutoSuggest from 'react-autosuggest';
import { GenericFieldHTMLAttributes, WrappedFieldProps } from 'redux-form';

export default class AutoSuggestInput extends React.PureComponent<
  WrappedFieldProps &
    GenericFieldHTMLAttributes & {
      suggestions: any;
    },
  {
    filteredSuggestions: any;
    highlightedSuggestion: any;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      filteredSuggestions: [],
      highlightedSuggestion: [],
    };
    this.handleGetSuggestion = this.handleGetSuggestion.bind(this);
  }

  handleFetch = ({ value }: { value: any }) => {
    const { suggestions } = this.props;

    const filteredSuggestions = suggestions.filter(({ label }: { label: any }) =>
      label.toLowerCase().startsWith(value),
    );
    this.setState({ filteredSuggestions });
  };

  handleClear = () => {
    this.setState({ filteredSuggestions: [] });
  };

  handleGetSuggestion = (props: any) => {
    return props.label;
  };

  handleSuggestionHighlighted = ({ suggestion }: { suggestion: any }) => {
    this.setState({ highlightedSuggestion: suggestion });
  };

  renderSuggestion = (props: any) => {
    return <span>{props.label}</span>;
  };

  handleSuggestionSelected = (event: any, { suggestionValue, method }: { suggestionValue: any; method: any }) => {
    const { input } = this.props;
    input.onChange(suggestionValue);
    if (method === 'enter') {
      event.preventDefault();
    }
  };

  render() {
    const { input } = this.props;
    const { filteredSuggestions } = this.state;
    return (
      <AutoSuggest
        suggestions={filteredSuggestions}
        onSuggestionsFetchRequested={this.handleFetch}
        onSuggestionsClearRequested={this.handleClear}
        getSuggestionValue={this.handleGetSuggestion}
        renderSuggestion={this.renderSuggestion}
        onSuggestionSelected={this.handleSuggestionSelected}
        inputProps={{
          className: 'form-control',
          ...input,
        }}
      />
    );
  }
}
