import * as React from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { RouteComponentProps, withRouter } from 'react-router';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

import { AddressState } from '../Address.reducer';
import { ValidationFailure } from '../../../../../../viewModels/base';
import AddressResponseViewModel from '../../../../../../viewModels/addressBook/AddressResponseViewModel';
import { SpeysSelectStringField } from '../../../../../../components/Form/SpeysCloud.SelectField.component';
import BaseFormFields from '../../../../../../helpers/FormFieldUtilities';
import ReusableComponentUtilities from '../../../../../../helpers/ReusableComponentUtilities';
import SpeysCloudLoaderButtonComponent from '../../../../../../components/SpeysCloud.LoaderButton.component';
import { countryOptions } from '../../../../../../seed/index';
import LocaleUtilities from '../../../../../../helpers/LocaleUtilities';
import { routePaths } from '../../../../../../constants/api.constants';
import ValidationUtilities from '../../../../../../helpers/ValidationUtilities';
import DetailviewTopNavComponent from '../../../../../../components/Template/Detailview.TopNav.component';
import SpeysContent from '../../../../../../components/SpeysContent';
import PageTitleComponent from '../../../../../../components/Template/PageTitle.component';

type AddressResponseViewModelKeys = keyof AddressResponseViewModel;

const hasError = ValidationUtilities.hasError;
const getError = ValidationUtilities.getError;

class FormFields extends BaseFormFields<AddressResponseViewModelKeys> {
  getPostalCode = () => this.defaultField('postalCode');
  getCity = () => this.defaultField('city');
  getAddressLine1 = () => this.defaultField('addressLine1');
  getAddressLine2 = () => this.defaultField('addressLine2');
  getAddressLine3 = () => this.defaultField('addressLine3');
  getCompanyName = () => this.defaultField('company');
  getContactPersonName = () => this.defaultField('contactName');
  getPhone = () => this.defaultField('contactPhoneNumber');
  getEmail = () => this.defaultField('contactEmail');
  getKmkRegistrationNumber = () => this.defaultField('kmkRegistrationNumber');
  getTntClientNumber = () => this.defaultField('tntClientNumber');
  getContactReference = () => this.defaultField('contactReference');

  getCountry = () => <SpeysSelectStringField name={this.typeSafeName('country')} options={countryOptions} />;
}

class AddressFieldsComponent extends React.Component<{
  formFields: FormFields;
  formErrors: Array<ValidationFailure>;
  formErrorPropertyNames: Array<string>;
}> {
  render() {
    const { formFields, formErrors, formErrorPropertyNames } = this.props;

    return (
      <div className="card">
        <div className="card-body">
          <h3 className="card-title m-b-5">{LocaleUtilities.i18n('addressbook-Address Info', 'addressbook')}</h3>
          <div className={`form-group ${hasError(formErrorPropertyNames, 'Country')}`}>
            <label>
              {LocaleUtilities.i18n('addressbook-Country', 'addressbook')}
              <span className="required">*</span>
            </label>
            {formFields.getCountry()}
            {getError(formErrors, 'CountryOption')}
          </div>
          <div className={`form-group ${hasError(formErrorPropertyNames, 'PostalCode')}`}>
            <label>
              {LocaleUtilities.i18n('addressbook-PostCode', 'addressbook')}
              <span className="required">*</span>
            </label>
            {formFields.getPostalCode()}
            {getError(formErrors, 'PostalCode')}
          </div>
          <div className={`form-group ${hasError(formErrorPropertyNames, 'City')}`}>
            <label>
              {LocaleUtilities.i18n('addressbook-City', 'addressbook')}
              <span className="required">*</span>
            </label>
            {formFields.getCity()}
            {getError(formErrors, 'City')}
          </div>
          <div className={`form-group ${hasError(formErrorPropertyNames, 'AddressLine1')}`}>
            <label>
              {LocaleUtilities.i18n('addressbook-AddressLine1', 'addressbook')}
              <span className="required">*</span>
            </label>
            {formFields.getAddressLine1()}
            {getError(formErrors, 'AddressLine1')}
          </div>
          <div className={`form-group ${hasError(formErrorPropertyNames, 'AddressLine2')}`}>
            <label>{LocaleUtilities.i18n('addressbook-AddressLine2', 'addressbook')}</label>
            {formFields.getAddressLine2()}
            {getError(formErrors, 'AddressLine2')}
          </div>
          <div className={`form-group ${hasError(formErrorPropertyNames, 'AddressLine3')}`}>
            <label>{LocaleUtilities.i18n('addressbook-AddressLine3', 'addressbook')}</label>
            {formFields.getAddressLine3()}
            {getError(formErrors, 'AddressLine3')}
          </div>
        </div>
      </div>
    );
  }
}

class ContactPersonFieldsComponent extends React.Component<{
  formFields: FormFields;
  formErrors: Array<ValidationFailure>;
  formErrorPropertyNames: Array<string>;
}> {
  render() {
    const { formFields, formErrors, formErrorPropertyNames } = this.props;

    return (
      <div className="card">
        <div className="card-body">
          <h3 className="card-title m-b-5">{LocaleUtilities.i18n('addressbook-Contact info', 'addressbook')}</h3>
          <div className={`form-group ${hasError(formErrorPropertyNames, 'Company')}`}>
            <label>
              {LocaleUtilities.i18n('addressbook-Company')}
              <span className="required">*</span>
            </label>
            {formFields.getCompanyName()}
            {getError(formErrors, 'Company')}
          </div>
          <div className={`form-group ${hasError(formErrorPropertyNames, 'ContactName')}`}>
            <label>{LocaleUtilities.i18n('addressbook-Contact person name')}*</label>
            {formFields.getContactPersonName()}
            {getError(formErrors, 'ContactName')}
          </div>
          <div className={`form-group ${hasError(formErrorPropertyNames, 'ContactPhoneNumber')}`}>
            <label>
              {LocaleUtilities.i18n('addressbook-Phone')}
              <span className="required">*</span>
            </label>
            {formFields.getPhone()}
            {getError(formErrors, 'ContactPhoneNumber')}
          </div>
          <div className={`form-group ${hasError(formErrorPropertyNames, 'Email')}`}>
            <label>{LocaleUtilities.i18n('addressbook-Email')}</label>
            {formFields.getEmail()}
            {getError(formErrors, 'Email')}
          </div>
          <div className={`form-group ${hasError(formErrorPropertyNames, 'KmkRegistrationNumber')}`}>
            <label>{LocaleUtilities.i18n('addressbook-KmKRegistrationNumber')}</label>
            {formFields.getKmkRegistrationNumber()}
            {getError(formErrors, 'KmkRegistrationNumber')}
          </div>
          {/*<div className={`form-group ${hasError(formErrorPropertyNames, 'TntClientNumber')}`}>
                        <label>Tnt Client Number (optional)</label>
                        {formFields.getTntClientNumber()}
                        {getError(formErrors, 'TntClientNumber')}
                    </div>*/}
          <div className={`form-group ${hasError(formErrorPropertyNames, 'ContactReference')}`}>
            <label>{LocaleUtilities.i18n('addressbook-ContactReference')}</label>
            {formFields.getContactReference()}
            {getError(formErrors, 'ContactReference')}
          </div>
        </div>
      </div>
    );
  }
}

export interface AddressComponentStateProps extends AddressState {
  title: string;
}

export interface AddressComponentDispatchProps {
  cancelItem: Function;
  startItem: Function;
  submitItem: Function;
}

export const addressFormName = 'addressForm';
type AddressComponentProps = AddressComponentStateProps &
  AddressComponentDispatchProps &
  RouteComponentProps<any> &
  InjectedFormProps<any>;

class AddressComponent extends React.Component<AddressComponentProps> {
  constructor(props: any) {
    super(props);

    this.goToList = this.goToList.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    this.props.startItem(this.props.addressId);
  }

  goToList() {
    this.props.history.push(routePaths.addressBook.list);
  }

  handleSave() {
    this.props.submitItem(this.goToList);
  }

  render() {
    const { title } = this.props;

    const errorWrapper = this.props.errorWrapper;
    const { formErrors, formErrorPropertyNames } = ValidationUtilities.getFormErrors(errorWrapper);
    const backToListRowComponent = ReusableComponentUtilities.getBackToListComponent(routePaths.addressBook.list);
    const errorRowComponent = ReusableComponentUtilities.getErrorRowComponent(errorWrapper);
    const formFields = new FormFields();

    if (ValidationUtilities.isCriticalError(errorWrapper)) {
      return (
        <div>
          {errorRowComponent}
          {backToListRowComponent}
        </div>
      );
    }

    return (
      <SpeysContent styleName={'ct-table'}>
        <SpeysContent.Body>
          <div className="container-fluid">
            <DetailviewTopNavComponent>{backToListRowComponent}</DetailviewTopNavComponent>
            <PageTitleComponent title={LocaleUtilities.i18n(`sidebar-menu-item-Address book`, 'sidebar.menu', true)} />
            <div className="animated fadeIn">
              <Row>
                <Col md={12}>
                  <h3 className="text-bold sub-title">{title}</h3>
                </Col>
              </Row>
              <Row className="animated bounceInRight">
                <Col md={12}>
                  <div className="form p-t-20 smaller-label-font">
                    {errorRowComponent}
                    <Row>
                      <Col md={6}>
                        <AddressFieldsComponent
                          formFields={formFields}
                          formErrors={formErrors}
                          formErrorPropertyNames={formErrorPropertyNames}
                        />
                      </Col>
                      <Col md={6}>
                        <ContactPersonFieldsComponent
                          formFields={formFields}
                          formErrors={formErrors}
                          formErrorPropertyNames={formErrorPropertyNames}
                        />
                      </Col>
                      <Col md={12} className="text-right mt-15 mb-25">
                        <SpeysCloudLoaderButtonComponent
                          loading={this.props.loading}
                          handleAction={this.handleSave}
                          className={'btn waves-effect waves-light btn-success '}
                        >
                          {LocaleUtilities.i18n('Save', 'base.button')}
                        </SpeysCloudLoaderButtonComponent>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </SpeysContent.Body>
      </SpeysContent>
    );
  }
}

export default reduxForm({ form: addressFormName })(withRouter(AddressComponent));
