import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Dispatch } from 'redux';

import { DriverViewApi } from 'api/Driver.api';
import CommonUtilities from 'helpers/CommonUtilities';
import { ErrorWrapper } from 'viewModels/base';

const actionCreator = actionCreatorFactory();
const type = 'DRIVERVIEW/UPLOAD_POD_FILES';
export const asyncActions = actionCreator.async<{}, {}, ErrorWrapper>(type);

export default function uploadPodFilesAsync(driverViewGuid: string, shipmentId: number, files: FileList): Function {
  return async (dispatch: Dispatch<AnyAction>): Promise<void> => {
    async function mainAction(): Promise<void> {
      dispatch(asyncActions.started({}));

      const result = await DriverViewApi.uploadPodFiles(driverViewGuid, shipmentId, files);

      dispatch(asyncActions.done({ params: {}, result }));
    }

    async function catchAction(exception: ErrorWrapper): Promise<void> {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
