import * as React from 'react';
import { Link } from 'react-router-dom';
import { UsersState } from './Users.reducer';
import { BootstrapTable, Options, SortOrder, TableHeaderColumn } from 'speys-react-bootstrap-table';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Button from 'reactstrap/lib/Button';
import { Row, Col } from 'reactstrap';
import UsersResponseViewModel from '../../../../../viewModels/driver/DriversResponseViewModel';
import TableFormattingUtilities from '../../../../../helpers/TableFormattingUtilities';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { fetchAsyncType } from './actions/Users.fetch.action';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { UsersRequestViewModel } from '../../../../../viewModels/driver/index';
import * as Rx from 'rxjs/Rx';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import { routePaths } from '../../../../../constants/api.constants';
import CommonUtilities from '../../../../../helpers/CommonUtilities';
import SpeysContent from '../../../../../components/SpeysContent';
import DetailviewTopNavComponent from '../../../../../components/Template/Detailview.TopNav.component';
import PageTitleComponent from '../../../../../components/Template/PageTitle.component';
import IconButton from '@material-ui/core/IconButton';
import ButtonMenu from '../../../../../components/SpeysButtons/ButtonMenu';
import MenuItem from '@material-ui/core/MenuItem';
import { RouteComponentProps, withRouter } from 'react-router';
import LocaleUtilities from '../../../../../helpers/LocaleUtilities';

export type UsersComponentStateProps = UsersState;

export interface UsersComponentDispatchProps {
  fetchAsync: fetchAsyncType;
  deleteCancel: Function;
  deleteStart: Function;
  deleteSubmit: Function;
  onSortChange: Function;
  onPageChange: Function;
  onSizePerPageList: Function;
  toggleActivation: Function;
}

function safe(key: keyof UsersResponseViewModel) {
  return CommonUtilities.typeSafeName<UsersResponseViewModel>(key);
}

type UsersComponentProps = UsersComponentStateProps &
  UsersComponentDispatchProps &
  RouteComponentProps<any> &
  InjectedFormProps<any>;

export const usersFormName = 'usersFormName';

class UsersComponent extends React.Component<UsersComponentProps> {
  subscription: Subscription;
  onSearch$: Subject<{}>;

  constructor(props: any) {
    super(props);
    this.onSearch$ = new Rx.Subject();
  }

  componentDidMount() {
    const request = new UsersRequestViewModel();
    request.parse(location);
    this.props.fetchAsync(request, true, false);

    this.subscription = this.onSearch$
      .debounceTime(300)
      .subscribe((debounced) => this.props.fetchAsync(null, false, true));
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  handleSearch = (e: any) => {
    this.onSearch$.next();
  };
  handleDeleteStart = (id: number) => {
    this.props.deleteStart(id);
  };
  handleDeleteCancel = () => {
    this.props.deleteCancel();
  };
  handleDeleteSubmit = () => {
    this.props.deleteSubmit();
  };
  handleEditClick = (id: number) => {
    this.props.history.push(routePaths.drivers.editId(id));
  };
  actionFormat = (cell: any, row: UsersResponseViewModel): JSX.Element => {
    return (
      <div className={'text-right'}>
        <IconButton className={'table-action-button'} color="primary" onClick={(e) => this.handleEditClick(row.id)}>
          <i className={' ti-pencil'} />
        </IconButton>
        <ButtonMenu>
          <MenuItem className={'table-menu-item'} onClick={(e) => this.handleDeleteStart(row.id)}>
            <a href="javascript:void(0)" className="list-action-button list-button-edit">
              <i className="ti-trash" /> {LocaleUtilities.i18n('menu-item-Delete', 'base.button')}
            </a>
          </MenuItem>
        </ButtonMenu>
      </div>
    );
  };

  render() {
    const { pendingDeleteId, confirmLoading, onSortChange, onPageChange, onSizePerPageList } = this.props;
    const { data, pagination } = this.props.tableData;
    const { page, total, sizePerPage } = pagination;

    const tableOptions: Options = {
      page: page,
      sizePerPage: sizePerPage,
      sizePerPageList: [5, 10, 25],
      onSortChange: (sortName: any, sortOrder: SortOrder) => onSortChange(sortName, sortOrder),
      onPageChange: (pageParam: number, sizePerPageParam: number) => onPageChange(pageParam, sizePerPageParam),
      onSizePerPageList: (sizePerPageParam: number) => onSizePerPageList(sizePerPageParam),
    };
    const searchTranslation = LocaleUtilities.i18n('form-input-placeholder-Search', 'form.input', true);
    return (
      <div>
        <Modal isOpen={pendingDeleteId > 0} toggle={this.handleDeleteCancel}>
          <ModalHeader toggle={this.handleDeleteCancel}>Deleting</ModalHeader>
          <ModalBody>{LocaleUtilities.i18n('confirm-modal-title-Are you sure', 'confirm.modal')}</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.handleDeleteSubmit} disabled={confirmLoading}>
              {LocaleUtilities.i18n('modal-button-Continue', 'confirm.modal')}
            </Button>{' '}
            <Button color="secondary" onClick={this.handleDeleteCancel} disabled={confirmLoading}>
              {LocaleUtilities.i18n('modal-button-Cancel', 'confirm.modal')}
            </Button>
          </ModalFooter>
        </Modal>
        <SpeysContent styleName={'ct-table'}>
          <SpeysContent.Body>
            <div className="container-fluid">
              <DetailviewTopNavComponent>
                <div className="text-right">
                  <Link
                    to={routePaths.drivers.new}
                    className="btn waves-effect waves-light btn-outline-primary btn-sm text-white"
                  >
                    + {LocaleUtilities.i18n('driver-detail-Add driver', 'driver.list')}
                  </Link>
                </div>
              </DetailviewTopNavComponent>
              <PageTitleComponent title={LocaleUtilities.i18n(`sidebar-menu-item-Drivers`, 'sidebar.menu', true)} />
              <Row className={'mt-15'}>
                <Col md={3}>
                  <form className="form p-t-20">
                    <div className="form-group">
                      <div className="input-group">
                        <div className="input-group-prepend bg-warning">
                          <span className="input-group-text bg-warning">
                            <i className="ti-search text-white" />
                          </span>
                        </div>
                        <Field
                          name="keyword"
                          className="form-control"
                          placeholder={searchTranslation}
                          component="input"
                          onChange={this.handleSearch}
                        />
                      </div>
                    </div>
                  </form>
                </Col>
              </Row>
              <div className=" animated fadeIn speys-data-table-container">
                <Row className="animated bounceInRight mt-15">
                  <Col md={12}>
                    <div className="card">
                      <Segment className="no-border">
                        <Dimmer active={this.props.loading}>
                          <Loader />
                        </Dimmer>
                        <BootstrapTable
                          data={data}
                          version="4"
                          containerClass="speys-data-table table-hover"
                          bordered={false}
                          hover={true}
                          pagination={true}
                          remote={true}
                          fetchInfo={{ dataTotalSize: total }}
                          options={tableOptions}
                        >
                          <TableHeaderColumn isKey={true} hidden={true} dataField={safe('id')}>
                            Id
                          </TableHeaderColumn>
                          <TableHeaderColumn />
                          <TableHeaderColumn
                            dataField={safe('firstName')}
                            dataSort={true}
                            dataFormat={TableFormattingUtilities.boldTextFormat}
                          >
                            {LocaleUtilities.i18n('users-detail-First Name', 'user.detail')}
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField={safe('lastName')}
                            dataSort={true}
                            dataFormat={TableFormattingUtilities.boldTextFormat}
                          >
                            {LocaleUtilities.i18n('users-detail-Last Name', 'user.detail')}
                          </TableHeaderColumn>
                          <TableHeaderColumn dataField={safe('drivingLicenseNr')} dataSort={true}>
                            {LocaleUtilities.i18n('users-detail-License Number', 'user.detail')}
                          </TableHeaderColumn>
                          <TableHeaderColumn dataField={safe('email')} dataSort={true}>
                            {LocaleUtilities.i18n('users-detail-Email', 'user.detail')}
                          </TableHeaderColumn>
                          <TableHeaderColumn dataField={safe('phone')} dataSort={true}>
                            {LocaleUtilities.i18n('users-detail-Phone', 'user.detail')}
                          </TableHeaderColumn>
                          <TableHeaderColumn dataFormat={this.actionFormat} width="10%" />
                        </BootstrapTable>
                      </Segment>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </SpeysContent.Body>
        </SpeysContent>
      </div>
    );
  }
}

export default reduxForm({ form: usersFormName })(withRouter(UsersComponent));
