import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Dispatch } from 'redux';
import { initialize } from 'redux-form';
import { SortOrder } from 'speys-react-bootstrap-table';

import { DeliveryApi } from '../../../../../../api/Delivery.api';
import DeliveriesResponseViewModel from '../../../../../../viewModels/delivery/deliveriesResponseViewModel';
import { ErrorWrapper, TableData } from '../../../../../../viewModels/base';
import { deliveriesFormName } from '../Shipment.component';
import DeliveriesRequestViewModel from '../../../../../../viewModels/delivery/deliveriesRequestViewModel';
import RouterUtilities from '../../../../../../helpers/RouterUtilities';
import CommonUtilities from '../../../../../../helpers/CommonUtilities';

const actionCreator = actionCreatorFactory();
export const asyncActions = actionCreator.async<
  {},
  { tableData: TableData<DeliveriesResponseViewModel> },
  ErrorWrapper
>('DELIVERIES/FETCH');

export declare type fetchAsyncType = (
  params: DeliveriesRequestViewModel | null,
  reinitializeForm: boolean,
  changeQueryParams: boolean,
) => (dispatch: Dispatch<AnyAction>, getState: Function) => Promise<void>;

//
// Main fetch action
//

const fetchAsync: fetchAsyncType = (
  params: DeliveriesRequestViewModel | null,
  reinitializeForm: boolean,
  changeQueryParams: boolean,
) => {
  return async (dispatch: Dispatch<AnyAction>, getState: Function) => {
    const deliveriesForm: DeliveriesRequestViewModel = params || getState().form[deliveriesFormName].values;

    async function mainAction() {
      dispatch(asyncActions.started({}));

      if (reinitializeForm) {
        dispatch(initialize(deliveriesFormName, deliveriesForm));
      }

      if (changeQueryParams) {
        RouterUtilities.changeQueryParamsInUrl(deliveriesForm);
      }

      const paginatedList = await DeliveryApi.getList(deliveriesForm);

      dispatch(
        asyncActions.done({
          params: {},
          result: {
            tableData: TableData.createTableDataFrom(paginatedList, deliveriesForm.page, deliveriesForm.sizePerPage),
          },
        }),
      );
    }

    async function catchAction(exception: any) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
};

export default fetchAsync;

//
// Sorting and pagination
//

export function onSortChange(sortName: string, sortOrder: SortOrder) {
  return async (dispatch: Dispatch<any>, getState: Function) => {
    const deliveriesForm: DeliveriesRequestViewModel = getState().form[deliveriesFormName].values;
    deliveriesForm.sortName = sortName;
    deliveriesForm.sortOrder = sortOrder;
    dispatch(fetchAsync(deliveriesForm, false, true));
  };
}

export function onPageChange(page: number, sizePerPage: number) {
  return async (dispatch: Dispatch<any>, getState: Function) => {
    const deliveriesForm: DeliveriesRequestViewModel = getState().form[deliveriesFormName].values;
    deliveriesForm.page = page;
    deliveriesForm.sizePerPage = sizePerPage;
    dispatch(fetchAsync(deliveriesForm, false, true));
  };
}

export function onSizePerPageList(sizePerPage: number) {
  return async (dispatch: Dispatch<any>, getState: Function) => {
    const deliveriesForm: DeliveriesRequestViewModel = getState().form[deliveriesFormName].values;
    deliveriesForm.sizePerPage = sizePerPage;
    dispatch(fetchAsync(deliveriesForm, false, true));
  };
}
