import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Dispatch } from 'redux';

import { ErrorWrapper, TableData } from '../../../../../../viewModels/base';
import TrackingApi from '../../../../../../api/Tracking.api';
import CommonUtilities from '../../../../../../helpers/CommonUtilities';
import TrackingsResponseViewModel from '../../../../../../viewModels/trackings/TrackingsResponseViewModel';

const actionCreator = actionCreatorFactory();
export const asyncActions = actionCreator.async<
  { loading: boolean },
  { data: TableData<TrackingsResponseViewModel> },
  ErrorWrapper
>('DELIVERYTRACKING/FETCH');

export default function fetchAsync() {
  return async (dispatch: Dispatch<AnyAction>, getState: Function) => {
    async function mainAction() {
      dispatch(asyncActions.started({ loading: true }));
      const paginatedList = await TrackingApi.getSticknTrackDevices();

      const tableData = TableData.createTableDataFrom(paginatedList, 1, 0);
      dispatch(asyncActions.done({ params: { loading: false }, result: { data: tableData } }));
    }

    async function catchAction(exception: any) {
      dispatch(asyncActions.failed({ params: { loading: false }, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
