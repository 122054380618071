import { BACKEND_HOST_MAIN } from '../constants/api.constants';
// import { PaginatedListResult } from '../viewModels/base';
import ApiUtilities from '../helpers/ApiUtils';
import PartnerRequestViewModel from '../viewModels/vehicle/VehicleRequestViewModel';
import PartnerCreateOrUpdateRequestViewModel from '../viewModels/vehicle/VehicleCreateOrUpdateRequestViewModel';
import PartnersRequestViewModel from '../viewModels/vehicle/VehiclesRequestViewModel';
import { PaginatedListResult } from '../viewModels/base';
import PartnersResponseViewModel from '../viewModels/vehicle/VehiclesResponseViewModel';

function getRoot() {
  return `${BACKEND_HOST_MAIN()}api/partner-portal/vehicle`;
}

export class VehicleApi {
  public static getList(params: PartnersRequestViewModel): Promise<PaginatedListResult<PartnersResponseViewModel>> {
    return ApiUtilities.handleGet<PaginatedListResult<PartnersResponseViewModel>>(getRoot(), { ...params });
  }

  public static getSingle(id: number): Promise<PartnerRequestViewModel | null> {
    return ApiUtilities.handleGet<PartnerRequestViewModel>(`${getRoot()}/${id}`);
  }

  public static add(formValues: PartnerCreateOrUpdateRequestViewModel) {
    return ApiUtilities.handlePost<number>(getRoot(), formValues);
  }

  public static update(id: number, formValues: PartnerCreateOrUpdateRequestViewModel) {
    return ApiUtilities.handlePut<boolean>(`${getRoot()}/${id}`, formValues);
  }

  public static remove(id: number) {
    return ApiUtilities.handleDelete(`${getRoot()}/${id}`);
  }
}
