import * as React from 'react';
import { ErrorType, ErrorWrapper, ValidationFailure } from '../viewModels/base';

type getFormErrorsResult = { formErrors: Array<ValidationFailure>; formErrorPropertyNames: Array<string> };
export default class ValidationUtls {
  public static isCriticalError(errorWrapper: ErrorWrapper | undefined) {
    return (
      errorWrapper &&
      (errorWrapper.type === ErrorType.ItemNotFound || errorWrapper.type === ErrorType.FailedResponseError)
    );
  }

  public static getFormErrors(errorWrapper: ErrorWrapper | undefined): getFormErrorsResult {
    const formErrors = (errorWrapper && errorWrapper.formValidations && errorWrapper.formValidations.errors) || [];
    const formErrorPropertyNames = formErrors.map((err) => err.propertyName);
    return { formErrors, formErrorPropertyNames };
  }

  public static getFormErrorPropertyNames(formErrors: Array<ValidationFailure> = []): Array<string> {
    return formErrors.map((err) => err.propertyName);
  }

  public static hint(errorText: string): JSX.Element {
    return (
      <div className="help-block">
        <ul role="alert">
          <li>{errorText}</li>
        </ul>
      </div>
    );
  }

  public static hasError(items: Array<string>, itemName: string) {
    return items.indexOf(itemName) > -1 ? 'error' : '';
  }

  public static getError(errors: Array<ValidationFailure>, itemName: string): JSX.Element | null {
    for (const error of errors) {
      if (error.propertyName === itemName) {
        return ValidationUtls.hint(error.errorMessage);
      }
    }
    return null;
  }
}
