import * as React from 'react';
import { CompanyComponentProps } from './Company.component';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import LocaleUtilities from '../../../../../../helpers/LocaleUtilities';
// import SpeysCloudLoaderButtonComponent from "../../../../../../components/SpeysCloud.LoaderButton.component";
import { FormFieldUtilities } from '../../../../../../helpers/FormFieldUtilities';
import BaseFormFields from '../../../../../../helpers/FormFieldUtilities';
import CompanyCreateOrUpdateRequestViewModel from '../../../../../../viewModels/company/CompanyCreateOrUpdateRequestViewModel';
import { SpeysSelectStringField } from '../../../../../../components/Form/SpeysCloud.SelectField.component';
import countryOptions from '../../../../../../seed/CountryOptions';
import CompanyResponseViewModel from '../../../../../../viewModels/company/CompanyResponseViewModel';

class FormFields extends BaseFormFields<keyof CompanyCreateOrUpdateRequestViewModel> {
  getName = (): JSX.Element => this.defaultField('name');
  getVat = (): JSX.Element => this.defaultField('vatNr');
  getRegisterNr = (): JSX.Element => this.defaultField('registerNr');
  getEmail = (): JSX.Element => this.defaultField('email');
  getPhone = (): JSX.Element => this.defaultField('phone');

  getFirstAddressLine = (): JSX.Element => this.defaultField('firstAddresLine');
  getSecondAddressLine = (): JSX.Element => this.defaultField('secondAddressLine');
  getCity = (): JSX.Element => this.defaultField('city');
  getRegion = (): JSX.Element => this.defaultField('region');
  getZip = (): JSX.Element => this.defaultField('zip');
  getCountry = (): JSX.Element => (
    <SpeysSelectStringField name={this.typeSafeName('country')} options={countryOptions} />
  );
}

class CompanyColumnTwoFieldsComponent extends React.Component<{
  formFields: FormFields;
  formHelper: FormFieldUtilities;
  company?: CompanyResponseViewModel;
}> {
  render(): JSX.Element {
    // const {formFields, formHelper, company} = this.props;
    const { company } = this.props;
    return (
      <div className="card">
        <div className="card-body">
          <h2 className="heading">
            <span>Address Details</span>
          </h2>
          {company !== undefined && (
            <div>
              <div className="form-group">
                <label>{LocaleUtilities.i18n('company-detail-Address line 1', 'company.detail', true)}</label>
                <div className={'ml-5'}>{company.firstAddresLine}</div>
              </div>
              <div className="form-group">
                <label>{LocaleUtilities.i18n('company-detail-Address line 2', 'company.detail', true)}</label>
                <div className={'ml-5'}>{company.secondAddressLine}</div>
              </div>
              <div className="form-group">
                <label>{LocaleUtilities.i18n('company-detail-City', 'company.detail', true)}</label>
                <div className={'ml-5'}>{company.city}</div>
              </div>
              <div className="form-group">
                <label>{LocaleUtilities.i18n('company-detail-Region', 'company.detail', true)}</label>
                <div className={'ml-5'}>{company.region}</div>
              </div>
              <div className="form-group">
                <label>{LocaleUtilities.i18n('company-detail-Postcode', 'company.detail', true)}</label>
                <div className={'ml-5'}>{company.zip}</div>
              </div>
              <div className="form-group">
                <label>{LocaleUtilities.i18n('company-detail-Country', 'company.detail', true)}</label>
                <div className={'ml-5'}>{LocaleUtilities.getCountryLabel(company.country)}</div>
              </div>
            </div>
          )}
          {/*
                    {formHelper.getField(LocaleUtilities.i18n('company-detail-Address line 1', 'company.detail', true), formFields.getFirstAddressLine(), 'AddressLine1', true)}
                    {formHelper.getField(LocaleUtilities.i18n('company-detail-Address line 2', 'company.detail', true), formFields.getSecondAddressLine(), 'AddressLine2')}
                    {formHelper.getField(LocaleUtilities.i18n('company-detail-City', 'company.detail', true), formFields.getCity(), 'City', true)}
                    {formHelper.getField(LocaleUtilities.i18n('company-detail-Region', 'company.detail', true), formFields.getRegion(), 'Region')}
                    {formHelper.getField(LocaleUtilities.i18n('company-detail-Postcode', 'company.detail', true), formFields.getZip(), 'Zip', true)}
                    {formHelper.getField(LocaleUtilities.i18n('company-detail-Country', 'company.detail', true), formFields.getCountry(), 'CountryOption', true)}
                    */}
        </div>
      </div>
    );
  }
}

export declare type CompanyDetailsTabComponentProps = CompanyComponentProps & {
  formHelper: any;
  company?: CompanyResponseViewModel;
};

export default class CompanyDetailsTabComponent extends React.Component<
  CompanyDetailsTabComponentProps,
  {
    tenants: any[];
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      tenants: [],
    };
  }

  goToList = (): void => {
    /* do nothing */
  };

  handleSave = (): void => {
    this.props.submitItem(this.goToList);
  };

  async componentDidMount(): Promise<void> {
    this.props.startItem(this.props.companyId);
  }

  render(): JSX.Element {
    const formFields = new FormFields();
    const { formHelper, company } = this.props;
    return (
      <div className="form p-t-20 smaller-label-font">
        <Row>
          <Col md={6}>
            <div className="card">
              <div className="card-body">
                <h2 className="heading">
                  <span>Profile Details</span>
                </h2>
                {company !== undefined && (
                  <div>
                    <div className="form-group">
                      <label>{LocaleUtilities.i18n('company-detail-Name', 'company.detail', true)}</label>
                      <div className={'ml-5'}>
                        <strong>{company.name}</strong>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>{LocaleUtilities.i18n('company-detail-Register number', 'company.detail', true)}</label>
                      <div className={'ml-5'}>{company.registerNr}</div>
                    </div>
                    <div className="form-group">
                      <label>{LocaleUtilities.i18n('company-detail-VAT nr', 'company.detail', true)}</label>
                      <div className={'ml-5'}>{company.vatNr}</div>
                    </div>
                    <div className="form-group">
                      <label>{LocaleUtilities.i18n('company-detail-Email', 'company.detail', true)}</label>
                      <div className={'ml-5'}>{company.email}</div>
                    </div>
                    <div className="form-group">
                      <label>{LocaleUtilities.i18n('company-detail-Phone', 'company.detail', true)}</label>
                      <div className={'ml-5'}>{company.phone}</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col md={6}>
            <CompanyColumnTwoFieldsComponent formFields={formFields} formHelper={formHelper} company={company} />
          </Col>
        </Row>
        {/*<Row>
                    <Col md={12} className="text-right mb-20 mt-10">
                        <SpeysCloudLoaderButtonComponent
                            loading={this.props.loading}
                            handleAction={this.handleSave}
                            className={'btn waves-effect waves-light btn-success text-white'}
                        >
                            {LocaleUtilities.i18n('Save', 'base.button')}
                        </SpeysCloudLoaderButtonComponent>
                    </Col>
                </Row>*/}
      </div>
    );
  }
}
