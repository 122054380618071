import * as React from 'react';
import { InjectedFormProps } from 'redux-form';
import { RouteComponentProps } from 'react-router';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { StickyContainer } from 'react-sticky';
import { RequestState } from './Request.reducer';
import { Input, ModalFooter, ModalHeader, ModalBody, Modal, Button } from 'reactstrap';

/*Components*/
import RequestDetailsTabComponent from './Request.ShippingOrder.component';
import SpeysCloudLoaderButtonComponent from '../../../../../components/SpeysCloud.LoaderButton.component';
import SweetAlert from '../../../../../components/SweetAlert/SweetAlert.component';

/*helpers*/
import LocaleUtilities from '../../../../../helpers/LocaleUtilities';

/*viewmodels*/
import RequestResponseViewModel from '../../../../../viewModels/request/RequestResponseViewModel';

/*material-ui*/
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PageTitleComponent from '../../../../../components/Template/PageTitle.component';

export interface RequestContainerStateProps extends RequestState {
  request?: RequestResponseViewModel;
}

export interface RequestComponentDispatchProps {
  fetchStart: Function;
  updateStatus: Function;
  updatePrice: Function;
}

export declare type AllRequestComponentProps = RequestContainerStateProps &
  RequestComponentDispatchProps &
  RouteComponentProps<any> &
  InjectedFormProps<any>;

export const requestFormName = 'requestForm';

export default class RequestComponent extends React.Component<
  AllRequestComponentProps,
  {
    alert?: any;
    showAddShipmentModal: boolean;
    price: string;
    modal: boolean;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      alert: undefined,
      showAddShipmentModal: false,
      price: '',
      modal: false,
    };
    this.handleAccept = this.handleAccept.bind(this);
    this.handleChangePrice = this.handleChangePrice.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  async componentDidMount(): Promise<void> {
    await this.props.fetchStart(this.props.requestId);
  }

  handleTabChange = (): void => undefined;

  handleAccept(id: number): void {
    this.setState(
      () => ({ modal: true }),
      () => {
        this.setState({
          alert: (
            <Modal isOpen={this.state.modal} toggle={(): void => this.setState({ modal: false, alert: undefined })}>
              <ModalHeader toggle={(): void => this.setState({ modal: false, alert: undefined })}>
                Accepting
              </ModalHeader>
              <ModalBody>
                <Input
                  name={'price'}
                  placeholder="Price"
                  onChange={this.handleChangePrice}
                  className="form-control form-control-sm"
                />
                Are you sure?
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  onClick={(): void =>
                    this.props
                      .updateStatus(id, 2)
                      .then(() => {
                        this.props.updatePrice(id, this.state.price);
                      })
                      .then(this.handleUpdate)
                  }
                >
                  Ok
                </Button>{' '}
                <Button color="secondary" onClick={(): void => this.setState({ modal: false, alert: undefined })}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          ),
          /*
                <SweetAlert title={LocaleUtilities.i18n('Are you sure', 'confirm.modal')}
                            type="success"
                            onConfirm={() => this.props.updateStart(id, 2).then(() => {this.props.updatePrice(id,"120")}).then(this.handleUpdateStatus)}
                            onCancel={() => this.setState({alert: undefined})}>
                    <Input
                        name={'price'}
                        onChange={this.handleChangePrice}
                        className="form-control form-control-sm"
                    />
                    <p>{LocaleUtilities.i18n('Are you sure you?', 'request.accept')}</p>
                </SweetAlert>
                */
        });
      },
    );
  }

  handleReject(id: number): void {
    this.setState({
      alert: (
        <SweetAlert
          title={LocaleUtilities.i18n('Are you sure', 'confirm.modal')}
          type="danger"
          onConfirm={(): void => this.props.updateStatus(id, 3).then(this.handleUpdate)}
          onCancel={(): void => this.setState({ alert: undefined })}
        >
          <p>{LocaleUtilities.i18n('Are you sure?', 'request.reject')}</p>
        </SweetAlert>
      ),
    });
  }

  handleChangePrice(e: React.ChangeEvent<HTMLInputElement>): void {
    const priceValue = e.target.value;
    this.setState(
      () => ({
        price: priceValue,
      }),
      () => {
        console.log(this.state.price);
      },
    );
  }

  async handleUpdate(): Promise<void> {
    this.setState({ modal: false, alert: undefined });
    await this.props.fetchStart(this.props.requestId);
  }

  render(): JSX.Element {
    const { request } = this.props;
    console.log(request);
    return (
      <StickyContainer>
        {this.state.alert}
        <div className="container-fluid shipment-details">
          <PageTitleComponent title={LocaleUtilities.i18n(`sidebar-menu-item-Request`, 'sidebar.menu', true)} />
          <div className="row mb-50">
            <div className="col-md-10 offset-lg-1">
              <Row>
                <Col md={12}>
                  <h3 className="text-bold sub-title mt-5 mb-5">
                    {'Shipping order'} #{request !== undefined ? request.id + ' : ' + request.status : null}
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <AppBar position="static" className={'speys-system-tabs'}>
                    <Tabs
                      value={0}
                      onChange={this.handleTabChange}
                      variant="scrollable"
                      scrollButtons="off"
                      indicatorColor="primary"
                      className={'speys-tabs'}
                    >
                      <Tab icon={<i className="ti-info"></i>} label={'Shipping order request details'} />
                    </Tabs>
                  </AppBar>
                  <RequestDetailsTabComponent {...this.props} />
                </Col>
              </Row>
              {request !== undefined && request.status == 'Open' ? (
                <div className="text-right mt-10 mr-10">
                  <SpeysCloudLoaderButtonComponent
                    loading={this.props.loading}
                    handleAction={(): void => {
                      this.handleAccept(request.id);
                    }}
                    className={'btn waves-effect waves-light btn-success text-white'}
                  >
                    {LocaleUtilities.i18n('Accept', 'base.button')}
                  </SpeysCloudLoaderButtonComponent>
                  <SpeysCloudLoaderButtonComponent
                    loading={this.props.loading}
                    handleAction={(): void => {
                      this.handleReject(request.id);
                    }}
                    className={'btn waves-effect waves-light btn-danger text-white'}
                  >
                    {LocaleUtilities.i18n('Reject', 'base.button')}
                  </SpeysCloudLoaderButtonComponent>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </StickyContainer>
    );
  }
}
