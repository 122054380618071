import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { asyncActions as fetchAsyncActions } from './actions/Users.fetch.action';
import { asyncActions as deleteSubmitActions } from './actions/Users.delete.submit.action';
import { asyncActions as activationSubmitActions } from './actions/Users.toggleActivation.action';
import { deleteCancel, deleteStart } from './actions';
import { ErrorWrapper, TableData } from '../../../../../viewModels/base';
import UsersResponseViewModel from '../../../../../viewModels/driver/DriversResponseViewModel';

export const REDUCER_KEY__USERS = 'users';
export interface UsersState {
  type?: string;
  loading: boolean;
  confirmLoading: boolean;
  pagination: { total: number; current: number; pageSize: number };
  pendingDeleteId: number;
  error?: ErrorWrapper;
  tableData: TableData<UsersResponseViewModel>;
}

const defaultState: UsersState = {
  loading: false,
  confirmLoading: false,
  pagination: { total: 0, current: 0, pageSize: 25 },
  pendingDeleteId: 0,
  tableData: TableData.getDefault<UsersResponseViewModel>(),
};
export default (state: UsersState = defaultState, action: Action): UsersState => {
  // Fetch
  if (isType(action, fetchAsyncActions.started)) {
    return { ...state, type: action.type, loading: true };
  }
  if (isType(action, fetchAsyncActions.done)) {
    return { ...state, type: action.type, loading: false, tableData: action.payload.result.tableData };
  }
  if (isType(action, fetchAsyncActions.failed)) {
    return { ...state, type: action.type, loading: false, error: action.payload.error };
  }

  // Delete actions
  if (isType(action, deleteCancel)) {
    return { ...state, type: action.type, pendingDeleteId: 0 };
  }
  if (isType(action, deleteStart)) {
    return { ...state, type: action.type, pendingDeleteId: action.payload };
  }
  if (isType(action, deleteSubmitActions.started)) {
    return { ...state, type: action.type, confirmLoading: true };
  }
  if (isType(action, deleteSubmitActions.done)) {
    return { ...state, type: action.type, confirmLoading: false, pendingDeleteId: 0 };
  }
  if (isType(action, deleteSubmitActions.failed)) {
    return { ...state, type: action.type, confirmLoading: false };
  }

  // Activation/Deactivation
  if (isType(action, activationSubmitActions.started)) {
    return { ...state, type: action.type, confirmLoading: true };
  }
  if (isType(action, activationSubmitActions.done)) {
    return { ...state, type: action.type, confirmLoading: false };
  }
  if (isType(action, activationSubmitActions.failed)) {
    return { ...state, type: action.type, confirmLoading: false };
  }

  return state;
};
