import * as React from 'react';
// import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
// import DateFnsUtils from '@date-io/date-fns';
import { Redirect, Route } from 'react-router-dom';
import noInternet from 'no-internet';
import { ConnectedRouter } from 'connected-react-router';
import MainApp from './MainApp.component';
import { routePaths } from '../constants/api.constants';
import asyncComponent from '../util/asyncComponent';
import { injectAsyncReducer, history } from '../store';
import { REDUCER_KEY__FORGOT_PASSWORD } from './routes/Auth.ForgotPassword/ForgotPassword.reducer';
import { REDUCER_KEY__CHANGE_PASSWORD } from './routes/Auth.ChangePassword/ChangePassword.reducer';
import { ErrorWrapper } from '../viewModels/base';
import { Switch } from 'react-router';
import { IntlProvider } from 'react-intl';
import { store } from 'index';

/*Components*/
import SpeysCloudLoaderComponent from '../components/SpeysCloud.Loader.component';
import { REDUCER_KEY__DRIVER_VIEW } from './routes/Common/DriverView/DriverView.reducer';

import 'assets/scss/style.css';
import 'assets/scss/spinners.css';
import 'lib/reactifyCss.js';
import 'react-select/dist/react-select.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

interface RouteProps {
  location: any;
}

const RestrictedRoute = ({ component: Component, ...rest }): JSX.Element => (
  <Route
    {...rest}
    render={(props: RouteProps): JSX.Element =>
      rest.isLoggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: routePaths.login,
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export interface AppComponentStateProps {
  loading: boolean;
  error?: ErrorWrapper;
  isLoggedIn: boolean;
  settings: any;
  online: boolean;
}

export interface AppComponentDispatchProps {
  networkChanged: (online: boolean) => Function;
}

class AppComponent extends React.Component<AppComponentStateProps & AppComponentDispatchProps> {
  render(): JSX.Element {
    const { loading, error, isLoggedIn, networkChanged } = this.props;

    noInternet({
      milliseconds: 10000,
      callback: (offline: boolean) => {
        const { online } = this.props;
        const newOnlineState = !offline;
        return online !== newOnlineState && networkChanged(newOnlineState);
      },
    });

    if (loading) {
      return <SpeysCloudLoaderComponent />;
    }
    if (isLoggedIn && error) {
      return (
        <div>
          [ERROR] {error.title}: {error.description}
        </div>
      );
    }

    return (
      <IntlProvider locale={'en'}>
        <div className={`app-main ${!isLoggedIn ? '' : ''}`}>
          <ConnectedRouter history={history}>
            <Switch>
              <Route
                path={routePaths.driverView}
                component={asyncComponent(async () => {
                  const reducer = await import('./routes/Common/DriverView/DriverView.reducer');
                  injectAsyncReducer(store, REDUCER_KEY__DRIVER_VIEW, reducer.default);
                  return await import('./routes/Common/DriverView');
                })}
              />

              {isLoggedIn ? (
                <RestrictedRoute exact path={`/`} isLoggedIn={isLoggedIn} component={MainApp} />
              ) : (
                <Route path={'*'} component={asyncComponent(async () => await import('./routes/Login'))} />
              )}

              <Route
                path={routePaths.forgotPassword}
                exact
                component={asyncComponent(async () => {
                  const reducer = await import('./routes/Auth.ForgotPassword/ForgotPassword.reducer');
                  injectAsyncReducer(store, REDUCER_KEY__FORGOT_PASSWORD, reducer.default);
                  return await import('./routes/Auth.ForgotPassword');
                })}
              />
              <Route
                path={routePaths.resetPassword}
                exact
                component={asyncComponent(async () => {
                  const reducer = await import('./routes/Auth.ChangePassword/ChangePassword.reducer');
                  injectAsyncReducer(store, REDUCER_KEY__CHANGE_PASSWORD, reducer.default);
                  return await import('./routes/Auth.ChangePassword');
                })}
              />
              <Route
                path={routePaths.sessions.error}
                component={asyncComponent(async () => {
                  return await import('../components/Session/index');
                })}
              />
            </Switch>
          </ConnectedRouter>
        </div>
      </IntlProvider>
    );
  }
}

export default AppComponent;
