import * as React from 'react';
import DatePicker from 'react-datepicker';
import { Field, GenericFieldHTMLAttributes, BaseFieldProps, WrappedFieldProps } from 'redux-form';
import * as moment from 'moment';

class XXXField extends Field<
  GenericFieldHTMLAttributes & BaseFieldProps & { placeHolder: string; showTimeSelect?: boolean }
> {}
class SpeysDatePickerInnerComponent extends React.Component<
  WrappedFieldProps & GenericFieldHTMLAttributes & BaseFieldProps & { placeHolder: string; showTimeSelect?: boolean }
> {
  constructor(props: any) {
    super(props);

    this.clickOutside = this.clickOutside.bind(this);
  }

  // this solves the interference with react-select https://github.com/Hacker0x01/react-datepicker/issues/730
  clickOutside(): void {
    // eslint-disable-next-line react/no-string-refs
    const picker: any = this.refs.picker; // a stupid hack because typescript is stupid.
    picker.cancelFocusInput();
    picker.setOpen(false);
  }

  render(): JSX.Element {
    const { input, placeHolder, className, showTimeSelect } = this.props;
    const { value, onChange } = input;

    const momentDate = !value ? null : moment.isMoment(value) ? value : moment.utc(value);

    return (
      <DatePicker
        utcOffset={0}
        className={className}
        dateFormat={showTimeSelect ? 'DD.MM.YYYY HH:mm:ss' : 'DD.MM.YYYY'}
        selected={momentDate}
        placeholderText={placeHolder}
        ref="picker" // eslint-disable-line react/no-string-refs
        onClickOutside={this.clickOutside}
        showTimeSelect={false}
        onChange={(date: moment.Moment) => onChange(date)}
      />
    );
  }
}

/* TODO: OBSOLETE, don't use this */
class SpeysDatePickerComponentProps {
  name: string;
  placeHolder: string;
  className: string;
  showTimeSelect?: boolean;
}
export class SpeysDatePickerComponent extends React.Component<SpeysDatePickerComponentProps> {
  render() {
    const { name, placeHolder, className, showTimeSelect } = this.props;
    return (
      <XXXField
        name={name}
        className={className}
        component={SpeysDatePickerInnerComponent}
        placeHolder={placeHolder}
        showTimeSelect={showTimeSelect}
      />
    );
  }
}
