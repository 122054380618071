/**
 * App Header
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Link } from 'react-router-dom';
import screenfull from 'screenfull';
import Tooltip from '@material-ui/core/Tooltip';
import MenuIcon from '@material-ui/icons/Menu';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';
import LanguageProvider from './LanguageProvider';
import { default as LocaleUtils } from '../../helpers/LocaleUtilities';

// actions
import { collapsedSidebarAction } from '../../app/actions/template';

// helpers
import { getAppLayout } from '../../helpers/helpers';
import AuthUtils from '../../helpers/AuthUtils/AuthUtils';

class Header extends Component {
  state = {
    languageWidgetOpen: false,
    customizer: false,
    isMobileSearchFormVisible: false,
    navCollapsed: false,
  };

  // function to change the state of collapsed sidebar
  onToggleNavCollapsed = (event) => {
    const val = !this.state.navCollapsed;
    this.props.collapsedSidebarAction(val);
    this.setState({ navCollapsed: val });
  };

  // open dashboard overlay
  openDashboardOverlay() {
    $('.dashboard-overlay').toggleClass('d-none');
    $('.dashboard-overlay').toggleClass('show');
    if ($('.dashboard-overlay').hasClass('show')) {
      $('body').css('overflow', 'hidden');
    } else {
      $('body').css('overflow', '');
    }
  }

  // close dashboard overlay
  closeDashboardOverlay() {
    $('.dashboard-overlay').removeClass('show');
    $('.dashboard-overlay').addClass('d-none');
    $('body').css('overflow', '');
  }

  // toggle screen full
  toggleScreenFull() {
    screenfull.toggle();
  }

  // mobile search form
  openMobileSearchForm() {
    this.setState({ isMobileSearchFormVisible: true });
  }

  handleSetLanguage(language) {
    // do nothing
  }

  render() {
    const { isMobileSearchFormVisible } = this.state;
    $('body').click(function () {
      $('.dashboard-overlay').removeClass('show');
      $('.dashboard-overlay').addClass('d-none');
      $('body').css('overflow', '');
    });
    const { horizontalMenu, agencyMenu, location, flag, changeLanguage } = this.props;
    const demoText = {
      color: 'red',
      margin: '0px',
    };
    return (
      <AppBar position="static" className="rct-header">
        <Toolbar className="d-flex justify-content-between w-100 pl-0">
          <div className="d-flex align-items-center">
            {(horizontalMenu || agencyMenu) && (
              <div className="site-logo">
                <Link to="/" className="logo-mini">
                  <img
                    src={require('../../assets/img/appLogo.png')}
                    className="mr-15"
                    alt="site logo"
                    width="35"
                    height="35"
                  />
                </Link>
                <Link to="/" className="logo-normal">
                  <img
                    src={require('../../assets/img/appLogoText.png')}
                    className="img-fluid"
                    alt="site-logo"
                    width="67"
                    height="17"
                  />
                </Link>
              </div>
            )}
            {!agencyMenu && (
              <ul className="list-inline mb-0 navbar-left">
                {!horizontalMenu ? (
                  <li className="list-inline-item" onClick={(e) => this.onToggleNavCollapsed(e)}>
                    <Tooltip title="Sidebar Toggle" placement="bottom">
                      <IconButton color="inherit" mini="true" aria-label="Menu" className="hamburger">
                        <MenuIcon />
                      </IconButton>
                    </Tooltip>
                  </li>
                ) : (
                  <li className="list-inline-item">
                    <Tooltip title="Sidebar Toggle" placement="bottom">
                      <IconButton color="inherit" aria-label="Menu" className="hamburger" component={Link} to="/">
                        <i className="ti-layout-sidebar-left"></i>
                      </IconButton>
                    </Tooltip>
                  </li>
                )}
                {/*!horizontalMenu && <QuickLinks />*/}
                <li className="list-inline-item search-icon d-inline-block">
                  <IconButton mini="true" className="search-icon-btn" onClick={() => this.openMobileSearchForm()}>
                    <i className="zmdi zmdi-search"></i>
                  </IconButton>
                </li>
              </ul>
            )}
          </div>
          {window.location.href.startsWith('https://partnerportal.speysdemo.com/') ? (
            <h1 style={demoText}>
              <strong>DEMO</strong>
            </h1>
          ) : null}
          <ul className="navbar-right list-inline mb-0">
            <li className="list-inline-item summary-icon hidden">
              <Tooltip title="Summary" placement="bottom">
                <a href="#!" className="header-icon tour-step-3" onClick={() => this.openDashboardOverlay()}>
                  <i className="zmdi zmdi-info-outline"></i>
                </a>
              </Tooltip>
            </li>
            {!horizontalMenu && (
              <li className="list-inline-item hidden">
                <Tooltip title="Upgrade" placement="bottom">
                  <Button
                    component={Link}
                    to={`/${getAppLayout(location)}/pages/pricing`}
                    className="upgrade-btn tour-step-4 text-white"
                    color="primary"
                  >
                    Upgrade
                  </Button>
                </Tooltip>
              </li>
            )}
            <li className="list-inline-item setting-icon hidden">
              <Tooltip title="Chat" placement="bottom">
                <IconButton aria-label="settings" onClick={() => this.setState({ customizer: true })}>
                  <i className="zmdi zmdi-comment"></i>
                </IconButton>
              </Tooltip>
            </li>
            {/*AuthUtils.isAdmin() ? (
                            <li className="list-inline-item summary-icon">
                                <Tooltip title="Turn on translations modification" placement="bottom">
                                    <a
                                        className={!LocaleUtils.getDebug() ? "header-icon waves-effect waves-dark text-dark": "header-icon waves-effect waves-dark text-dark active"}
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        onClick={e => {
                                            LocaleUtils.toggleDebug();
                                            window.location.reload();
                                        }}
                                    >
                                        <i className="zmdi zmdi-key"/>
                                    </a>
                                </Tooltip>
                            </li>
                        ) : null*/}
            <LanguageProvider />
            <li className="list-inline-item">
              <Tooltip title="Full Screen" placement="bottom">
                <IconButton aria-label="settings" onClick={() => this.toggleScreenFull()}>
                  <i className="zmdi zmdi-crop-free"></i>
                </IconButton>
              </Tooltip>
            </li>
          </ul>
          <Drawer
            anchor={'right'}
            open={this.state.customizer}
            onClose={() => this.setState({ customizer: false })}
          ></Drawer>
        </Toolbar>
      </AppBar>
    );
  }
}

// map state to props
const mapStateToProps = ({ app }) => {
  return app;
};

export default withRouter(
  connect(mapStateToProps, {
    collapsedSidebarAction,
  })(Header),
);
