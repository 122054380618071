import { BACKEND_HOST_AUTH } from '../constants/api.constants';
import { PaginatedListResult } from '../viewModels/base';
import {
  UsersResponseViewModel,
  UserResponseViewModel,
  UserCreateOrUpdateRequestViewModel,
} from '../viewModels/driver';
import UserSelectComponentResponseViewModel from '../viewModels/driver/DriverSelectComponentResponseViewModel';
import UsersRequestViewModel from '../viewModels/driver/DriversRequestViewModel';
import ApiUtilities from '../helpers/ApiUtils';

function getRoot() {
  return `${BACKEND_HOST_AUTH()}api/partner-portal/driver`;
}

export class UserApi {
  public static getList(params: UsersRequestViewModel): Promise<PaginatedListResult<UsersResponseViewModel>> {
    return ApiUtilities.handleGet<PaginatedListResult<UsersResponseViewModel>>(getRoot(), { ...params });
  }

  public static getSelectComponentList(params: {}): Promise<Array<UserSelectComponentResponseViewModel>> {
    const URL = `${getRoot()}/select-component-values`;
    return ApiUtilities.handleGet<Array<UserSelectComponentResponseViewModel>>(URL, { ...params });
  }

  public static getSingle(id: number): Promise<UserResponseViewModel | null> {
    return ApiUtilities.handleGet<UserResponseViewModel>(`${getRoot()}/${id}`);
  }

  public static add(formValues: UserCreateOrUpdateRequestViewModel) {
    return ApiUtilities.handlePost<number>(getRoot(), formValues);
  }

  public static update(id: number, formValues: UserCreateOrUpdateRequestViewModel) {
    return ApiUtilities.handlePut<boolean>(`${getRoot()}/${id}`, formValues);
  }

  public static remove(id: number) {
    return ApiUtilities.handleDelete(`${getRoot()}/${id}`);
  }
}
