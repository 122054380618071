import actionCreatorFactory from 'typescript-fsa';
import { Dispatch } from 'redux';
import { initialize } from 'redux-form';
import { userFormName } from '../../components/User.component';

import { ErrorWrapper } from '../../../../../../../viewModels/base';
import CommonUtilities from '../../../../../../../helpers/CommonUtilities';

const actionCreator = actionCreatorFactory();
const type = 'USER/CREATE/START';
export const asyncActions = actionCreator.async<{}, { userId: number }, ErrorWrapper>(type);

export default function start(userId: number) {
  return async (dispatch: Dispatch<any>) => {
    async function mainAction() {
      dispatch(asyncActions.started({}));
      dispatch(initialize(userFormName, {}));
      dispatch(asyncActions.done({ params: {}, result: { userId } }));
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
