import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Dispatch } from 'redux';
import { deliveryFormName } from '../../components/Shipment.component';
import { DeliveryApi } from '../../../../../../../api/Delivery.api';
import { ErrorWrapper } from '../../../../../../../viewModels/base';

import { DeliveryState, REDUCER_KEY__DELIVERY } from '../../Shipment.reducer';
import { toastr } from 'react-redux-toastr';

import CommonUtilities from '../../../../../../../helpers/CommonUtilities';

const actionCreator = actionCreatorFactory();
export const asyncActions = actionCreator.async<{}, {}, ErrorWrapper>('DELIVERY/EDIT/SUBMIT');

export default function submit(onSuccess: Function, confirmAlso: boolean) {
  return async (dispatch: Dispatch<AnyAction>, getState: Function) => {
    async function mainAction() {
      dispatch(asyncActions.started({ confirmLoading: true }));

      const state = getState();
      const formValues = state.form[deliveryFormName].values || {};
      if (!formValues) {
        throw new ErrorWrapper('Nothing was filled');
      }

      const currentState: DeliveryState = state[REDUCER_KEY__DELIVERY];
      if (!currentState.deliveryId) {
        throw new ErrorWrapper('Id is missing');
      }

      if (!confirmAlso) {
        await DeliveryApi.update(currentState.deliveryId, formValues);
      } else {
        await DeliveryApi.updateAndConfirm(currentState.deliveryId, formValues);
      }
      dispatch(asyncActions.done({ params: { confirmLoading: false }, result: {} }));
      toastr.success('Success', 'Item was successfully updated');
      if (onSuccess) {
        onSuccess();
      }
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
      toastr.error('Error', 'Item was not updated');
      CommonUtilities.scrollToTop();
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
