import { BACKEND_HOST_MAIN } from '../constants/api.constants';
import ApiUtilities from '../helpers/ApiUtils';
import DriverViewResponseViewModel from 'viewModels/driverView/DriverViewResponseViewModel';
import { DeliveryStatus } from 'viewModels/enums';
import ChangeShipmentStatusResponseViewModel from 'viewModels/driverView/ChangeShipmentStatusResponseViewModel';
import UploadDriverPodFilesResponseViewModel from '../viewModels/driverView/UploadDriverPodFilesResponseViewModel';

function getRoot(): string {
  return `${BACKEND_HOST_MAIN()}api/partner-portal/driver`;
}

export class DriverViewApi {
  public static getDriverView(driverViewGuid: string): Promise<DriverViewResponseViewModel> {
    return ApiUtilities.handleGet<DriverViewResponseViewModel>(`${getRoot()}/driver-view/${driverViewGuid}`, {});
  }

  public static changeShipmentStatus(
    driverViewGuid: string,
    shipmentId: number,
    status: DeliveryStatus,
  ): Promise<ChangeShipmentStatusResponseViewModel> {
    return ApiUtilities.handlePut<ChangeShipmentStatusResponseViewModel>(`${getRoot()}/shipment/${shipmentId}`, {
      driverViewGuid,
      status,
    });
  }

  public static uploadPodFiles(
    driversKey: string,
    shipmentId: number,
    files: FileList,
  ): Promise<UploadDriverPodFilesResponseViewModel> {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append(`files[${i}]`, files[i]);
    }

    const driversKeyHeader = { 'speys-drivers-key': driversKey };
    return ApiUtilities.handleFilePost<UploadDriverPodFilesResponseViewModel>(
      `${getRoot()}/shipment/${shipmentId}/upload-pod-files`,
      formData,
      driversKeyHeader,
    );
  }

  public static getPodImageUrl(driversKey: string, shipmentId: number, podId: number, tiny: boolean): string {
    return `${getRoot()}/${driversKey}/shipment/${shipmentId}/pod/${podId}?size=${tiny ? 'tiny' : 'full'}`;
  }
}
