import * as React from 'react';
import BaseFormFields from '../../../../../helpers/FormFieldUtilities';
import VehiclesRequestViewModel from '../../../../../viewModels/vehicle/VehiclesRequestViewModel';
import { MouseEventHandler } from 'react';
import { basicStatusOptions } from '../../../../../seed';
import LocaleUtilities from '../../../../../helpers/LocaleUtilities';

class FormFields extends BaseFormFields<keyof VehiclesRequestViewModel> {
  getName = () => this.defaultField('name');
  getStatus = () => this.comboboxStringField('status', basicStatusOptions);
}

interface VehiclesSearchFieldsComponentProps {
  handleSearch: MouseEventHandler<HTMLButtonElement>;
}

export default class VehiclesSearchFieldsComponent extends React.Component<VehiclesSearchFieldsComponentProps> {
  render() {
    const { handleSearch } = this.props;
    const formFields = new FormFields();

    return (
      <div className="form-material pt-20">
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <label>{LocaleUtilities.i18n('Name')}</label>
              {formFields.getName()}
            </div>
          </div>
          <div className="col-md-3">
            <div>
              <label>{LocaleUtilities.i18n('Status')}</label>
              {formFields.getStatus()}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-align-right display-block">
            <button className="btn btn-rounded btn-info pull-right" onClick={handleSearch}>
              {LocaleUtilities.i18n('button-Search', 'base.button')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
