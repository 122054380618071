import * as React from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { Link } from 'react-router-dom';
import ErrorBlock from '../components/SpeysCloud.ErrorBlock.component';
import { ErrorWrapper } from '../viewModels/base';
import LocaleUtilities from './LocaleUtilities';

export default class ReusableComponentUtilities {
  public static getBackToListComponent(path: string) {
    return (
      <span>
        <Link to={path} className="ml-10 text-white">
          <i className=" ti-angle-left" /> {LocaleUtilities.i18n('Back To List', 'base')}
        </Link>
      </span>
    );
  }

  public static getErrorRowComponent(
    errorWrapper: ErrorWrapper | undefined,
    errorTranslationKey?: string,
    errorTranslationGroup?: string,
  ) {
    return (
      <Row>
        <Col md={12}>
          <ErrorBlock
            error={errorWrapper}
            errorTranslationKey={errorTranslationKey}
            errorTranslationGroup={errorTranslationGroup}
          />
        </Col>
      </Row>
    );
  }
}
