import actionCreatorFactory from 'typescript-fsa';
import { Dispatch } from 'redux';
import { toastr } from 'react-redux-toastr';

import { DeliveryApi } from '../../../../../../api/Delivery.api';
import { DeliveriesReducerState, REDUCER_KEY__DELIVERIES } from '../Shipments.reducer';
import fetchAsync from './Shipment.fetch.action';
import CommonUtilities from '../../../../../../helpers/CommonUtilities';

const actionCreator = actionCreatorFactory();
export const asyncActions = actionCreator.async<{}, {}, {}>('DELIVERIES/DELETE/SUBMIT');

export default function submit() {
  return async (dispatch: Dispatch<any>, getState: Function) => {
    const currentState: DeliveriesReducerState = getState()[REDUCER_KEY__DELIVERIES];

    async function mainAction() {
      dispatch(asyncActions.started({}));
      await DeliveryApi.remove(currentState.pendingDeleteId);
      dispatch(asyncActions.done({ params: {}, result: {} }));
      toastr.success('Success', 'Item was successfully deleted');
      dispatch(fetchAsync(null, false, false));
    }

    async function cancelAction(exception: any) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
      toastr.error('Error', 'Item was not deleted');
    }

    await CommonUtilities.tryCatchWrapper(mainAction, cancelAction);
  };
}
