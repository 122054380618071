import actionCreatorFactory from 'typescript-fsa';
import { Dispatch } from 'redux';

import fetchAsync from './Users.fetch.action';
import { REDUCER_KEY__USERS, UsersState } from '../Users.reducer';
import { UserApi } from '../../../../../../api/User.api';
import { toastr } from 'react-redux-toastr';
import CommonUtilities from '../../../../../../helpers/CommonUtilities';

const actionCreator = actionCreatorFactory();
export const asyncActions = actionCreator.async<{}, {}, {}>('USERS/DELETE/SUBMIT');

export default function submit() {
  return async (dispatch: Dispatch<any>, getState: Function) => {
    const currentState: UsersState = getState()[REDUCER_KEY__USERS];

    async function mainAction() {
      dispatch(asyncActions.started({}));
      await UserApi.remove(currentState.pendingDeleteId);
      dispatch(asyncActions.done({ params: {}, result: {} }));
      toastr.success('Success', 'Item was successfully deleted');
      dispatch(fetchAsync(null, false, false));
    }

    async function cancelAction(exception: any) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
      toastr.error('Error', 'Item was not deleted');
    }

    await CommonUtilities.tryCatchWrapper(mainAction, cancelAction);
  };
}
