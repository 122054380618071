import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { asyncActions as startActions } from './actions/App.start.action';
import { asyncActions as logoutActions } from './actions/App.logout.submit.action';
import { asyncActions as loginActions } from './actions/App.login.submit.action';
import { ErrorWrapper } from '../viewModels/base';
import { NullableLocalStorageUserModel } from '../helpers/AuthUtils';
import { asyncActions as networkChangedActions } from './actions/App.networkChanged.action';

export const REDUCER_NAME__APP = 'app';
export interface AppReduxState {
  loading: boolean;
  error?: ErrorWrapper;
  user: NullableLocalStorageUserModel;
  settings: any;
  online: boolean;
}
const defaultState: AppReduxState = {
  loading: true,
  error: undefined,
  user: null,
  settings: {},
  online: true,
};
export default (state: AppReduxState = defaultState, action: Action) => {
  // Set or unset user
  if (isType(action, startActions.started)) {
    return { ...state, type: action.type };
  }
  if (isType(action, startActions.done)) {
    return { ...state, type: action.type, loading: false, user: action.payload.result.user, error: undefined };
  }
  if (isType(action, startActions.failed)) {
    return { ...state, type: action.type, loading: false, user: null, error: action.payload.error };
  }

  // Login
  if (isType(action, loginActions.started)) {
    return { ...state, type: action.type, loading: true, user: state.user };
  }
  if (isType(action, loginActions.done)) {
    return { ...state, type: action.type, loading: false, user: action.payload.result.user, error: undefined };
  }
  if (isType(action, loginActions.failed)) {
    return { ...state, type: action.type, loading: false, user: state.user, error: action.payload.error };
  }

  // Logout
  if (isType(action, logoutActions.started)) {
    return { ...state, type: action.type, loading: true, user: state.user };
  }
  if (isType(action, logoutActions.done)) {
    return { ...state, type: action.type, loading: false, user: null, error: undefined };
  }
  if (isType(action, logoutActions.failed)) {
    return { ...state, type: action.type, loading: false, user: state.user, error: action.payload.error };
  }

  // Network changed
  if (isType(action, networkChangedActions.done)) {
    return { ...state, type: action.type, online: action.payload.result.online };
  }

  return state;
};
