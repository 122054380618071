import { LOCAL_STORAGE_TOKEN_KEY } from '../constants/base.constants';
import axios, { AxiosError, AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ErrorWrapper, FailedResponseErrorWrapper, ServiceResult } from '../viewModels/base';
import DateUtilities from './DateUtilities';
import AuthUtils from './AuthUtils/AuthUtils';
import { routePaths, errorPageIgnoreList } from '../constants/api.constants';
import { matchPath } from 'react-router';

export default class ApiUtils {
  // Reusable headers, because they are used in 90%+ of all the cases
  public static getDefaultHeaders(accessToken: string | null = null) {
    const token = accessToken || localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) || null;

    return {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Speys-Application-Type': 'Driver Application',
    };
  }

  public static getFileHeaders() {
    const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) || null;
    return {
      // NB! 'Content-Type' must no exist or file upload will fail!
      Authorization: `Bearer ${token}`,
    };
  }

  //
  // Main Methods
  //

  public static async handleGet<T>(
    URL: string,
    params: Record<string, any> | null = null,
    token: string | null = null,
  ): Promise<T> {
    const fixedParams = DateUtilities.tryConvertAllMomentsToDates(params);
    const config: AxiosRequestConfig = {
      headers: ApiUtils.getDefaultHeaders(token),
      params: fixedParams,
      responseType: 'json',
    };

    const response = await ApiUtils.handleApi(axios.get<ServiceResult<T>>(URL, config));
    return ApiUtils.returnOrThrow(response);
  }

  public static async handlePost<T>(URL: string, data: object): Promise<T> {
    const config: AxiosRequestConfig = {
      headers: ApiUtils.getDefaultHeaders(),
      method: 'POST',
      responseType: 'json',
    };

    const response = await ApiUtils.handleApi(axios.post<ServiceResult<T>>(URL, JSON.stringify(data), config));
    return ApiUtils.returnOrThrow(response);
  }

  public static async handlePut<T>(URL: string, data: object | null): Promise<T> {
    const config: AxiosRequestConfig = {
      headers: ApiUtils.getDefaultHeaders(),
      method: 'PUT',
      responseType: 'json',
    };

    const response = await ApiUtils.handleApi(axios.put<ServiceResult<T>>(URL, JSON.stringify(data), config));
    return ApiUtils.returnOrThrow(response);
  }

  public static async handleDelete(URL: string): Promise<boolean> {
    const config: AxiosRequestConfig = {
      headers: ApiUtils.getDefaultHeaders(),
      method: 'DELETE',
      responseType: 'json',
    };

    const response = await ApiUtils.handleApi(axios.delete(URL, config));
    return ApiUtils.returnOrThrow(response);
  }

  public static returnOrThrow<T>(response: ServiceResult<T>): T {
    if (!response.isSuccessful) {
      const errorWrapper = new ErrorWrapper('Error occurred');
      if (response.validation) {
        errorWrapper.formValidations = response.validation;
      }
      throw errorWrapper;
    }
    return response.payload;
  }

  //
  // Edge cases
  //

  public static async handleFilePost<T>(URL: string, data: FormData, additionalHeaders?: {}): Promise<T> {
    const headers = { ...ApiUtils.getFileHeaders(), ...additionalHeaders };
    const config: AxiosRequestConfig = {
      headers: headers,
      method: 'POST',
    };

    const response = await ApiUtils.handleApi(axios.post<ServiceResult<T>>(URL, data, config));
    return ApiUtils.returnOrThrow<T>(response);
  }

  public static async handleFileGet(URL: string): Promise<Blob> {
    const data: any = await fetch(URL, {
      headers: new Headers(ApiUtils.getDefaultHeaders()),
    });
    if (!data.ok) {
      return ApiUtils.returnOrThrow(data.json());
    }
    return await data.blob();
  }

  private static navigateToErrorPage(): void {
    for (const entry of errorPageIgnoreList) {
      //Do not navigate to error page when url
      //is on ignore list.
      if (!!matchPath(window.location.pathname, entry)) {
        return;
      }
    }
    window.location.href = routePaths.sessions.error;
  }
  /**
   * Every API call goes through this method
   * @param {AxiosPromise<T>} promise
   * @param {boolean} throwT
   * @param useTokenRefreshCondition
   * @returns {Promise<T>}
   */
  public static async handleApi<T>(
    promise: AxiosPromise<T>,
    throwT = false,
    useTokenRefreshCondition = true,
  ): Promise<T> {
    function doApiCall(): Promise<T> {
      return promise
        .then(function onFulfilled(value: AxiosResponse<T>): T {
          // console.info('handleApi status code', value.status);

          if (value.status === 422) {
            console.log('Validation error');
          }

          return value.data;
        })
        .catch(function onRejected(error: AxiosError) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // toastr.error(error.response.status.toString(), 'Request was not successful');
            // console.info(error.response.data, error.response.status, error.response.headers);

            if (error.response.status === 401) {
              console.log('handleApi - status 401 - not authorized - redirecting to login');
              new AuthUtils().unsetToken();
              // TODO: push to
              location.reload();
            }

            if (throwT && error.response.data) {
              ApiUtils.navigateToErrorPage();
              throw error.response.data;
            } else {
              ApiUtils.navigateToErrorPage();
              throw new FailedResponseErrorWrapper('Request failed. Code ' + (error.response.status || 'unknown'));
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            // console.info(error.request);
            ApiUtils.navigateToErrorPage();
            throw new FailedResponseErrorWrapper('No response was received');
          } else {
            // Something happened in setting up the request that triggered an Error
            // console.info('Error', error.message);
            // toastr.error('Error', error.message);
            ApiUtils.navigateToErrorPage();
            throw new FailedResponseErrorWrapper(error.message);
          }
        });
    }

    const authUtil = new AuthUtils();
    if (authUtil.tokenNeedsRefreshing() && useTokenRefreshCondition) {
      // console.info('Refreshing token. UseTokenRefreshCondition: ', useTokenRefreshCondition);
      return authUtil.refreshToken(() => doApiCall());
    } else {
      return doApiCall();
    }
  }
}
