import { BACKEND_HOST_FILE_UPLOAD } from '../constants/api.constants';
import ApiUtilities from '../helpers/ApiUtils';
import CommonUtilities from '../helpers/CommonUtilities';

function getRoot() {
  return `${BACKEND_HOST_FILE_UPLOAD()}api/file-upload`;
}

export class FileApi {
  public static uploadFiles(externalId: number, formData: FormData, entityName: string) {
    return ApiUtilities.handleFilePost(`${getRoot()}/${externalId}/upload-s3-files/${entityName}`, formData);
  }

  public static getFiles(externalId: number, objectName: string) {
    return ApiUtilities.handleGet(`${getRoot()}/${externalId}/files/${objectName}`);
  }

  public static remove(externalId: number, fileId: number) {
    return ApiUtilities.handleDelete(`${getRoot()}/${externalId}/s3-file/${fileId}`);
  }

  public static async downloadFile(
    externalId: number,
    fileId: number,
    view: boolean,
    entityName: string,
    fileName?: string,
  ) {
    const URL = `${getRoot()}/${externalId}/download-s3-file/${fileId}/${view}/${entityName}`;
    const file = await ApiUtilities.handleFileGet(URL);
    CommonUtilities.downloadFile(file, view, fileName);
  }
}
