/* eslint-disable @typescript-eslint/camelcase */
import AuthUtils from './AuthUtils/AuthUtils';

export default class MenuUtilities {
  public static getAllowedMenuForUser(menuItem: any[]): any[] | null {
    const result: any[] = [];
    const authUtils = new AuthUtils();
    menuItem.map(function (menu, key) {
      if (authUtils.showMenuItem(menu.allowedRoles)) {
        if (menu.child_routes !== undefined && menu.child_routes.length > 0) {
          const currentSubRoutes = menu.child_routes;
          menu.child_routes = MenuUtilities.getAllowedMenuForUser(currentSubRoutes);
        }
        result.push(menu);
      }
    });
    return result;
  }
}
