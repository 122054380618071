import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Dispatch } from 'redux';
import { vehicleFormName } from '../../components/Vehicle.component';
import { ErrorWrapper } from '../../../../../../../viewModels/base';

import { VehicleState, REDUCER_KEY__VEHICLE } from '../../Vehicle.reducer';
import { VehicleApi } from '../../../../../../../api/Vehicle.api';
import { toastr } from 'react-redux-toastr';

import CommonUtilities from '../../../../../../../helpers/CommonUtilities';

const actionCreator = actionCreatorFactory();
const type = 'VEHICLE/EDIT/SUBMIT';
export const asyncActions = actionCreator.async<{}, {}, ErrorWrapper>(type);

export default function submit(onSuccess: Function) {
  return async (dispatch: Dispatch<AnyAction>, getState: Function) => {
    async function mainAction() {
      dispatch(asyncActions.started({ confirmLoading: true }));
      const state = getState();
      const currentState: VehicleState = state[REDUCER_KEY__VEHICLE];
      const formValues = state.form[vehicleFormName].values || {};
      if (!formValues) {
        throw new ErrorWrapper('Nothing was filled');
      }
      if (!currentState.companyId) {
        throw new ErrorWrapper('Id is missing');
      }
      //console.log(currentState.unitGroupId, formValues);
      await VehicleApi.update(currentState.companyId, formValues);
      dispatch(asyncActions.done({ params: { confirmLoading: false }, result: {} }));
      toastr.success('Success', 'Item was successfully updated');
      if (onSuccess) {
        onSuccess();
      }
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
      toastr.error('Error', 'Item was not updated');
      CommonUtilities.scrollToTop();
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
