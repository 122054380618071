import * as React from 'react';
import { Field } from 'redux-form';
import { ValidationFailure } from '../viewModels/base';
import ValidationUtilities from './ValidationUtilities';
import { GenericSelectOptions, SpeysSelectStringField } from '../components/Form/SpeysCloud.SelectField.component';
import { SpeysDatePickerComponent } from '../components/Form/SpeysCloud.DatepickerField.component';
import { SpeysCheckBoxComponent } from '../components/Form/SpeysCloud.CheckboxField.component';
import { SpeysCloudDatepicker2FieldComponent } from '../components/Form/SpeysCloud.Datepicker2Field.component';
import { SpeysCloudTimepicker2FieldComponent } from '../components/Form/SpeysCloud.Timepicker2Field.component';
import AutoSuggestInput from '../components/Form/SpeysCloud.AutoSuggest.component';
import { SpeysCloudTimePickerComponent } from '../components/Form/SpeysCloud.TimePicker';
import { RadioButtonValue } from '../viewModels/common/RadioButtonValue';

export default abstract class BaseFormFields<T extends string> {
  protected typeSafeName(name: T) {
    return name;
  }

  protected defaultField(name: T, placeHolder = '', prefix = '', className = 'form-control', required?: boolean) {
    return <Field className={className} name={prefix + name} component="input" placeholder={placeHolder} />;
  }

  protected defaultFieldAutoSuggest(
    name: T,
    placeHolder = '',
    prefix = '',
    className = 'form-control',
    required?: boolean,
  ) {
    const suggestions = [
      { label: 'Apple', value: 1 },
      { label: 'Aqua', value: 2 },
      { label: 'Banana', value: 3 },
      { label: 'Bean', value: 4 },
      { label: 'Date', value: 5 },
    ];
    const renderSelectField = (input: any) => <AutoSuggestInput suggestions={suggestions} {...input} />;

    return <Field className={className} name={prefix + name} component={renderSelectField} placeholder={placeHolder} />;
  }

  protected passwordField(name: T, placeHolder = '', prefix = '', className = 'form-control') {
    return (
      <Field className={className} name={prefix + name} component="input" type="password" placeholder={placeHolder} />
    );
  }

  protected dateField(
    name: T,
    placeHolder = '',
    showTimeSelect?: boolean,
    className = 'form-control',
    defaultAddHours?: number,
  ) {
    return (
      <SpeysDatePickerComponent
        name={name}
        placeHolder={placeHolder}
        className={className}
        showTimeSelect={showTimeSelect}
      />
    );
  }

  protected dateField2(
    name: T,
    placeHolder = '',
    showTimeSelect?: boolean,
    className = '',
    defaultAddHours?: number,
    customTimeSelectFunction?: Function,
  ) {
    return (
      <SpeysCloudDatepicker2FieldComponent
        name={name}
        placeHolder={placeHolder}
        className={className}
        showTimeSelect={showTimeSelect}
        defaultAddHours={defaultAddHours}
        customTimeSelectFunction={customTimeSelectFunction}
      />
    );
  }

  protected timeField2(name: T, placeHolder = '', showTimeSelect?: boolean, className = '', defaultAddHours?: number) {
    return (
      <SpeysCloudTimepicker2FieldComponent
        name={name}
        placeHolder={placeHolder}
        className={className}
        showTimeSelect={showTimeSelect}
        defaultAddHours={defaultAddHours}
      />
    );
  }

  protected timePicker(name: T, placeHolder = '', showTimeSelect?: boolean, className = '', defaultAddHours?: number) {
    return (
      <SpeysCloudTimePickerComponent
        name={name}
        placeHolder={placeHolder}
        className={className}
        showTimeSelect={showTimeSelect}
        defaultAddHours={defaultAddHours}
      />
    );
  }

  protected textArea(name: T, className = 'form-control', rows = 5) {
    return <Field name={name} className={className} rows={rows} component="textarea" />;
  }

  protected comboboxStringField(name: T, options: GenericSelectOptions<string>) {
    return <SpeysSelectStringField name={name} options={options} />;
  }

  protected checkboxField(name: T, label: any, prefix = '') {
    return <SpeysCheckBoxComponent name={prefix + name} label={label} />;
  }

  protected radioButtonField(
    name: T,
    options: RadioButtonValue[],
    label: any,
    prefix = '',
    currentValue = '',
    fn?: Function,
  ) {
    return options.map((member, key) => (
      <div className={'form-check'} key={key}>
        <label className="form-check-label">
          <Field
            name={prefix + name}
            component={'input'}
            type="radio"
            value={member.value}
            checked={currentValue.toString() === member.value.toString() ? true : false}
            onChange={(event) => {
              if (event === undefined) {
                return;
              }

              if (fn !== undefined) {
                fn(event.target.value);
              }
              // console.log("This is the new value of field myField: " + event.target.value);
              // props.input.onChange(event); // <-- Propagate the event
            }}
          />{' '}
          {member.label}
        </label>
      </div>
    ));
  }
}

export class FormFieldUtilities {
  private _formErrors: ValidationFailure[];
  private _formErrorPropertyNames: Array<string>;

  constructor(formErrors: Array<ValidationFailure>, formErrorPropertyNames: Array<string>) {
    this._formErrors = formErrors;
    this._formErrorPropertyNames = formErrorPropertyNames;
  }

  getField(label: any, field: JSX.Element, errorKey: string, requiered?: boolean) {
    return (
      <div className={`form-group ${ValidationUtilities.hasError(this._formErrorPropertyNames, errorKey)}`}>
        <label>
          {label}
          {requiered !== null && requiered ? <span className="required">*</span> : null}
        </label>
        {field}
        {ValidationUtilities.getError(this._formErrors, errorKey)}
      </div>
    );
  }
}
