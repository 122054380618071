import * as React from 'react';

interface Props {
  isLoading: boolean;
  showOverlayType?: string;
  error: any;
}

class SpeysCloudLoadingComponent extends React.Component<Props> {
  render() {
    const { isLoading, error } = this.props;
    if (isLoading) {
      return (
        <div className={this.props.showOverlayType !== undefined ? `overlay ${this.props.showOverlayType}` : 'overlay'}>
          <div className="content-spinner">
            <div className="bounce1" />
            <div className="bounce2" />
            <div className="bounce3" />
          </div>
        </div>
      );
    } else if (error) {
      return <div>Sorry, there was a problem loading the page.</div>;
    } else {
      return null;
    }
  }
}

export default SpeysCloudLoadingComponent;
