import actionCreatorFactory from 'typescript-fsa';
import { Dispatch } from 'redux';
import { initialize } from 'redux-form';
import { deliveryFormName } from '../../components/Shipment.component';
import { DeliveryApi } from '../../../../../../../api/Delivery.api';

import { ErrorWrapper, NotFoundErrorWrapper } from '../../../../../../../viewModels/base';
import CommonUtilities from '../../../../../../../helpers/CommonUtilities';
// import getFiles from '../../actions/Shipment.FileUpload.get-files.action';

const actionCreator = actionCreatorFactory();
const type = 'DELIVERY/EDIT/START';
export const asyncActions = actionCreator.async<{}, { deliveryId: number; status: number }, ErrorWrapper>(type);

export default function start(deliveryId: number) {
  return async (dispatch: Dispatch<any>) => {
    async function mainAction() {
      dispatch(asyncActions.started({}));
      const delivery = await DeliveryApi.getSingle(deliveryId);
      if (!delivery) {
        throw new NotFoundErrorWrapper(`Item with Id ${deliveryId} not found`);
      }
      dispatch(initialize(deliveryFormName, delivery));
      dispatch(asyncActions.done({ params: {}, result: { deliveryId: deliveryId, status: delivery.status } }));
      // dispatch(getFiles(deliveryId, undefined));
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
