import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Dispatch } from 'redux';

import { ErrorWrapper, TableData } from '../../../../../../viewModels/base';
import UsersResponseViewModel from '../../../../../../viewModels/driver/DriversResponseViewModel';
import { UserApi } from '../../../../../../api/User.api';
import UsersRequestViewModel from '../../../../../../viewModels/driver/DriversRequestViewModel';
import { SortOrder } from 'speys-react-bootstrap-table';
import { usersFormName } from '../Users.component';
import { initialize } from 'redux-form';
import RouterUtilities from '../../../../../../helpers/RouterUtilities';
import CommonUtilities from '../../../../../../helpers/CommonUtilities';

const actionCreator = actionCreatorFactory();
export const asyncActions = actionCreator.async<{}, { tableData: TableData<UsersResponseViewModel> }, ErrorWrapper>(
  'USERS/FETCH',
);

export declare type fetchAsyncType = (
  params: UsersRequestViewModel | null,
  reinitializeForm: boolean,
  changeQueryParams: boolean,
) => (dispatch: Dispatch<AnyAction>, getState: Function) => Promise<void>;

const fetchAsync: fetchAsyncType = (
  params: UsersRequestViewModel | null,
  reinitializeForm: boolean,
  changeQueryParams: boolean,
) => {
  return async (dispatch: Dispatch<AnyAction>, getState: Function) => {
    const usersRequestViewModel: UsersRequestViewModel = params || getState().form[usersFormName].values;

    async function mainAction() {
      dispatch(asyncActions.started({ loading: true }));

      if (reinitializeForm) {
        dispatch(initialize(usersFormName, usersRequestViewModel));
      }

      if (changeQueryParams) {
        RouterUtilities.changeQueryParamsInUrl(usersRequestViewModel);
      }

      const paginatedList = await UserApi.getList(usersRequestViewModel);

      const tableData = TableData.createTableDataFrom(
        paginatedList,
        usersRequestViewModel.page,
        usersRequestViewModel.sizePerPage,
      );

      dispatch(asyncActions.done({ params: {}, result: { tableData } }));
    }

    async function catchAction(exception: any) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
};

export default fetchAsync;

//
// Sorting and pagination
//

export function onSortChange(sortName: string, sortOrder: SortOrder) {
  return async (dispatch: Dispatch<any>, getState: Function) => {
    const viewModel: UsersRequestViewModel = getState().form[usersFormName].values;
    viewModel.sortName = sortName;
    viewModel.sortOrder = sortOrder;
    dispatch(fetchAsync(viewModel, false, true));
  };
}

export function onPageChange(page: number, sizePerPage: number) {
  return async (dispatch: Dispatch<any>, getState: Function) => {
    const viewModel: UsersRequestViewModel = getState().form[usersFormName].values;
    viewModel.page = page;
    viewModel.sizePerPage = sizePerPage;
    dispatch(fetchAsync(viewModel, false, true));
  };
}

export function onSizePerPageList(sizePerPage: number) {
  return async (dispatch: Dispatch<any>, getState: Function) => {
    const viewModel: UsersRequestViewModel = getState().form[usersFormName].values;
    viewModel.sizePerPage = sizePerPage;
    dispatch(fetchAsync(viewModel, false, true));
  };
}
