import { BACKEND_HOST_MAIN } from '../constants/api.constants';
import { PaginatedListResult } from '../viewModels/base';
import {
  DeliveryResponseViewModel,
  DeliveriesResponseViewModel,
  ShipmentCreateOrUpdateRequestViewModel,
  DeliveriesRequestViewModel,
} from '../viewModels/delivery';
import ApiUtilities from '../helpers/ApiUtils';
import CommonUtilities from '../helpers/CommonUtilities';
import ShipmentUpdateStatusInforamtionRequestViewModel from '../viewModels/delivery/ShipmentUpdateStatusInforamtionRequestViewModel';

function getRoot() {
  return `${BACKEND_HOST_MAIN()}api/partner-portal/shipment`;
}

function getExcelRoot() {
  return `${BACKEND_HOST_MAIN()}api/excel`;
}

export class DeliveryApi {
  public static getList(params: DeliveriesRequestViewModel): Promise<PaginatedListResult<DeliveriesResponseViewModel>> {
    return ApiUtilities.handleGet<PaginatedListResult<DeliveriesResponseViewModel>>(getRoot(), { ...params });
  }

  public static getSingle(id: number): Promise<DeliveryResponseViewModel | null> {
    return ApiUtilities.handleGet<DeliveryResponseViewModel>(`${getRoot()}/${id}`);
  }

  public static add(formValues: ShipmentCreateOrUpdateRequestViewModel) {
    return ApiUtilities.handlePost<number>(getRoot(), formValues);
  }

  public static addAndConfirm(formValues: ShipmentCreateOrUpdateRequestViewModel) {
    return ApiUtilities.handlePost<number>(`${getRoot()}/confirm`, formValues);
  }

  public static update(id: number, formValues: ShipmentCreateOrUpdateRequestViewModel) {
    return ApiUtilities.handlePut<boolean>(`${getRoot()}/${id}`, formValues);
  }

  public static updateAndConfirm(id: number, formValues: ShipmentCreateOrUpdateRequestViewModel) {
    return ApiUtilities.handlePut<boolean>(`${getRoot()}/${id}/confirm`, formValues);
  }

  public static changeStatus(id: number, statusId: number) {
    return ApiUtilities.handlePost<boolean>(`${getRoot()}/update-status/${id}`, { status: statusId });
  }

  public static changeStatusInformation(id: number, formValues: ShipmentUpdateStatusInforamtionRequestViewModel) {
    return ApiUtilities.handlePost<boolean>(`${getRoot()}/update-status-information/${id}`, formValues);
  }

  public static remove(id: number) {
    return ApiUtilities.handleDelete(`${getRoot()}/${id}`);
  }

  public static async downloadFile(id: number) {
    const URL = `${getRoot()}/${id}/pdf`;
    const file = await ApiUtilities.handleFileGet(URL);
    const filename = '4pl_shipment_' + id;
    CommonUtilities.downloadFile(file, false, filename);
  }

  public static async downloadExcelFile(id: number) {
    const URL = `${getExcelRoot()}/${id}/xsped`;
    const file = await ApiUtilities.handleFileGet(URL);
    const filename = '4pl_shipment_' + id + '.xlsx';
    CommonUtilities.downloadFile(file, false, filename);
  }

  public static ImportExcelRows(formData: FormData): Promise<DeliveryResponseViewModel | null> {
    return ApiUtilities.handleFilePost(`${getRoot()}/import-from-excel/`, formData);
  }
}
