import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { asyncActions as fetchAsyncActions } from './actions/Request.fetch.action';
import { asyncActions as updateStartActions } from './actions/Request.update.start.action';
import { asyncActions as updatePriceActions } from './actions/Request.price.action';
import { ErrorWrapper, TableData } from '../../../../../viewModels/base';
import { RequestResponseViewModel } from '../../../../../viewModels/request';

export const REDUCER_KEY__REQUESTS = 'requests';
export interface RequestState {
  type?: string;
  loading: boolean;
  confirmLoading: boolean;
  requestId?: number;
  error?: ErrorWrapper;
  tableData: TableData<RequestResponseViewModel>;
  requestStatus: number;
  price: string;
}

const defaultState: RequestState = {
  loading: false,
  confirmLoading: false,
  tableData: TableData.getDefault<RequestResponseViewModel>(),
  requestStatus: 0,
  price: '',
};
export default (state: RequestState = defaultState, action: Action): RequestState => {
  // Fetch
  if (isType(action, fetchAsyncActions.started)) {
    return { ...state, type: action.type, loading: true };
  }
  if (isType(action, fetchAsyncActions.done)) {
    return {
      ...state,
      type: action.type,
      loading: false,
      tableData: action.payload.result.tableData,
    };
  }
  if (isType(action, fetchAsyncActions.failed)) {
    return { ...state, type: action.type, loading: false, error: action.payload.error };
  }

  // Update status

  if (isType(action, updateStartActions.started)) {
    return { ...state, type: action.type, loading: true };
  }
  if (isType(action, updateStartActions.done)) {
    return { ...state, type: action.type, loading: false, requestStatus: action.payload.result.status };
  }
  if (isType(action, updateStartActions.failed)) {
    return { ...state, type: action.type, loading: false };
  }

  //Update Price

  if (isType(action, updatePriceActions.started)) {
    return { ...state, type: action.type, loading: true };
  }
  if (isType(action, updatePriceActions.done)) {
    return { ...state, type: action.type, loading: false, price: action.payload.result.price };
  }
  if (isType(action, updatePriceActions.failed)) {
    return { ...state, type: action.type, loading: false };
  }

  return state;
};
