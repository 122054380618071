import actionCreatorFactory from 'typescript-fsa';
import { Dispatch } from 'redux';
import { initialize } from 'redux-form';
import { RequestApi } from '../../../../../../api/Request.api';
import RequestCreateOrUpdateRequestViewModel from '../../../../../../viewModels/request/RequestCreateOrUpdateRequestViewModel';
import { requestFormName } from '../Request.component';
import { ErrorWrapper, NotFoundErrorWrapper } from '../../../../../../viewModels/base';
import CommonUtilities from '../../../../../../helpers/CommonUtilities';

const actionCreator = actionCreatorFactory();
const type = 'REQUEST/EDIT/START';
export const asyncActions = actionCreator.async<
  {},
  { requestId: number; request: RequestCreateOrUpdateRequestViewModel },
  ErrorWrapper
>(type);

export default function start(requestId: number) {
  return async (dispatch: Dispatch<any>) => {
    async function mainAction() {
      dispatch(asyncActions.started({}));
      const request = await RequestApi.getSingle(requestId);
      if (!request) {
        throw new NotFoundErrorWrapper(`Item with Id ${requestId} not found`);
      }
      dispatch(initialize(requestFormName, request));
      dispatch(asyncActions.done({ params: {}, result: { requestId: requestId, request: request } }));
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
