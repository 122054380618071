import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Dispatch } from 'redux';

import { ErrorWrapper } from '../../../../../../viewModels/base';
import CommonUtilities from '../../../../../../helpers/CommonUtilities';
import { AddressApi } from '../../../../../../api/Address.api';
import { change } from 'redux-form';
import { deliveryFormName } from '../components/Shipment.component';
import { ShipmentContact } from '../../../../../../viewModels/delivery/ShipmentResponseViewModel';

const actionCreator = actionCreatorFactory();
const type = 'SHIPMENT/GET_DEBTOR_INFO';
export const asyncActions = actionCreator.async<{}, {}, ErrorWrapper>(type);

export default function fetch(contactId: number, key: string, setAsEmpty?: boolean) {
  return async (dispatch: Dispatch<AnyAction>) => {
    async function mainAction() {
      dispatch(asyncActions.started({}));

      if (setAsEmpty !== undefined && setAsEmpty === true) {
        dispatch(change(deliveryFormName, key, new ShipmentContact()));
      }

      if (setAsEmpty === undefined || setAsEmpty === false) {
        const contact = await AddressApi.getSingleDebtorContact(contactId);
        dispatch(change(deliveryFormName, key, contact));

        if (contact !== null && contact.tenantId > 0) {
          dispatch(change(deliveryFormName, 'tenantId', contact.tenantId));
        }
      }

      dispatch(asyncActions.done({ params: {}, result: {} }));
    }

    async function cancelAction(exception: any) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, cancelAction);
  };
}
