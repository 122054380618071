import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { asyncActions as createStartActions } from './New/actions/Shipment.create.start.action';
import { asyncActions as createSubmitActions } from './New/actions/Shipment.create.submit.action';
import { asyncActions as editStartActions } from './Edit/actions/Shipment.edit.start.action';
import { asyncActions as editSubmitActions } from './Edit/actions/Shipment.edit.submit.action';
import { asyncActions as getPodFilesAsyncActions } from './actions/Shipment.FileUpload.get-pod-files.action';
import { createCancel } from './New/actions';
import { editCancel } from './Edit/actions';
import { ErrorWrapper, TableData } from '../../../../../viewModels/base';
import {
  senderSearchCancel,
  senderSearchStart,
  senderSearchSubmitAsyncActions,
  senderToggleVisibility,
  senderAlternativeToggleVisibility,
  senderAlternativeSearchCancel,
  senderAlternativeSearchStart,
  senderAlternativeSearchSubmitAsyncActions,
  receiverSearchCancel,
  receiverSearchStart,
  receiverSearchSubmitAsyncActions,
  receiverToggleVisibility,
  receiverAlternativeToggleVisibility,
  receiverAlternativeSearchCancel,
  receiverAlternativeSearchStart,
  receiverAlternativeSearchSubmitAsyncActions,
} from './actions/Shipment.Receiver.actions';
import { asyncActions as contactSearchStartAsyncActions } from './actions/Shipment.Contact.fetch.actions';
import { asyncActions as deleteFileAsyncActions } from './actions/Shipment.FileUpload.delete-file.action';
import { asyncActions as downloadFileAsyncActions } from './actions/Shipment.FileUpload.download-file.action';
import { asyncActions as getFilesAsyncActions } from './actions/Shipment.FileUpload.get-files.action';
import { asyncActions as uploadFilesAsyncActions } from './actions/Shipment.FileUpload.upload-files.action';
import { asyncActions as changeStausAsyncActions } from './actions/Shipment.Status.actions';
import { asyncActions as importExcelRows } from './actions/Shipment.ImportExcel.action';
import { asyncActions as updateDebitorInfo } from './actions/Shipment.Update.DebtorInfo.action';
import { asyncActions as getCommentsAsyncActions } from './actions/Shipment.Comments.get-comments.action';

import AddressesResponseViewModel from '../../../../../viewModels/addressBook/AddressesResponseViewModel1';
import ShipmentLegResponseViewModel from '../../../../../viewModels/delivery/legs/ShipmentLegResponseViewModel';
import ShipmentCommentResponseViewModel from '../../../../../viewModels/delivery/comments/commentResponseViewModel';

export const REDUCER_KEY__DELIVERY = 'delivery';

export class DeliveryState {
  type?: string;
  errorWrapper?: ErrorWrapper;
  deliveryId?: number;
  deliveryStatus: number;
  pendingSenderSearch: boolean;
  senderDetailsVisible: boolean;

  pendingSenderAlternativeSearch: boolean;
  senderAlternativeDetailsVisible: boolean;

  pendingReceiverSearch: boolean;
  receiverDetailsVisible: boolean;

  pendingReceiverAlternativeSearch: boolean;
  receiverAlternativeDetailsVisible: boolean;

  loading: boolean;
  contactTableData: TableData<AddressesResponseViewModel>;

  files?: any[];
  podFiles?: any[];
  loadingFiles: boolean;
  loadingLeg: boolean;
  loadingImportExcel: boolean;
  shipmentDetailsRows: any[];
  shipmentLegs: ShipmentLegResponseViewModel[];
  loadingComments: boolean;
  comments: ShipmentCommentResponseViewModel[];
}

const defaultState: DeliveryState = {
  pendingSenderSearch: false,
  senderDetailsVisible: true,
  pendingSenderAlternativeSearch: false,
  senderAlternativeDetailsVisible: false,
  pendingReceiverSearch: false,
  receiverDetailsVisible: false,
  pendingReceiverAlternativeSearch: false,
  receiverAlternativeDetailsVisible: false,
  loading: false,
  contactTableData: TableData.getDefault<AddressesResponseViewModel>(),
  loadingFiles: false,
  loadingLeg: false,
  deliveryId: 0,
  deliveryStatus: 0,
  shipmentDetailsRows: [],
  loadingImportExcel: false,
  shipmentLegs: [],
  loadingComments: false,
  comments: [],
};
export default (state: DeliveryState = defaultState, action: Action): DeliveryState => {
  // Create actions
  if (isType(action, createCancel)) {
    return { ...state, type: action.type, loading: false, errorWrapper: undefined };
  }
  if (isType(action, createStartActions.started)) {
    return { ...state, type: action.type, loading: true, errorWrapper: undefined, podFiles: [], files: [] };
  }
  if (isType(action, createStartActions.done)) {
    return { type: action.type, loading: false, ...defaultState };
  }
  if (isType(action, createStartActions.failed)) {
    return { ...state, type: action.type, loading: false };
  }
  if (isType(action, createSubmitActions.started)) {
    return { ...state, type: action.type, loading: true, errorWrapper: undefined };
  }
  if (isType(action, createSubmitActions.done)) {
    return { ...state, type: action.type, loading: false, deliveryId: action.payload.result.deliveryId };
  }
  if (isType(action, createSubmitActions.failed)) {
    return { ...state, type: action.type, errorWrapper: action.payload.error, loading: false };
  }

  // Edit actions
  if (isType(action, editCancel)) {
    return { ...state, type: action.type, loading: false, deliveryId: undefined, deliveryStatus: 0 };
  }
  if (isType(action, editStartActions.started)) {
    return { ...state, type: action.type, loading: true };
  }
  if (isType(action, editStartActions.done)) {
    return {
      ...state,
      type: action.type,
      loading: false,
      deliveryId: action.payload.result.deliveryId,
      deliveryStatus: action.payload.result.status,
      errorWrapper: undefined,
    };
  }
  if (isType(action, editStartActions.failed)) {
    return { ...state, type: action.type, loading: false, errorWrapper: action.payload.error };
  }
  if (isType(action, editSubmitActions.started)) {
    return { ...state, type: action.type, loading: true };
  }
  if (isType(action, editSubmitActions.done)) {
    return { ...state, type: action.type, deliveryId: undefined, deliveryStatus: 0, loading: false };
  }
  if (isType(action, editSubmitActions.failed)) {
    return { ...state, type: action.type, errorWrapper: action.payload.error, loading: false };
  }

  // Sender actions
  if (isType(action, senderSearchCancel)) {
    return { ...state, type: action.type, pendingSenderSearch: false };
  }
  if (isType(action, senderSearchStart)) {
    return { ...state, type: action.type, pendingSenderSearch: true };
  }
  if (isType(action, senderSearchSubmitAsyncActions.started)) {
    return { ...state, type: action.type };
  }
  if (isType(action, senderSearchSubmitAsyncActions.done)) {
    return { ...state, type: action.type, pendingSenderSearch: false };
  }
  if (isType(action, senderSearchSubmitAsyncActions.failed)) {
    return { ...state, type: action.type };
  }
  if (isType(action, senderToggleVisibility)) {
    return { ...state, type: action.type, senderDetailsVisible: !state.senderDetailsVisible };
  }

  // Sender alternative actions
  if (isType(action, senderAlternativeSearchCancel)) {
    return { ...state, type: action.type, pendingSenderAlternativeSearch: false };
  }
  if (isType(action, senderAlternativeSearchStart)) {
    return { ...state, type: action.type, pendingSenderAlternativeSearch: true };
  }
  if (isType(action, senderAlternativeSearchSubmitAsyncActions.started)) {
    return { ...state, type: action.type };
  }
  if (isType(action, senderAlternativeSearchSubmitAsyncActions.done)) {
    return { ...state, type: action.type, pendingSenderAlternativeSearch: false };
  }
  if (isType(action, senderAlternativeSearchSubmitAsyncActions.failed)) {
    return { ...state, type: action.type };
  }
  if (isType(action, senderAlternativeToggleVisibility)) {
    return { ...state, type: action.type, senderAlternativeDetailsVisible: !state.senderAlternativeDetailsVisible };
  }

  // Receiver actions
  if (isType(action, receiverSearchCancel)) {
    return { ...state, type: action.type, pendingReceiverSearch: false };
  }
  if (isType(action, receiverSearchStart)) {
    return { ...state, type: action.type, pendingReceiverSearch: true };
  }
  if (isType(action, receiverSearchSubmitAsyncActions.started)) {
    return { ...state, type: action.type };
  }
  if (isType(action, receiverSearchSubmitAsyncActions.done)) {
    return { ...state, type: action.type, pendingReceiverSearch: false };
  }
  if (isType(action, receiverSearchSubmitAsyncActions.failed)) {
    return { ...state, type: action.type };
  }
  if (isType(action, receiverToggleVisibility)) {
    return { ...state, type: action.type, receiverDetailsVisible: !state.receiverDetailsVisible };
  }

  // Receiver alternative actions
  if (isType(action, receiverAlternativeSearchCancel)) {
    return { ...state, type: action.type, pendingReceiverAlternativeSearch: false };
  }
  if (isType(action, receiverAlternativeSearchStart)) {
    return { ...state, type: action.type, pendingReceiverAlternativeSearch: true };
  }
  if (isType(action, receiverAlternativeSearchSubmitAsyncActions.started)) {
    return { ...state, type: action.type };
  }
  if (isType(action, receiverAlternativeSearchSubmitAsyncActions.done)) {
    return { ...state, type: action.type, pendingReceiverAlternativeSearch: false };
  }
  if (isType(action, receiverAlternativeSearchSubmitAsyncActions.failed)) {
    return { ...state, type: action.type };
  }
  if (isType(action, receiverAlternativeToggleVisibility)) {
    return {
      ...state,
      type: action.type,
      receiverAlternativeDetailsVisible: !state.receiverAlternativeDetailsVisible,
    };
  }

  // Contact actions
  if (isType(action, contactSearchStartAsyncActions.started)) {
    return { ...state, type: action.type, contactTableData: TableData.getDefault<AddressesResponseViewModel>() };
  }
  if (isType(action, contactSearchStartAsyncActions.done)) {
    return { ...state, type: action.type, contactTableData: action.payload.result.tableData };
  }
  if (isType(action, contactSearchStartAsyncActions.failed)) {
    return { ...state, type: action.type };
  }

  /*
        Files
     */

  // File delete
  if (isType(action, deleteFileAsyncActions.started)) {
    return { ...state, type: action.type, loadingFiles: true };
  }
  if (isType(action, deleteFileAsyncActions.done)) {
    return { ...state, type: action.type, loadingFiles: false };
  }
  if (isType(action, deleteFileAsyncActions.failed)) {
    return { ...state, type: action.type, loadingFiles: false };
  }

  // File download
  if (isType(action, downloadFileAsyncActions.started)) {
    return { ...state, type: action.type, loadingFiles: true };
  }
  if (isType(action, downloadFileAsyncActions.done)) {
    return { ...state, type: action.type, loadingFiles: false };
  }
  if (isType(action, downloadFileAsyncActions.failed)) {
    return { ...state, type: action.type, loadingFiles: false };
  }

  // Files get
  if (isType(action, getFilesAsyncActions.started)) {
    return { ...state, type: action.type, files: [], loadingFiles: true };
  }
  if (isType(action, getFilesAsyncActions.done)) {
    return { ...state, type: action.type, files: action.payload.result.files, loadingFiles: false };
  }
  if (isType(action, getFilesAsyncActions.failed)) {
    return { ...state, type: action.type, files: [], loadingFiles: false };
  }

  // Files get POD
  if (isType(action, getPodFilesAsyncActions.started)) {
    return { ...state, type: action.type, podFiles: [], loadingFiles: true };
  }
  if (isType(action, getPodFilesAsyncActions.done)) {
    return { ...state, type: action.type, podFiles: action.payload.result.files, loadingFiles: false };
  }
  if (isType(action, getPodFilesAsyncActions.failed)) {
    return { ...state, type: action.type, podFiles: [], loadingFiles: false };
  }

  // File upload
  if (isType(action, uploadFilesAsyncActions.started)) {
    return { ...state, type: action.type, loadingFiles: true };
  }
  if (isType(action, uploadFilesAsyncActions.done)) {
    return { ...state, type: action.type, loadingFiles: false };
  }
  if (isType(action, uploadFilesAsyncActions.failed)) {
    return { ...state, type: action.type, loadingFiles: false };
  }

  // Change shipment status
  if (isType(action, changeStausAsyncActions.started)) {
    return { ...state, type: action.type, loading: true };
  }
  if (isType(action, changeStausAsyncActions.done)) {
    return { ...state, type: action.type, loading: false, deliveryStatus: action.payload.result.status };
  }
  if (isType(action, changeStausAsyncActions.failed)) {
    return { ...state, type: action.type, loading: false };
  }

  // Excel rows import
  if (isType(action, importExcelRows.started)) {
    return { ...state, type: action.type, loadingImportExcel: true };
  }
  if (isType(action, importExcelRows.done)) {
    return { ...state, type: action.type, loadingImportExcel: false };
  }
  if (isType(action, importExcelRows.failed)) {
    return { ...state, type: action.type, loadingImportExcel: false };
  }

  // Update Debitor info
  if (isType(action, updateDebitorInfo.started)) {
    return { ...state, type: action.type, loading: true };
  }
  if (isType(action, updateDebitorInfo.done)) {
    return { ...state, type: action.type, loading: false };
  }
  if (isType(action, updateDebitorInfo.failed)) {
    return { ...state, type: action.type, loading: false };
  }

  // Comments get
  if (isType(action, getCommentsAsyncActions.started)) {
    return { ...state, type: action.type, comments: [], loadingComments: true };
  }
  if (isType(action, getCommentsAsyncActions.done)) {
    return { ...state, type: action.type, comments: action.payload.result.comments, loadingComments: false };
  }
  if (isType(action, getCommentsAsyncActions.failed)) {
    return { ...state, type: action.type, comments: [], loadingComments: false };
  }
  return state;
};
