import { SelectComponentValue } from '../viewModels/common/SelectComponentValue';

const statuses: Array<SelectComponentValue> = [
  { label: 'form-Choose', value: '' },
  { label: 'shipment-status-Processing', value: 'Processing' },
  { label: 'shipment-status-Collected', value: 'Collected' },
  { label: 'shipment-status-In transit', value: 'InTransit' },
  // { label: LocaleUtilities.i18n('shipment-status-Delivering', 'shipment.status', true), value: 'Delivering'},
  { label: 'shipment-status-Delivered', value: 'Delivered' },
];

export default statuses;
