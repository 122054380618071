import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { asyncActions as changePasswordActions } from './actions/Profile.change-password.action';
import { ErrorWrapper } from '../../../../viewModels/base';

export class ProfileState {
  type?: string;
  errorWrapper?: ErrorWrapper;
  loading: boolean;
}

const defaultState: ProfileState = {
  loading: false,
  errorWrapper: undefined,
};
export const REDUCER_KEY__PROFILE = 'profile';
export default (state: ProfileState = defaultState, action: Action): ProfileState => {
  // Change password
  if (isType(action, changePasswordActions.started)) {
    return { ...state, type: action.type, loading: true };
  }
  if (isType(action, changePasswordActions.done)) {
    return { ...state, type: action.type, errorWrapper: undefined, loading: false };
  }
  if (isType(action, changePasswordActions.failed)) {
    return { ...state, type: action.type, errorWrapper: action.payload.error, loading: false };
  }

  return state;
};
