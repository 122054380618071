import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Dispatch } from 'redux';
import { toastr } from 'react-redux-toastr';

import { ErrorWrapper } from '../../../../viewModels/base';
import CommonUtilities from '../../../../helpers/CommonUtilities';
import AccountApi from '../../../../api/Account.api';

const actionCreator = actionCreatorFactory();
const type = 'PROFILE/CHANGE_PASSWORD/SUBMIT';
export const asyncActions = actionCreator.async<{}, {}, ErrorWrapper>(type);

export default function submit(formValues: any, onSuccess: Function) {
  return async (dispatch: Dispatch<AnyAction>, getState: Function) => {
    async function mainAction() {
      dispatch(asyncActions.started({}));
      await AccountApi.resetPassword(formValues);
      dispatch(asyncActions.done({ params: {}, result: {} }));
      toastr.success('Success', 'Password has been changed');
      if (onSuccess) {
        onSuccess();
      }
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
      toastr.error('Error', 'Password has not been changed');
      CommonUtilities.scrollToTop();
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
