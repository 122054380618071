import actionCreatorFactory from 'typescript-fsa';
import { Dispatch } from 'redux';
import { DeliveryApi } from '../../../../../../api/Delivery.api';

import { ErrorWrapper } from '../../../../../../viewModels/base';
import CommonUtilities from '../../../../../../helpers/CommonUtilities';
import { deliveryFormName } from '../components/Shipment.component';

const actionCreator = actionCreatorFactory();
const type = 'SHIPMENT/CHANGE_STATUS_INFORMATION';
export const asyncActions = actionCreator.async<{}, { deliveryId: number }, ErrorWrapper>(type);

import { ShipmentUpdateStatusInforamtionRequestViewModel } from '../../../../../../viewModels/delivery';

export default function changeStatusInformation(deliveryId: number, statusId: number) {
  return async (dispatch: Dispatch<any>, getState: Function) => {
    async function mainAction() {
      dispatch(asyncActions.started({}));
      const state = getState();
      const formValues = state.form[deliveryFormName].values || {};
      const viewModel = new ShipmentUpdateStatusInforamtionRequestViewModel();
      viewModel.status = statusId;
      viewModel.date = formValues.deliveredDate;
      viewModel.deliveredComment = formValues.deliveredComment;
      viewModel.deliveredSignBy = formValues.deliveredSignBy;
      await DeliveryApi.changeStatusInformation(deliveryId, viewModel);

      dispatch(asyncActions.done({ params: {}, result: { deliveryId: deliveryId } }));
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
