import * as React from 'react';
import { AllDeliveryComponentProps } from './Shipment.component';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from '@material-ui/core/Button';
import ShipmentExpandableBlockComponent from './Shipment.Expandable.Block.component';
import LocaleUtilities from '../../../../../../helpers/LocaleUtilities';
import SpeysCloudLoadingComponent from '../../../../../../components/SpeysCloud.Loading.component';
import SweetAlert from '../../../../../../components/SweetAlert/SweetAlert.component';
import IconButton from '@material-ui/core/IconButton';

export declare type ShipmentCommentsTabComponentProps = AllDeliveryComponentProps & {
  shipment: any;
  loadingComments: boolean;
  getComments: Function;
  addComment: Function;
  deleteComment: Function;
};

export default class ShipmentCommentsTabComponent extends React.Component<
  ShipmentCommentsTabComponentProps,
  {
    alert?: any;
    comment: string;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      alert: null,
      comment: '',
    };
    this.handleSaveComment = this.handleSaveComment.bind(this);
    this.handleSaveCommentSuccess = this.handleSaveCommentSuccess.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    this.props.getComments(this.props.deliveryId);
  }

  async handleSaveCommentSuccess() {
    await this.props.getComments(this.props.deliveryId);
    await this.setState({
      comment: '',
    });
  }

  async handleSaveComment() {
    await this.props.addComment(this.props.deliveryId, this.state.comment, this.handleSaveCommentSuccess);
  }

  async handleInputChange(e: any) {
    await this.setState({
      comment: e.target.value,
    });
  }

  async deleteCommentSubmit(id: number) {
    await this.props.deleteComment(id);
    await this.props.getComments(this.props.deliveryId);
  }

  deleteComment(id: number) {
    this.setState({
      alert: (
        <SweetAlert
          title={LocaleUtilities.i18n('Are you sure', 'confirm.modal')}
          type="danger"
          onConfirm={() => this.deleteCommentSubmit(id)}
          onCancel={() => this.setState({ alert: undefined })}
        >
          <p>{LocaleUtilities.i18n('Are you sure you want to remove the comment?', 'shipment.comment')}</p>
        </SweetAlert>
      ),
    });
  }

  render() {
    const inputPlaceHolder = LocaleUtilities.i18n(
      'shipment-comment-tab-Type your message',
      'shipment.comment',
      true,
    ).toString();
    return (
      <Row className={'chat-wrapper'}>
        <Col md={12} className={'chat-main-body'}>
          {this.state.alert}
          <div className="chat-footer d-flex align-items-center py-3">
            <form className="mr-3 w-100">
              <div className="mb-0 form-group">
                <input
                  id="search-msg"
                  onChange={this.handleInputChange}
                  placeholder={inputPlaceHolder}
                  type="text"
                  value={this.state.comment}
                  className="msg-input form-control"
                />
              </div>
            </form>
            <Button
              variant="contained"
              color="primary"
              className="submit-btn bg-primary"
              onClick={(e) => this.handleSaveComment()}
            >
              {LocaleUtilities.i18n('shipment-comment-tab-Send', 'shipment.comment', true)}
              <i className="zmdi zmdi-mail-send ml-2"></i>
            </Button>
          </div>
        </Col>
        <Col md={12} className={'rct-mail-wrapper'}>
          <ShipmentExpandableBlockComponent
            key={0}
            panelKey={0}
            title={LocaleUtilities.i18n('shipment-comment-tab-Comments', 'shipment.comment', true)}
            icon={'ti-comment-alt'}
            expanded={true}
          >
            <div className={'list-wrap mt-5'}>
              <SpeysCloudLoadingComponent isLoading={this.props.loadingComments} error={undefined} />
              <ul className={'list-unstyled m-0'}>
                {this.props.comments !== undefined &&
                  this.props.comments.map((member, key) => (
                    <li key={key} className={'d-flex justify-content-between align-items-center list-item'}>
                      <div className="d-flex align-items-center w-100">
                        <div className="emails media w-100">
                          <div className="avatar-wrap w-10 align-self-center">
                            <img
                              src={require(`../../../../../../assets/avatars/user-40.jpg`)}
                              alt="mail user"
                              className="rounded-circle mr-15 align-self-center"
                              width="40"
                              height="40"
                            />
                          </div>
                          <div className="media-body d-flex align-items-center w-90">
                            <div className="d-inline-block w-25">
                              <h5 className="mb-1">{member.createdBy}</h5>
                              <span className="font-xs d-inline-block">{member.createdOn}</span>
                            </div>
                            <p className="font-xs text-muted w-75 d-inline-block mb-0 mx-4">{member.comment}</p>
                          </div>
                          <div className="text-muted w-10 text-right">
                            {member.canDelete && (
                              <IconButton
                                className={'table-action-button'}
                                color="primary"
                                onClick={(e) => this.deleteComment(member.id)}
                              >
                                <i className={'ti-trash text-danger'} />
                              </IconButton>
                            )}
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </ShipmentExpandableBlockComponent>
        </Col>
      </Row>
    );
  }
}
