import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Dispatch } from 'redux';
import { toastr } from 'react-redux-toastr';

import { ErrorWrapper } from '../../../../../../viewModels/base';
import CommonUtilities from '../../../../../../helpers/CommonUtilities';
import { FileApi } from '../../../../../../api/File.api';

const actionCreator = actionCreatorFactory();
const type = 'DELIVERY/UPLOAD-FILES';
export const asyncActions = actionCreator.async<{}, {}, ErrorWrapper>(type);

export default function submit(externalId: number, files: any[], onSuccess: Function) {
  return async (dispatch: Dispatch<AnyAction>, getState: Function) => {
    async function mainAction() {
      dispatch(asyncActions.started({}));

      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append(`files[${i}]`, files[i]);
      }

      await FileApi.uploadFiles(externalId, formData, 'Shipment');
      dispatch(asyncActions.done({ params: {}, result: {} }));
      if (onSuccess) {
        onSuccess();
      }
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
      toastr.error('Error', 'Error has occurred');
      CommonUtilities.scrollToTop();
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
