import { PaginatedSearchBaseOptionsResult } from '../base';
import RouterUtilities from '../../helpers/RouterUtilities';
import { Moment } from 'moment';
import LocaleUtilities from '../../helpers/LocaleUtilities';

export default class DeliveriesRequestViewModel extends PaginatedSearchBaseOptionsResult {
  keyword: string;
  reference: string;
  company: string;
  deliveryDateFrom?: Moment;
  deliveryDateTo?: Moment;
  status: string;

  public parse(location: Location) {
    const obj = RouterUtilities.locationSearchStringParse(location);
    if (!obj) {
      return;
    }

    super.baseParse(obj);
    if ('keyword' in obj && typeof obj.keyword === 'string') {
      this.keyword = obj.keyword;
    }
    if ('company' in obj && typeof obj.company === 'string') {
      this.company = obj.company;
    }
    if ('reference' in obj && typeof obj.reference === 'string') {
      this.reference = obj.reference;
    }
    if ('deliveryDateFrom' in obj) {
      this.deliveryDateFrom = LocaleUtilities.trySetDate(obj.deliveryDateFrom);
    }
    if ('deliveryDateTo' in obj) {
      this.deliveryDateTo = LocaleUtilities.trySetDate(obj.deliveryDateTo);
    }
    if ('status' in obj && typeof obj.status === 'string') {
      this.status = obj.status;
    }
  }
}
