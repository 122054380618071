import * as React from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

interface ImageBlockProps {
  filename: string;
  height: string;
}

class ImageBlock extends React.Component<ImageBlockProps> {
  render() {
    const { filename, height } = this.props;
    return (
      <Row className="mt-10 mb-10">
        <Col md={12}>
          <div className="dashboard-banner">
            <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel">
              <div className="carousel-inner" role="listbox">
                <div className="carousel-item active">
                  <img className="d-block img-fluid" src={filename} height={height} alt="First slide" />
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

export default ImageBlock;
