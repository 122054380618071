import { BACKEND_HOST_MAIN } from '../constants/api.constants';
// import { PaginatedListResult } from '../viewModels/base';
import ApiUtilities from '../helpers/ApiUtils';
import CompanyResponseViewModel from '../viewModels/company/CompanyResponseViewModel';
import CompanyCreateOrUpdateRequestViewModel from '../viewModels/company/CompanyCreateOrUpdateRequestViewModel';
// import CommonUtilities from '../helpers/CommonUtilities';

function getRoot() {
  return `${BACKEND_HOST_MAIN()}api/booking_portal/company`;
}

export class CompanyApi {
  public static getSingle(id: number): Promise<CompanyResponseViewModel | null> {
    return ApiUtilities.handleGet<CompanyResponseViewModel>(`${getRoot()}/0`);
  }

  public static update(formValues: CompanyCreateOrUpdateRequestViewModel) {
    return ApiUtilities.handlePut<boolean>(`${getRoot()}/0`, formValues);
  }
}
