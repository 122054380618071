import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Dispatch } from 'redux';
import { toastr } from 'react-redux-toastr';

import { ErrorWrapper } from '../../../../../../viewModels/base';
import CommonUtilities from '../../../../../../helpers/CommonUtilities';
import { DeliveryApi } from '../../../../../../api/Delivery.api';

const actionCreator = actionCreatorFactory();
const type = 'DELIVERIES/DOWNLOAD-PDF';
export const asyncActions = actionCreator.async<{}, {}, ErrorWrapper>(type);

export default function submit(id: number) {
  return async (dispatch: Dispatch<AnyAction>) => {
    async function mainAction() {
      dispatch(asyncActions.started({}));
      await DeliveryApi.downloadFile(id);
      dispatch(asyncActions.done({ params: {}, result: {} }));
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
      toastr.error('Error', 'Failed to download file');
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
