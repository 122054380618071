import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Dispatch } from 'redux';
import { deliveryFormName } from '../../components/Shipment.component';
import { DeliveryApi } from '../../../../../../../api/Delivery.api';

import { ErrorWrapper } from '../../../../../../../viewModels/base';
import { toastr } from 'react-redux-toastr';
import CommonUtilities from '../../../../../../../helpers/CommonUtilities';

const actionCreator = actionCreatorFactory();
const type = 'DELIVERY/CREATE/SUBMIT';
export const asyncActions = actionCreator.async<{}, { deliveryId?: number }, ErrorWrapper>(type);

export default function submit(onSuccess: Function, confirmAlso: boolean) {
  return async (dispatch: Dispatch<AnyAction>, getState: Function) => {
    async function mainAction() {
      const formValues = getState().form[deliveryFormName].values;
      if (!formValues) {
        throw new ErrorWrapper('Nothing was filled');
      }

      dispatch(asyncActions.started({}));
      let deliveryId = 0;
      let result;
      if (!confirmAlso) {
        result = await DeliveryApi.add(formValues);
      } else {
        result = await DeliveryApi.addAndConfirm(formValues);
      }

      if (result !== undefined) {
        deliveryId = result;
      }
      dispatch(asyncActions.done({ params: {}, result: { deliveryId: deliveryId } }));
      toastr.success('Success', 'Item was successfully created');
      if (onSuccess) {
        onSuccess();
      }
    }

    async function catchAction(exception: ErrorWrapper) {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
      toastr.error('Error', 'Item was not created');
      CommonUtilities.scrollToTop();
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
