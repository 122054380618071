import { SelectComponentValue } from '../viewModels/common/SelectComponentValue';

/* There is default values for some of the types.. they are used in Delivery.component
 * if you are changing value, please go and look check the component
 * */
const types: Array<SelectComponentValue> = [
  { label: 'form-Choose', value: '0' },
  { label: 'shipment-parcel-EUR pallet', value: '1' },
  { label: 'shipment-parcel-FIN pallet', value: '2' },
  { label: 'shipment-parcel-Pallet', value: '3' },
  { label: 'shipment-parcel-Box', value: '8' },
  { label: 'shipment-parcel-Package', value: '7' },
  { label: 'shipment-parcel-463L', value: '4' },
  { label: 'shipment-parcel-Trolley', value: '5' },
  { label: 'shipment-parcel-Canister', value: '9' },
  { label: 'shipment-parcel-Full truck load', value: '10' },
];

export default types;
