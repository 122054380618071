import { DeliveryStatus, ShippingOrderStatus } from '../viewModels/enums';
import { typeOptions } from '../seed';
import { SelectComponentValue } from '../viewModels/common/SelectComponentValue';

export default class SeedUtilities {
  public static getShipmentType(type: string) {
    let title = '';
    typeOptions.forEach(function (element: SelectComponentValue) {
      if (type === element.value) {
        title = element.label;
      }
    });
    return title;
  }

  public static getStatusColor(status: string) {
    switch (status) {
      case DeliveryStatus[DeliveryStatus.MissingData]: // returns 'Collecting' string value
        return 'danger';
      case DeliveryStatus[DeliveryStatus.Processing]: // returns 'Collecting' string value
        return 'warning';
      case DeliveryStatus[DeliveryStatus.Collected]: // returns 'Collecting' string value
        return 'warning';
      case DeliveryStatus[DeliveryStatus.Received]: // returns 'Collecting' string value
        return 'warning';
      case DeliveryStatus[DeliveryStatus.Confirmed]: // returns 'Collecting' string value
        return 'warning';
      case DeliveryStatus[DeliveryStatus.PickedUp]: // returns 'Collecting' string value
        return 'warning';
      case DeliveryStatus[DeliveryStatus.InTransit]: // returns 'Collecting' string value
        return 'warning';
      case DeliveryStatus[DeliveryStatus.Delivered]: // returns 'Collecting' string value
        return 'success';
      case DeliveryStatus[DeliveryStatus.Canceled]: // returns 'Collecting' string value
        return 'inverse';
      case DeliveryStatus[DeliveryStatus.Draft]: // returns 'Collecting' string values
        return 'danger';

      default:
        return 'secondary';
    }
  }

  public static getStatusColorByNumber(status: number) {
    return this.getStatusColor(this.getStatusStringByNumber(status));
  }

  public static getStatusStringByNumber(status: number) {
    switch (status) {
      case DeliveryStatus.MissingData: // returns 'Collecting' string value
        return 'MissingData';
      case DeliveryStatus.Collected: // returns 'Collecting' string value
        return 'Collecting';
      case DeliveryStatus.Processing: // returns 'Processing' string value
        return 'Processing';
      case DeliveryStatus.Draft: // returns 'Collecting' string value
        return 'Draft';
      case DeliveryStatus.Received: // returns 'Collecting' string value
        return 'Received';
      case DeliveryStatus.Confirmed: // returns 'Collecting' string value
        return 'Confirmed';
      case DeliveryStatus.PickedUp: // returns 'Collecting' string value
        return 'PickedUp';
      case DeliveryStatus.InTransit: // returns 'Collecting' string value
        return 'InTransit';
      case DeliveryStatus.Delivered: // returns 'Collecting' string value
        return 'Delivered';
      case DeliveryStatus.Canceled: // returns 'Collecting' string value
        return 'Canceled';
      default:
        return '';
    }
  }

  public static getShippingOrderType(type: string) {
    let title = '';
    typeOptions.forEach(function (element: SelectComponentValue) {
      if (type === element.value) {
        title = element.label;
      }
    });
    return title;
  }

  public static getShippingOrderColor(status: string) {
    switch (status) {
      case ShippingOrderStatus[ShippingOrderStatus.New]: // returns 'Collecting' string value
        return 'secondary';
      case ShippingOrderStatus[ShippingOrderStatus.InProgress]: // returns 'Collecting' string value
        return 'info';
      case ShippingOrderStatus[ShippingOrderStatus.PickedUp]: // returns 'Collecting' string value
        return 'primary';
      case ShippingOrderStatus[ShippingOrderStatus.InProgress]: // returns 'Collecting' string value
        return 'primary';
      case ShippingOrderStatus[ShippingOrderStatus.Delivered]: // returns 'Collecting' string value
        return 'success';
      case ShippingOrderStatus[ShippingOrderStatus.Invoiced]: // returns 'Collecting' string value
        return 'success';
      case ShippingOrderStatus[ShippingOrderStatus.Canceled]: // returns 'Collecting' string value
        return 'inverse';

      default:
        return 'secondary';
    }
  }

  public static getShippingOrderStatusColorByNumber(status: number) {
    return this.getShippingOrderColor(this.getShippingOrderStatusStringByNumber(status));
  }

  public static getShippingOrderStatusStringByNumber(status: number) {
    switch (status) {
      case ShippingOrderStatus.New: // returns 'Collecting' string value
        return 'New';
      case ShippingOrderStatus.Canceled: // returns 'Collecting' string value
        return 'Canceled';
      case ShippingOrderStatus.Delivered: // returns 'Collecting' string value
        return 'Delivered';
      case ShippingOrderStatus.PickedUp: // returns 'Collecting' string value
        return 'PickedUp';
      case ShippingOrderStatus.InProgress: // returns 'Collecting' string value
        return 'InProgress';
      case ShippingOrderStatus.Invoiced: // returns 'Collecting' string value
        return 'Invoiced';
      default:
        return '';
    }
  }
}
