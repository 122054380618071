import countryOptions from './CountryOptions';
import seedOptions from './StatusOptions';
import typeOptions from './TypeOptions';
import { RadioButtonValue } from '../viewModels/common/RadioButtonValue';
import { ShipmentType } from '../viewModels/enums';
import LocaleUtilities from '../helpers/LocaleUtilities';

const roleOptions = [{ label: 'Partner Driver', value: 'Partner Driver' }];

const roleTenantAdminOptions = [{ label: 'Partner Driver', value: 'Partner Driver' }];

const basicStatusOptions = [
  { label: 'Active', value: 'Active' },
  { label: 'Inactive', value: 'Inactive' },
];

const shipmentTypes: RadioButtonValue[] = [
  { label: LocaleUtilities.i18n('shipment-type-Sender', 'shipment.details'), value: ShipmentType.Sender.toString() },
  {
    label: LocaleUtilities.i18n('shipment-type-Receiver', 'shipment.details'),
    value: ShipmentType.Receiver.toString(),
  },
];

export {
  countryOptions,
  seedOptions,
  typeOptions,
  roleOptions,
  roleTenantAdminOptions,
  basicStatusOptions,
  shipmentTypes,
};
