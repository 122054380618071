/* eslint-disable @typescript-eslint/camelcase */
import {
  AppScope,
  LOCAL_STORAGE_APP_SCOPE,
  LOCAL_STORAGE_PROFILE_KEY,
  LOCAL_STORAGE_REFRESH_TOKEN_KEY,
  LOCAL_STORAGE_TOKEN_KEY,
  LOCAL_STORAGE_YOU_WERE_LOGGED_OFF_MESSAGE,
} from '../../constants/base.constants';
import * as moment from 'moment';
import { Duration } from 'moment';
import axios, { AxiosResponse } from 'axios';
import { BACKEND_HOST_AUTH, endpoints } from '../../constants/api.constants';
import * as qs from 'qs';
import { TokenInfo } from '../../api/Account.api';
import IAuthUtils from './IAuthUtils';

export enum UserModelAccountStatus {
  notSelected = 0,
  inactive = 10,
  active = 20,
}

export enum UserModelRoles {
  partnerAdmin = 60,
}

export class LocalStorageUserModel {
  username: string;
  email: string;
  roles: UserModelRoles[] = [];
  accountStatus?: UserModelAccountStatus = UserModelAccountStatus.notSelected;
  expiresOn?: Date;
  halfTimeExpiresOn?: Date;
}

export declare type NullableLocalStorageUserModel = LocalStorageUserModel | null;

export class AuthUtils implements IAuthUtils {
  public static isAdmin(): boolean {
    const cl = new AuthUtils();
    const user1 = cl.getCurrentUser();
    return user1 ? user1.roles.indexOf(UserModelRoles.partnerAdmin) > -1 : false;
  }

  isCurrentUserLoggedIn(userParam: NullableLocalStorageUserModel = null): boolean {
    const user = userParam || this.getCurrentUser();
    return user != null; // && !_.isEmpty(user);
  }

  showMenuItem(roleNames: UserModelRoles[], userParam: NullableLocalStorageUserModel = null): boolean {
    if (roleNames.length == 0) {
      return true;
    }
    const userRoles = this.getCurrentUserRoles(userParam);
    const result = userRoles.filter((value) => roleNames.indexOf(value) > -1); // intersection
    return result.length > 0;
  }

  hasValidRole(roleNames: UserModelRoles[], userParam: NullableLocalStorageUserModel = null): boolean {
    const userRoles = this.getCurrentUserRoles(userParam);
    const result = userRoles.filter((value) => roleNames.indexOf(value) > -1); // intersection
    return result.length > 0;
  }

  getCurrentUser(): NullableLocalStorageUserModel {
    try {
      const profileStr = localStorage.getItem(LOCAL_STORAGE_PROFILE_KEY);
      if (!profileStr) {
        return null;
      }
      return JSON.parse(profileStr) as LocalStorageUserModel;
    } catch (ex) {
      console.error(ex);
      return null;
    }
  }

  getRefreshToken(): string | null {
    return localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
  }

  setToken(token: string, user: LocalStorageUserModel, refreshToken: string): void {
    localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
    localStorage.setItem(LOCAL_STORAGE_PROFILE_KEY, JSON.stringify(user));
    localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY, refreshToken);
  }

  setTokenOnly(token: string): void {
    localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
  }

  setExpirationDateAndHalfExpirationDateOnly(expiresIn: number) {
    const user = this.getCurrentUser();
    if (user) {
      const dateData = this.calculateExpirationDateAndHalfExpirationDate(expiresIn);
      user.expiresOn = dateData.expiresOn;
      user.halfTimeExpiresOn = dateData.halfTimeExpiresOn;
      localStorage.setItem(LOCAL_STORAGE_PROFILE_KEY, JSON.stringify(user));
    }
  }

  unsetToken(): void {
    localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
    localStorage.removeItem(LOCAL_STORAGE_PROFILE_KEY);
    localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
  }

  setYouWereLoggedOffMessage(): void {
    localStorage.setItem(LOCAL_STORAGE_YOU_WERE_LOGGED_OFF_MESSAGE, 'You were logged off');
  }

  popYouWereLoggedOffMessage(): string | null {
    const message = localStorage.getItem(LOCAL_STORAGE_YOU_WERE_LOGGED_OFF_MESSAGE);
    if (!message) {
      return null;
    }
    localStorage.removeItem(LOCAL_STORAGE_YOU_WERE_LOGGED_OFF_MESSAGE);
    return message;
  }

  getHalfExpirationDateUtc(): Date | undefined {
    const user = this.getCurrentUser();
    if (!user) {
      return undefined;
    }
    const halfExpirationDate = user.halfTimeExpiresOn;
    if (!halfExpirationDate) {
      return undefined;
    }
    return halfExpirationDate;
  }

  getExpirationDateUtc(): Date | undefined {
    const user = this.getCurrentUser();
    if (!user) {
      return undefined;
    }
    const expiresOn = user.expiresOn;
    if (!expiresOn) {
      return undefined;
    }
    return expiresOn;
  }

  tokenNeedsRefreshing(): boolean {
    const halfExpirationDate = this.getHalfExpirationDateUtc();
    if (!halfExpirationDate) {
      return false;
    }
    const momentHalfExpirationDateUtc = moment.utc(halfExpirationDate);
    const momentNowUtc = moment.utc();
    return momentNowUtc >= momentHalfExpirationDateUtc;
  }

  // secondsLeftTillTokenExpiration(): number {
  //     const expiredOn = this.getExpirationDateUtc();
  //     if (!expiredOn) {
  //         return 0;
  //     }
  //     const momentExpiredOnUtc = moment.utc(expiredOn);
  //     const momentNowUtc = moment.utc();
  //     return moment.duration(momentExpiredOnUtc.diff(momentNowUtc)).seconds();
  // }

  getTimeLeft(): Duration {
    const expiresOn = this.getExpirationDateUtc();
    const expiresOnUtc = moment.utc(expiresOn);
    const nowUtc = moment.utc();
    return moment.duration(expiresOnUtc.diff(nowUtc));
  }

  calculateExpirationDateAndHalfExpirationDate(expiresIn: number): { expiresOn: Date; halfTimeExpiresOn: Date } {
    const expiresOn = new Date(new Date().getTime() + expiresIn * 1000);
    const halfTimeExpiresOn = new Date(new Date().getTime() + (expiresIn / 2) * 1000);
    return { expiresOn, halfTimeExpiresOn };
  }

  refreshToken<T>(callback: () => Promise<T>): Promise<T> {
    const data = {
      grant_type: 'refresh_token',
      refresh_token: this.getRefreshToken(),
      scope: 'openid offline_access',
    };
    const p = axios.post<TokenInfo>(BACKEND_HOST_AUTH() + endpoints.auth.login, qs.stringify(data));
    return p.then(
      (value: AxiosResponse<TokenInfo>): Promise<T> => {
        console.info('Token refreshed. New token data', value.data);
        this.setTokenOnly(value.data.access_token);
        this.setExpirationDateAndHalfExpirationDateOnly(value.data.expires_in);
        return callback();
      },
    );
  }

  /**
   * Should the page be displayed to admin or user? It is decided using this variable. Default is customer.
   * @param scope
   */
  public static setAppScope(scope: AppScope) {
    localStorage.setItem(LOCAL_STORAGE_APP_SCOPE, scope ? scope.toString() : AppScope.customer.toString());
  }

  public static getAppScope(): AppScope {
    return AppScope[localStorage.getItem(LOCAL_STORAGE_APP_SCOPE) || ''] || AppScope.customer;
  }

  private getCurrentUserRoles(userParam: NullableLocalStorageUserModel = null): UserModelRoles[] {
    const user = userParam || this.getCurrentUser();

    if (!user) {
      console.info('authUtil: No user exists'); // TODO: change to correct logger
      return [];
    }

    if (!user.roles) {
      console.info('authUtil: No authority roles exist'); // TODO: change to correct logger
      console.log(user);
      return [];
    }

    return user.roles;
  }
}

export default AuthUtils;
