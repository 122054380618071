import * as React from 'react';
import { reduxForm, InjectedFormProps, WrappedFieldArrayProps, FieldArray } from 'redux-form';
import { RouteComponentProps, withRouter } from 'react-router';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { VehicleState } from '../Vehicle.reducer';
import { SpeysSelectStringField } from '../../../../../../components/Form/SpeysCloud.SelectField.component';
import BaseFormFields, { FormFieldUtilities } from '../../../../../../helpers/FormFieldUtilities';
import VehicleCreateOrUpdateRequestViewModel from '../../../../../../viewModels/vehicle/VehicleCreateOrUpdateRequestViewModel';
import SpeysCloudLoaderButtonComponent from '../../../../../../components/SpeysCloud.LoaderButton.component';
import ValidationUtilities from '../../../../../../helpers/ValidationUtilities';
import ReusableComponentUtilities from '../../../../../../helpers/ReusableComponentUtilities';
import { routePaths } from '../../../../../../constants/api.constants';
import DetailviewTopNavComponent from '../../../../../../components/Template/Detailview.TopNav.component';
import LocaleUtilities from '../../../../../../helpers/LocaleUtilities';
import TrucksImage from '../../../../../../assets/img/partner-bg.jpg';

import SpeysContent from '../../../../../../components/SpeysContent';
import ImageBlock from '../../../../../../components/ImageBlock';
import PageTitleComponent from '../../../../../../components/Template/PageTitle.component';
import AuthUtils from '../../../../../../helpers/AuthUtils/AuthUtils';

class FormFields extends BaseFormFields<keyof VehicleCreateOrUpdateRequestViewModel> {
  getLicensePlate = () => this.defaultField('regNumber');
  getAssignedDriver = (fn) => {
    return <FieldArray name="assignedDriver" component={fn} />;
  };
  getCategory = (fn: any, isOther: boolean) => {
    if (isOther == false) {
      return (
        <SpeysSelectStringField
          name={this.typeSafeName('loadCapacity')}
          options={[
            { label: '< 3,5t Truck', value: '< 3,5t Truck' },
            { label: '3,5t Truck', value: '3,5t Truck' },
            { label: '7,5t Truck', value: '7,5t Truck' },
            { label: '12t Truck', value: '12t Truck' },
            { label: '40t Truck', value: '40t Truck' },
            { label: 'Other', value: 'Other' },
          ]}
          fn={fn}
          fnUseValue={true}
        />
      );
    } else {
      return this.defaultField('loadCapacity', 'Load Capacity...');
    }
  };
  getVehicleType = (fn: any, isOther: boolean) => {
    if (isOther == false) {
      return (
        <SpeysSelectStringField
          name={this.typeSafeName('type')}
          options={[
            { label: 'Taut Liner', value: 'Taut Liner' },
            { label: 'Box Trailer', value: 'Box Trailer' },
            { label: 'Jumbo', value: 'Jumbo' },
            { label: 'Double Decker', value: 'Double Decker' },
            { label: 'Gigaliner', value: 'Gigaliner' },
            { label: 'Thermo', value: 'Thermo' },
            { label: 'Other', value: 'Other' },
          ]}
          fn={fn}
          fnUseValue={true}
        />
      );
    } else {
      return this.defaultField('type', 'Type Vehicle...');
    }
  };
  getCargoLength = () => this.defaultField('cargoLength');
  getCargoWidth = () => this.defaultField('cargoWidth');
  getCargoHeight = () => this.defaultField('cargoHeight');
}

interface VehicleColumnTwoFieldsComponentState {
  truck: string;
  vehicle: string;
  isOtherTruck: boolean;
  isOtherVehicle: boolean;
  unitOptions: any[];
}

class VehicleColumnTwoFieldsComponent extends React.Component<
  {
    formFields: FormFields;
    formHelper: FormFieldUtilities;
  } & WrappedFieldArrayProps<any>,
  VehicleColumnTwoFieldsComponentState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      truck: this.props.formFields.getCategory.name,
      vehicle: this.props.formFields.getVehicleType.name,
      isOtherTruck: false,
      isOtherVehicle: false,
      unitOptions: [],
    };
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handleVehicleChange = this.handleVehicleChange.bind(this);
  }

  handleCategoryChange(value: any) {
    if (value == 'Other') {
      this.setState({
        isOtherTruck: true,
      });
    }
  }

  handleVehicleChange(value: any) {
    if (value == 'Other') {
      this.setState({
        isOtherVehicle: true,
      });
    }
  }

  fn = () => {
    const unitGroupOptions = [{ label: 'Choose', value: '0' }];
    if (this.state.unitOptions !== undefined && this.state.unitOptions.length > 0) {
      for (let i = 0; i < this.state.unitOptions.length; i++) {
        const item = {
          label: this.state.unitOptions[i].name,
          value: this.state.unitOptions[i].id,
        };
        unitGroupOptions.push(item);
      }
    }
    return <SpeysSelectStringField name={'Assign Driver'} options={unitGroupOptions} />;
  };

  render() {
    const { formFields, formHelper } = this.props;

    return (
      <div className="card">
        <div className="card-body">
          <h2 className="heading">
            <span>Address Details</span>
          </h2>
          <Row>
            <Col md={8}>
              {formHelper.getField(
                LocaleUtilities.i18n('vehicle-detail-Load Capacity', 'vehicle-detail', true),
                formFields.getCategory(this.handleCategoryChange, this.state.isOtherTruck),
                'Load Capacity',
                true,
              )}
              {formHelper.getField(
                LocaleUtilities.i18n('vehicle-detail-License plate', 'vehicle-detail', true),
                formFields.getLicensePlate(),
                'License plate',
                true,
              )}
              {formHelper.getField(
                LocaleUtilities.i18n('vehicle-detail-Vehicle Type', 'vehicle-detail', true),
                formFields.getVehicleType(this.handleVehicleChange, this.state.isOtherVehicle),
                'Vehicle Type',
                true,
              )}
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              {formHelper.getField(
                LocaleUtilities.i18n('vehicle-detail-Cargo Length', 'vehicle-detail', true),
                formFields.getCargoLength(),
                'Cargo Length',
                true,
              )}
            </Col>
            <Col md={4}>
              {formHelper.getField(
                LocaleUtilities.i18n('vehicle-detail-Cargo Width', 'vehicle-detail', true),
                formFields.getCargoWidth(),
                'Cargo Width',
                true,
              )}
            </Col>
            <Col md={4}>
              {formHelper.getField(
                LocaleUtilities.i18n('vehicle-detail-Cargo Height', 'vehicle-detail', true),
                formFields.getCargoHeight(),
                'Cargo Height',
                true,
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export interface VehicleComponentStateProps extends VehicleState {
  title: any;
}

export interface VehicleComponentDispatchProps {
  cancelItem: Function;
  startItem: Function;
  submitItem: Function;
}

export const vehicleFormName = 'vehicleForm';
type VehicleComponentProps = VehicleComponentStateProps &
  VehicleComponentDispatchProps &
  RouteComponentProps<any> &
  InjectedFormProps<any>;

class VehicleComponent extends React.Component<
  VehicleComponentProps & WrappedFieldArrayProps<any>,
  {
    tenants: any[];
  }
> {
  constructor(props: any) {
    super(props);
  }

  goToList = () => {
    this.props.history.push(routePaths.vehicles.list);
  };

  handleSave = () => {
    this.props.submitItem(this.goToList);
  };

  async componentDidMount() {
    this.props.startItem(this.props.companyId);
    if (AuthUtils.isAdmin()) {
    }
  }

  render() {
    const errorWrapper = this.props.errorWrapper;
    const { formErrors, formErrorPropertyNames } = ValidationUtilities.getFormErrors(errorWrapper);
    const backToListRowComponent = ReusableComponentUtilities.getBackToListComponent(routePaths.vehicles.list);
    const errorRowComponent = ReusableComponentUtilities.getErrorRowComponent(
      errorWrapper,
      'partner-detail-error-',
      'partner.detail',
    );
    const formHelper = new FormFieldUtilities(formErrors, formErrorPropertyNames);
    const formFields = new FormFields();
    const { fields, meta } = this.props;

    if (ValidationUtilities.isCriticalError(errorWrapper)) {
      return (
        <div>
          {errorRowComponent}
          {backToListRowComponent}
        </div>
      );
    }

    return (
      <div>
        <DetailviewTopNavComponent>{backToListRowComponent}</DetailviewTopNavComponent>
        <SpeysContent styleName={'ct-table'}>
          <SpeysContent.Body>
            <div className="container-fluid">
              <PageTitleComponent
                title={LocaleUtilities.i18n(`sidebar-menu-item-Vehicle Configuration`, 'sidebar.menu', true)}
              />
              <div className="col-md-10 offset-lg-1">
                <div className="userProfile-wrapper animated fadeIn">
                  <ImageBlock filename={TrucksImage} height={'45px'} />
                  {errorRowComponent}
                  <Row className="animated bounceInRight">
                    <Col md={12}>
                      <div className="form p-t-20 smaller-label-font">
                        <Row>
                          <Col md={12}>
                            <VehicleColumnTwoFieldsComponent
                              formFields={formFields}
                              formHelper={formHelper}
                              fields={fields}
                              meta={meta}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} className="text-right mb-20 mt-10">
                            <SpeysCloudLoaderButtonComponent
                              loading={this.props.loading}
                              handleAction={this.handleSave}
                              className={'btn waves-effect waves-light btn-success text-white'}
                            >
                              {LocaleUtilities.i18n('Save', 'base.button')}
                            </SpeysCloudLoaderButtonComponent>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </SpeysContent.Body>
        </SpeysContent>
      </div>
    );
  }
}

export default reduxForm({ form: vehicleFormName })(withRouter(VehicleComponent));
