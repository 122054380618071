import { SortOrder } from 'speys-react-bootstrap-table';

export class PaginatedSearchBaseOptionsResult {
  page = 1;
  sizePerPage = 25;
  skip: number;
  take: number;
  sortName: string;
  sortOrder: SortOrder;

  public baseParse(obj: any) {
    if ('page' in obj) {
      this.page = Number(obj.page);
    }
    if ('sizePerPage' in obj) {
      this.sizePerPage = Number(obj.sizePerPage);
    }
    if ('skip' in obj) {
      this.skip = Number(obj.skip);
    }
    if ('take' in obj) {
      this.take = Number(obj.take);
    }
    if ('sortName' in obj) {
      this.sortName = obj.sortName;
    }
    if ('sortOrder' in obj) {
      this.sortOrder = obj.sortOrder;
    }
  }
}

export class PaginatedListResult<TModel> {
  contextObjects: Array<TModel>;
  totalCount: number;
  pages: number;
}

export class ValidationFailure {
  propertyName: string;
  errorMessage: string;
  attemptedValue: any;
  customState: any;
  errorCode: string;
}

export class ValidationResult {
  errors: Array<ValidationFailure>;
}

export interface ServiceResult<T> {
  payload: T;
  isSuccessful: boolean;
  validation: ValidationResult;
}

export enum ErrorType {
  GenericError = 0,
  ItemNotFound = 10,
  FailedResponseError = 20,
}

export class ErrorWrapper {
  title: any;
  description: string;
  formValidations: ValidationResult;
  type: ErrorType = ErrorType.GenericError;

  constructor(title: any, description?: string) {
    this.title = title;
    this.description = description || '';
  }
}

export class NotFoundErrorWrapper extends ErrorWrapper {
  constructor(description: string) {
    super('Error', description);
    this.type = ErrorType.ItemNotFound;
  }
}

export class FailedResponseErrorWrapper extends ErrorWrapper {
  constructor(description: string) {
    super('Error', description);
    this.type = ErrorType.FailedResponseError;
  }
}

//
// TODO: put these classes/interfaces in correct files
//

export class SpeysPagination {
  total: number;
  page: number;
  sizePerPage: number;
  sortName?: string;
  sortOrder?: SortOrder;

  public static getDefault(): SpeysPagination {
    return { total: 0, page: 1, sizePerPage: 25 };
  }
}

export class TableData<T> {
  data: Array<T>;
  pagination: SpeysPagination;

  public static createTableDataFrom<T>(
    paginatedListResult: PaginatedListResult<T>,
    page: number,
    sizePerPage: number,
  ): TableData<T> {
    const data = paginatedListResult.contextObjects;
    const pagination: SpeysPagination = { total: paginatedListResult.totalCount, page, sizePerPage };
    return new TableData(data, pagination);
  }

  public static getDefault<T>(): TableData<T> {
    return new TableData<T>([], SpeysPagination.getDefault());
  }

  constructor(data: Array<T>, pagination: SpeysPagination) {
    this.data = data;
    this.pagination = pagination;
  }
}

export class TableActions {
  public onSortChange: (sortName: string, sortOrder: SortOrder) => any;
  public onPageChange: (page: number, sizePerPage: number) => any;
  public onSizePerPageList: (sizePerPage: number) => any;
}
