import actionCreatorFactory, { AnyAction } from 'typescript-fsa';
import { Dispatch } from 'redux';

import DriverViewResponseViewModel from 'viewModels/driverView/DriverViewResponseViewModel';
import { DriverViewApi } from 'api/Driver.api';
import CommonUtilities from 'helpers/CommonUtilities';
import { ErrorWrapper } from 'viewModels/base';

//
// Main fetch action
//

const actionCreator = actionCreatorFactory();
const type = 'DRIVERVIEW/FETCH';
export const asyncActions = actionCreator.async<{}, { driverView: DriverViewResponseViewModel }, ErrorWrapper>(type);

export default function fetchAsync(driverViewGuid: string): Function {
  return async (dispatch: Dispatch<AnyAction>): Promise<void> => {
    async function mainAction(): Promise<void> {
      dispatch(asyncActions.started({}));

      const driverView = await DriverViewApi.getDriverView(driverViewGuid);

      dispatch(asyncActions.done({ params: {}, result: { driverView } }));
    }

    async function catchAction(exception: ErrorWrapper): Promise<void> {
      dispatch(asyncActions.failed({ params: {}, error: exception }));
    }

    await CommonUtilities.tryCatchWrapper(mainAction, catchAction);
  };
}
