import * as React from 'react';
import TimeKeeper from 'react-timekeeper';
import DatePicker from 'react-datetime';
import { Field, GenericFieldHTMLAttributes, BaseFieldProps, WrappedFieldProps } from 'redux-form';
import * as moment from 'moment';
import $ from 'jquery';

class XXXField extends Field<
  GenericFieldHTMLAttributes &
    BaseFieldProps & { placeHolder: string; showTimeSelect?: boolean; defaultAddHours?: number }
> {}

class SpeysTimePickerInnerComponent extends React.Component<
  WrappedFieldProps &
    GenericFieldHTMLAttributes &
    BaseFieldProps & { placeHolder: string; showTimeSelect?: boolean; defaultAddHours?: number }
> {
  UNSAFE_componentWillMount() {
    $(document).ready(function () {
      $('.rdt.readonly').find('input').attr('readonly', 'true');
    });
  }
  handleOnFocus() {
    console.log('test');
  }
  render() {
    const { input, className } = this.props;
    const { value, onChange } = input;

    const momentDate = !value ? undefined : moment.isMoment(value) ? value : moment.utc(value);

    return (
      <div className="input-group">
        <TimeKeeper />
        <DatePicker
          className={className}
          value={momentDate}
          onFocus={this.handleOnFocus}
          onChange={(date: string) => {
            const momentDate2 = moment(date);
            if (moment(date).isValid()) {
              if (momentDate2.hour() === 0 && momentDate2.minute() === 0 && this.props.defaultAddHours !== undefined) {
                momentDate2.hours(this.props.defaultAddHours);
              }
              onChange(momentDate2);
            } else {
              onChange(null);
            }
          }}
        />
        <div className="input-group-append">
          <span className="input-group-text">
            <i className="icon-clock"></i>
          </span>
        </div>
      </div>
    );
  }
}

class SpeysTimePickerComponentProps {
  name: string;
  placeHolder: string;
  className: string;
  showTimeSelect?: boolean;
  defaultAddHours?: number;
}

export class SpeysCloudTimePickerComponent extends React.Component<SpeysTimePickerComponentProps> {
  render() {
    const { name, placeHolder, className, showTimeSelect, defaultAddHours } = this.props;
    return (
      <XXXField
        name={name}
        className={className}
        component={SpeysTimePickerInnerComponent}
        placeHolder={placeHolder}
        showTimeSelect={showTimeSelect}
        defaultAddHours={defaultAddHours}
      />
    );
  }
}
