/**
 * Sidebar Content
 */
import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import IntlMessages from '../../util/IntlMessages';

import NavMenuItem from './NavMenuItem';

// redux actions
import { onToggleMenu } from '../../app/actions/template';
import RouterUtilities from '../../helpers/RouterUtilities';
import MenuUtilites from '../../helpers/MenuUtilities';
import LocaleUtilities from '../../helpers/LocaleUtilities';

class SidebarContent extends Component {
  toggleMenu(menu, stateCategory) {
    const data = {
      menu,
      stateCategory,
    };
    this.props.onToggleMenu(data);
  }

  render() {
    const { sidebarMenus } = this.props.sidebar;
    let currentLink = '/';
    if (
      this.props !== null &&
      this.props !== undefined &&
      this.props.history !== undefined &&
      this.props.history.location !== undefined
    ) {
      currentLink = RouterUtilities.getActiveRoute(this.props.history.location);
      if (currentLink.length === 0) {
        currentLink = 'dashboard';
      }
    }
    const mainMenu = MenuUtilites.getAllowedMenuForUser(sidebarMenus.adminGeneral);
    const secondMenu = MenuUtilites.getAllowedMenuForUser(sidebarMenus.adminSettings);
    return (
      <div className="rct-sidebar-nav">
        <nav className="navigation">
          <List
            className="rct-mainMenu p-0 m-0 list-unstyled"
            subheader={
              <ListSubheader className="side-title" component="li">
                {LocaleUtilities.i18n(`sidebar-menu-header-General`, 'sidebar.menu')}
              </ListSubheader>
            }
          >
            {mainMenu.map((menu, key) => (
              <NavMenuItem
                menu={menu}
                key={key}
                currentLink={currentLink}
                onToggleMenu={() => this.toggleMenu(menu, 'adminGeneral')}
              />
            ))}
          </List>
          {secondMenu !== undefined && secondMenu.length > 0 && (
            <List
              className="rct-mainMenu p-0 m-0 list-unstyled"
              subheader={
                <ListSubheader className="side-title" component="li">
                  {LocaleUtilities.i18n(`sidebar-menu-header-Settings`, 'sidebar.menu')}
                </ListSubheader>
              }
            >
              {secondMenu.map((menu, key) => (
                <NavMenuItem
                  menu={menu}
                  key={key}
                  currentLink={currentLink}
                  onToggleMenu={() => this.toggleMenu(menu, 'adminSettings')}
                />
              ))}
            </List>
          )}
        </nav>
      </div>
    );
  }
}

// map state to props
const mapStateToProps = ({ sidebar }) => {
  return { sidebar };
};

export default withRouter(
  connect(mapStateToProps, {
    onToggleMenu,
  })(SidebarContent),
);
